
import { Tooltip, Flex } from 'antd'
import type { TableColumnsType } from 'antd';
import {
	faSheetPlastic,
	faCodePullRequest,
	faSpinner,
	faFileCircleCheck,
	faQuestion
} from "@fortawesome/free-solid-svg-icons";
import { URLPaths } from '../../../../../config/application/URLPaths';
import { Link } from "react-router-dom";
import { BsFiletypeJson } from "react-icons/bs";
import { TbFileTypeTxt, TbFileTypeHtml, TbFileTypeXml } from "react-icons/tb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const inputCon = process.env.PUBLIC_URL + '/static/input_con.svg'
const outputCon = process.env.PUBLIC_URL + '/static/output_con.svg'

const sharedIcon = process.env.PUBLIC_URL + '/static/common.svg'
const sendCon = process.env.PUBLIC_URL + '/static/sendCon.svg'


export const ConnectorListCols: TableColumnsType<IConnectorList> = [

	{
		dataIndex: 'type',
		key: 'type',
		align: 'center',
		width: '4%',
		render: (text, record) =>
			<Tooltip title={`${text}`}>
				{/* <FontAwesomeIcon icon={text === 'CONNECTOR_OUTPUT' ? faCircleChevronUp : faCircleChevronDown} /> */}
				{text === 'CONNECTOR_INPUT' ?
					<span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<img src={inputCon} alt="running" style={{ width: 20, paddingLeft: '0px', transform: 'rotate(180deg)' }} />
					</span>
					:
					<span style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
						<img src={outputCon} alt="running" style={{ width: 20, paddingLeft: '0px' }} />
					</span>

				}
			</Tooltip>
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		ellipsis: true,
		width: '23%',
		render: (text, record) =>
			<Tooltip title={`${text}`}>
				<Link to=
					{record.type === 'CONNECTOR_INPUT' ?
						`${URLPaths.IN_CONNECTOR_EDIT.link}/${record.versionId}` :
						`${URLPaths.OUT_CONNECTOR_EDIT.link}/${record.versionId}`
					}>{text}</Link>
			</Tooltip>

	},
	{
		title: 'Создал',
		dataIndex: 'userName',
		key: 'userName',
		ellipsis: true,
		width: '10%',
		render: (text) =>
			<Tooltip title={`${text}`}>
				<span>{text}</span>
			</Tooltip>,
	},
	{
		dataIndex: 'summarySubType',
		key: 'summarySubType',
		ellipsis: true,
		width: '4%',
		align: 'center',
		render: (text, record) =>
			<Tooltip title={`${text}`}>
				{text === 'REQUESTER' && record.type === 'CONNECTOR_OUTPUT' ?
					<span style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
						<img src={sendCon} alt="running" style={{ width: 20, paddingLeft: '0px' }} />
					</span>
					:
					<FontAwesomeIcon style={text === 'REQUESTER' ? { color: '#349A15' } : { color: '#FF6D00' }} icon={text === 'REQUESTER' ? faCodePullRequest : faSpinner} />
				}
			</Tooltip>,
	},
	{
		dataIndex: 'dataFormat',
		key: 'dataFormat',
		width: '4%',
		align: 'center',
		render: (text, record) =>
			<Tooltip title={text ? text : 'etc'}>
				<Flex justify="center" align="center" >
					{
						text === 'JSON' ? <BsFiletypeJson style={{ color: '#006064' }} /> :
							text === 'TEXT' ? <TbFileTypeTxt style={{ color: '#0D47A1' }} /> :
								text === 'HTML' ? <TbFileTypeHtml style={{ color: '#FF6F00' }} /> :
									text === 'XML' ? <TbFileTypeXml style={{ color: '#33691E' }} /> :
										<FontAwesomeIcon icon={faQuestion} />
					}
				</Flex>
			</Tooltip>
	},
	{

		dataIndex: 'common',
		key: 'common',
		width: '4%',
		align: 'center',
		render: (text, record) =>
			<Tooltip title={text === true ? 'Общее' : null}>
				{text === true ?
					<span style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
						<img src={sharedIcon} alt="shared" style={{ width: 20, paddingLeft: '0px' }} />
					</span>
					: null
				}
			</Tooltip>
	},
	{
		title: '',
		dataIndex: 'summaryState',
		key: 'summaryState',
		width: '4%',
		ellipsis: true,
		align: 'center',
		render: (text, record) => <FontAwesomeIcon style={text === 'DRAFT' ? { color: '#B9B9B9' } : { color: '#7FD581' }} icon={text === 'DRAFT' ? faSheetPlastic : faFileCircleCheck} />,
	},
	{
		title: 'Используется в моделях',
		dataIndex: 'models',
		key: 'models',
		ellipsis: true,
		width: '26%',
		render: (models: { modelName: string }[]) => (
			<span>
				{models && models.map((model, index, array) => (
					<span key={index}>
						{model.modelName}
						{index === array.length - 1 ? '.' : ', '}
					</span>
				))}
			</span>
		),
	},

	{
		title: 'Время последней операции',
		dataIndex: 'createDate',
		key: 'createDate',
		ellipsis: true,
		width: '21%',
		render: (text) => {
			const date = new Date(text);
			const formattedDate = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}.${(date.getMonth() + 1) < 10 ? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}:${date.getSeconds() < 10 ? '0' : ''}${date.getSeconds()}`;
			return <span>{formattedDate}</span>;
		}
	},


	// {
	// 	title: 'Type',
	// 	dataIndex: 'type',
	// 	key: 'type',
	// 	width: 0,
	// 	render: (text, record) => <span>{text === 'RULE' && 'ruleType' in record ? (record as Rule).ruleType : text}</span>
	// },

]