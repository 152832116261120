import { UseQueryOptions, UseQueryResult, useMutation, useQuery } from "@tanstack/react-query"
import { getPrivileges, getUser, postRegisterUser, postUser } from "../../../api/requests"
import React from "react"
import { useDispatch } from "react-redux"
import { ActionTypes } from "../../../redux/Users/UserReducer"
import { authErrorHandler } from "../../../hooks/AuthHook"
import useCustomToast from "../../../components/CustomToast/useCustomToast"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';

export function useGetUser({ userName }: { userName?: string | null }, options?: UseQueryOptions<IGetUserResponse, Error>): UseQueryResult<IGetUserResponse, Error> {

	const dispatch = useDispatch()
	const navigate = useNavigate()


	const req = useQuery({
		queryKey: ['getUser', userName],
		queryFn: () => getUser({ params: userName }),
		enabled: userName !== null && userName !== undefined,
		...options
	})

	React.useEffect(() => {
		if (req.data) {
			dispatch({ type: ActionTypes.USER_GET, payload: req.data })
			sessionStorage.setItem("login", String(userName))
			authErrorHandler.handleAuthChange(true)
			navigate('/');

		}
	}, [req.data])

	return req
}


export function useRegisterUser() {
	const toast = useCustomToast();
	const dispatch = useDispatch();

	return {
		...useMutation({
			mutationFn: async (values: any) => {
				await postRegisterUser({ data: values });
			},
			onSuccess: (data) => {
				toast.handleToast({ type: 'success', text: 'Success!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error: ${(error as any).message}`, key: uuidv4() });
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function usePostUser() {
	const toast = useCustomToast();
	const dispatch = useDispatch();

	return {
		...useMutation({
			mutationFn: async (values: any) => {
				await postUser({ data: values });
				return getUser({ params: values.login });
			},
			onSuccess: (data) => {
				toast.handleToast({ type: 'success', text: 'Welcome!', key: uuidv4() });
				dispatch({ type: ActionTypes.USER_GET, payload: data });
				sessionStorage.setItem("login", String(data.login));
				authErrorHandler.handleAuthChange(true);
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error: ${(error as any).message}`, key: uuidv4() });
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useFetchPrivileges({ postUserResponse }: { postUserResponse?: IPostUserResponse }, options?: UseQueryOptions<IPostUserResponse, Error>): UseQueryResult<IPostUserResponse, Error> {

	const req = useQuery({
		queryKey: ['getPrivileges'],
		queryFn: () => {
			if (postUserResponse && postUserResponse.roles) {
				return getPrivileges({ params: postUserResponse.roles.map(role => Number(role.id)).join(',') });
			}
			return Promise.reject(new Error('postUserResponse is undefined or has no roles'));
		},
		enabled: !!postUserResponse,
		...options
	})

	return req
}
