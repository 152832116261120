import { Affix, Flex, Segmented } from 'antd';
import { ReactNode } from 'react'
import { useAppSelector } from '../../../../../redux/Store';
import { useNavigate } from 'react-router-dom';
import { URLPaths } from '../../../../../config/application/URLPaths';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../../../redux/Model/ModelReducer';

function ComponentListLayout({ children, current }: { children: ReactNode, current: string }) {


	const navigate = useNavigate()
	const dispatch = useDispatch()
	const currentTab = useAppSelector(store => store.ModelReducer.CurrentModelTab);

	const modelIcon = process.env.PUBLIC_URL + '/static/models.svg';
	const conIcon = process.env.PUBLIC_URL + '/static/cons.svg';
	const rulesIcon = process.env.PUBLIC_URL + '/static/rules.svg';
	const dataStructuresIcon = process.env.PUBLIC_URL + '/static/dataStructures.svg';

	return (
		<Flex vertical style={{ padding: '0 20px 20px 20px', width: '100%', position: 'relative' }}>

			<Affix offsetTop={70} >

				<div style={{ height: 60, width: '100%', backgroundColor: '#FFF', zIndex: 11, display: 'flex', alignItems: 'center' }}>
					<Segmented
						// size="large"
						style={{ width: 530, height: 40, marginTop: 10 }}
						defaultValue={currentTab ?? current}
						value={currentTab ?? current}
						key={current}
						options={[
							{
								label: (
									<div style={{ padding: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<img src={modelIcon} alt="models" style={{ width: 25, paddingRight: '5px' }} />
										<div>Модели</div>
									</div>
								),
								value: 'Models',

							},
							{
								label: (
									<div style={{ padding: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<img src={conIcon} alt="Connectors" style={{ width: 25, paddingRight: '5px' }} />
										<div>Коннекторы</div>
									</div>
								),
								value: 'connectors',
							},
							{
								label: (
									<div style={{ padding: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<img src={rulesIcon} alt="Rules" style={{ width: 25, paddingRight: '5px' }} />
										<div>Правила</div>
									</div>
								),
								value: 'Rules',
							},
							{
								label: (
									<div style={{ padding: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<img src={dataStructuresIcon} alt="DataStructures" style={{ width: 25, paddingRight: '5px' }} />
										<div>Структуры данных</div>
									</div>
								),
								value: 'DataStructures',
							},
						]}

						onChange={(value) => {

							const paths = {
								[URLPaths.MODELS.text]: URLPaths.MODELS.link,
								[URLPaths.CONNECTORS.text]: URLPaths.CONNECTORS.link,
								[URLPaths.RULES.text]: URLPaths.RULES.link,
								[URLPaths.DATASTRUCTURES.text]: URLPaths.DATASTRUCTURES.link,
							};

							const path = paths[value];

							if (path) {
								dispatch({
									type: ActionTypes.GENERAL_CHANGE_MODEL_TAB,
									payload: value
								})
								navigate(path);
							}
						}}

					/>
				</div>
			</Affix>

			{children}

		</Flex>
	)
}

export default ComponentListLayout