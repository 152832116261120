import { DatePicker, Form } from 'antd'
import dayjs from 'dayjs';
import type { DatePickerProps } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { postGenerateToken } from '../../../../../../../api/requests';

function ConFormDatePicker({ getToken }: { getToken: (generatedToken: string) => void }) {

	const currentDate = dayjs().format('YYYY-MM-DD');
	const maxDate = dayjs().add(1, 'year').format('YYYY-MM-DD');

	const generatedToken = useMutation({
		mutationFn: (time: any) => postGenerateToken({ params: { expirationTime: time } }),
		onSuccess: (data) => {
			getToken(data.data)
		},
		onError: (error) => {
			console.error('Invalid date value:', error);
		}
	})

	const generateToken = (value: DatePickerProps['value']) => {
		const time = dayjs(value).diff(dayjs(), 'second');
		generatedToken.mutate(time)
	};

	return (
		<Form.Item
			label="expiredAt"
			name="expiredAt"
			rules={[{ required: true, message: 'Please enter expired time' }]}
		>
			<DatePicker
				defaultValue={dayjs()}
				showTime
				format="YYYY-MM-DD HH:mm:ss"
				onOk={generateToken}
				disabledDate={(current) => (current && current < dayjs(currentDate).startOf('day')) || (current && current > dayjs(maxDate).startOf('day'))}
			/>
		</Form.Item>
	)
}

export default ConFormDatePicker