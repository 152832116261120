
import { Flex, Table, Input, TreeSelect, Switch } from "antd";
import RulesDataStructureControl from './controlElements';
import { FieldVariable } from "../../../../../shared/entities/Rule/Rule";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../../../redux/Model/RuleReducer";
import type { TableColumnsType } from 'antd';
import { Button, Modal } from 'antd'
import { EditOutlined, MoreOutlined, SaveOutlined } from '@ant-design/icons';
import { useAppSelector } from "../../../../../redux/Store";
import { useParams } from "react-router-dom";

import styles from './dataStructTable.module.scss'

type ItreeData = {
	value: string | number,
	title: string | number,
	children?: ItreeData[]
}

function RulesDataStructureTable(data: { data: FieldVariable[] }) {
	const [calcatedId, setCalculatedId] = useState(0)
	const [fieldsList, setFieldList] = useState<FieldVariable[]>([]);
	const [isVariablePickMode, setVariablePickMode] = useState(false)
	const [ruleVariable, setRuleVariable] = useState<string | number>()
	const [currentFieldId, setCurrentFieldId] = useState<number>()
	const dispatch = useDispatch()
	const selectedFieldId = useAppSelector(state => state.RuleReducer.selectedField.id)
	const { rule, selectedJsonData, isConditionActive } = useAppSelector(state => state.RuleReducer)
	const fieldList = useAppSelector(state => state.RuleReducer.rule?.fields)

	const fieldsRelationId = useAppSelector(state => state.RuleReducer.fieldRelation?.fieldRelationRequestSubDataDTOList)
	// const currentFieldId = useAppSelector(state => state.RuleReducer.selectedField.id)


	const fieldsRelationTags = useAppSelector(state => state.RuleReducer.fieldsResponse)
	const fieldSelection = useAppSelector(state => state.RuleReducer.fieldsResponse)
	// const tagsArray = fieldsRelationTags && fieldsRelationTags.map(item => Object.values(item.tags));
	const tagsArray = fieldsRelationTags?.flatMap(item =>
		Object.entries(item.tags).map(([id, name]) => ({ id: parseInt(id), name }))
	) ?? [];




	const [edit, setEdit] = useState(false)

	const [inputValue, setInputValue] = useState('')
	const [currentInputTestData, setCurrentInputTestData] = useState<number | string>(0)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { fromModel } = useParams()

	const [treeData, setTreeData] = useState<ItreeData[]>()
	const [value, setValue] = useState(0)
	const [defaultTitle, setDefaultTitle] = useState<string>()
	const [isNewField, setIsNewField] = useState(false)
	const fieldRelation = useAppSelector(state => state.RuleReducer.fieldRelation)



	const onChange = (newValue: number) => {


		setValue(newValue)
		setDefaultTitle(tagsArray.find((item) => item.id === newValue)?.name ?? undefined)


		dispatch({
			type: ActionTypes.RULE_SET_FIELDS_RELATION,
			payload: {
				sdvmodelStructId: fromModel,
				relation: {
					srcName: tagsArray.find((item) => item.id === newValue)?.name ?? undefined,
					srcSummaryFieldId: newValue,
					varSummaryFieldId: currentFieldId
				}
			}
		})
	};



	const showModal = (id: number) => {

		setRuleVariable(fieldsList.find((item) => item.id === id)?.ruleVariable || '');
		console.log('@ruleVar', fieldsList.find((item) => item.id === id)?.ruleVariable)

		setIsModalOpen(true);

	};


	const handleOk = () => {
		setIsModalOpen(false);
		setVariablePickMode(false)

		isVariablePickMode ?
			dispatch({
				type: ActionTypes.RULE_EDIT_FIELD_VARIABLE,
				payload: {
					id: selectedFieldId,
					variable: ruleVariable
				}
			}) : dispatch({
				type: ActionTypes.RULE_EDIT_FIELD_VARIABLE,
				payload: {
					id: selectedFieldId,
					variable: null
				}
			})
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setVariablePickMode(false)
	};

	interface DataType {
		key: React.ReactNode;
		name: string;
		id: number;
		testValueJson: string | number | null;
		children?: DataType[];
	}


	const columns: TableColumnsType<DataType> = [
		{
			title: 'LID',
			dataIndex: 'id',
			key: 'id',
			width: '15%',
		},
		{
			title: 'Наименование данных или ключ',
			dataIndex: 'name',
			key: 'name',
			width: '35%',
			render: (text: string, record: DataType) =>
				<div>
					{(edit === true && selectedFieldId === record.id) || (isNewField && selectedFieldId === record.id) ?
						<Input
							style={{ height: 25, borderRadius: 8 }}
							onChange={(e) => setInputValue(e.target.value)}
							value={inputValue}
						// defaultValue={text}
						/> :
						<span
							onClick={() => {
								// dispatch({
								// 	type: ActionTypes.RULE_SELECT_VALUE_TO_CONDITION,
								// 	payload: { valueToCondition: text, isValueSelecting: true }
								// })

								if (rule?.summarySubType === 'QUANTITY' && isConditionActive === true) {
									dispatch({
										type: ActionTypes.RULE_EDIT_JSON_DATA,
										payload: {
											...selectedJsonData,
											isQuality: false,
											newCondition: selectedJsonData?.condition ? selectedJsonData?.condition.concat('', text) : text,
											newResultCondition: selectedJsonData?.resultCondition,
										}
									});
								}
								else if (rule?.summarySubType === 'QUANTITY' && isConditionActive === false) {
									dispatch({
										type: ActionTypes.RULE_EDIT_JSON_DATA,
										payload: {
											...selectedJsonData,
											isQuality: false,
											newResultCondition: selectedJsonData?.resultCondition ? selectedJsonData?.resultCondition.concat('', text) : text,
											newCondition: selectedJsonData?.condition,
										}
									})
								}
								else {
									dispatch({
										type: ActionTypes.RULE_EDIT_JSON_DATA,
										payload: {
											...selectedJsonData,
											isQuality: true,
											number: selectedJsonData && selectedJsonData.number,
											newCondition: selectedJsonData?.condition.concat('', text),
										}
									});
								}
							}}
							style={{ cursor: 'pointer', paddingLeft: '10.5px' }}>{text}</span>}
				</div>
		},
		{
			title: 'Тестовое значение',
			dataIndex: 'testValueJson',
			key: 'testValueJson',
			width: '20%',
			render: (text: string, record: DataType) =>
				<div>{(edit === true && selectedFieldId === record.id) || (isNewField && selectedFieldId === record.id) ?
					<Input
						onChange={(e) => {
							// setCurrentInputTestData(e.target.value as string | number)

							const newValue = e.target.value.trim();
							const parsedValue = !isNaN(Number(newValue)) ? Number(newValue) : newValue;
							setCurrentInputTestData(parsedValue);

						}}
						value={currentInputTestData}
						style={{ height: 25, borderRadius: 8 }}
					// defaultValue={text} 
					/>
					: <span style={{ paddingLeft: '10.5px' }}>{text}</span>}</div>
		},
		{
			title: fromModel ? 'Связь с данными' : 'Редактирование',
			width: '30%',
			key: 'edit',
			render: (text, record) => <Flex gap='small'>
				<Button
					type="primary"
					shape="circle"
					style={{ border: '1px solid #5499C7' }}
					icon={(selectedFieldId === record.id! && edit) || (isNewField && selectedFieldId === record.id) ? <SaveOutlined style={{ color: '#FFF' }} /> : <EditOutlined style={{ color: '#FFF' }} />}
					onClick={() => {
						if (edit) {
							setIsNewField(false);
							dispatch({
								type: ActionTypes.RULE_EDIT_FIELD,
								payload: { name: inputValue, id: selectedFieldId, testValueJson: currentInputTestData }
								// paylaod: { id: selectedFieldId, key: 'name', value: inputValue }
							});

						} else {
							setInputValue(record.name)
							setCurrentInputTestData(record.testValueJson as string | number)
						}
						setEdit(!edit);
					}}
				/>

				{fromModel && <div>
					{
						fieldList?.find(field => field.id === record.id)?.ruleVariable !== null ||
							fieldsRelationId?.find(field => field.varSummaryFieldId === record.id) ?
							<Button
								style={{ border: '1px solid #229954' }}
								onClick={() => showModal(record.id)}>
								{fieldList?.find(field => field.id === record.id)?.ruleVariable !== null ?
									fieldList?.find(field => field.id === record.id)?.ruleVariable :
									fieldsRelationId?.find(field => field.varSummaryFieldId === record.id)?.srcName ?
										fieldsRelationId?.find(field => field.varSummaryFieldId === record.id)?.srcName :
										defaultTitle}
							</Button>
							:
							<Button
								className={styles.fieldRelationBtn}
								shape="circle"
								icon={<MoreOutlined className={styles.fieldRelationBtnIcon} />}
								onClick={() => showModal(record.id)}
							// style={{ border: '1px solid #E74C3C' }}
							/>
					}
				</div>}

			</Flex>
		}
	];


	useEffect(() => {
		setFieldList(data.data.length > 0 ? data.data.filter(item => !item.defaultField) : [])
	}, [data])

	useEffect(() => {
		if (fieldRelation)
			setValue(fieldRelation?.fieldRelationRequestSubDataDTOList.find(v => v.varSummaryFieldId === currentFieldId)?.srcSummaryFieldId as number)

	}, [])

	useEffect(() => {

		if (fieldSelection) {


			const mapChildren = (fields: any[]): any[] => {
				return fields.map((field) => ({
					value: field.id,
					disabled: field.children !== null,
					title: field.name,
					children: field.children ? mapChildren(field.children) : null,
				}));
			}
			const mapFields = (fields: any[]): any[] => {
				return fields.map((field) => ({
					value: field.id,
					disabled: field.children !== null,
					title: `${field.type} #${field.id} `,
					children: field.fields ? mapChildren(field.fields) : null,
				}));
			};

			setTreeData(mapFields(fieldSelection));
		}
	}, [fieldSelection])

	const addField = () => {
		setCalculatedId(fieldsList ? fieldsList.length + 1 : 0)
		setFieldList([...fieldsList, { name: `newField-${calcatedId}`, id: calcatedId, key: calcatedId, testValueJson: 0 }]);
		setInputValue(`newField-${calcatedId}`)
		dispatch({
			type: ActionTypes.RULE_ADD_FIELD,
			payload: { name: `newField-${calcatedId}`, id: calcatedId, testValueJson: 0 }
		})
		dispatch({
			type: ActionTypes.RULE_SELECT_FIELD,
			payload: {
				name: `newField-${calcatedId}`,
				id: calcatedId,
				testData: 0
			}
		})
		setEdit(true)
		setIsNewField(true)
	}

	const removeField = (idToRemove: number) => {

		dispatch({
			type: ActionTypes.RULE_REMOVE_FIELD,
			payload: idToRemove
		});
	};




	return (
		<Flex vertical gap='middle' style={{ marginBottom: 20, width: '70%' }} >



			<Modal title="Сопоставьте поле" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

				<Flex gap='middle'>
					<span style={{ width: 200 }}>{!isVariablePickMode ? 'Выберите из коннектора' : 'Выставьте свою переменную'}</span>
					<Switch value={isVariablePickMode} onChange={setVariablePickMode} style={{ marginBottom: 15 }} />
				</Flex>

				{isVariablePickMode ?
					<Input
						placeholder="Please set your own variable"
						value={ruleVariable}
						onChange={(e) => setRuleVariable(e.target.value)}
						style={{ width: '100%', height: 32, border: '1px solid #d9d9d9', padding: '0 11px', borderRadius: 8 }}
					/>
					:
					<TreeSelect
						showSearch
						style={{ width: '100%' }}
						dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
						placeholder="Please select from connectors"
						allowClear
						treeDefaultExpandAll
						treeNodeFilterProp='title'
						// reeNodeFilterProp='label'
						treeData={treeData}
						value={value}
						onChange={(newValue: number) =>
							onChange(
								newValue,
							)}
					/>
				}

			</Modal>

			{!fromModel && <RulesDataStructureControl addField={addField} removeField={removeField} />}
			<Table
				columns={columns}
				dataSource={fieldsList}
				rowKey={(row) => row.id}
				rowClassName={(row) => (row.id === selectedFieldId ? styles.picked : styles.default)}
				size='small'
				onRow={(record: any) => {
					return {
						onClick: () => {
							setCurrentFieldId(record.id)
							dispatch({ type: ActionTypes.RULE_SELECT_FIELD, payload: record })
						},
					};
				}}

			/>

		</Flex>
	)
}

export default RulesDataStructureTable