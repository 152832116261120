import { Button, Divider, Flex, Form, FormProps, Input, Select, message } from 'antd'
import { useState } from 'react'
import { useAppSelector } from '../../../../../../redux/Store';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../../../../redux/Model/ConnectorReducer';

import ConFormDatePicker from './datePicker/DatePicker';
import { baseURL } from '../../../../../../api/instance';

function FormOfStructure({ closeModal }: { closeModal: () => void }) {

	const dispatch = useDispatch()
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const conType = useAppSelector(state => state.ConnectorReducer.connector?.summarySubType)
	const defaultList = useAppSelector(store => store.ConnectorReducer.connector?.connectionList?.list)

	const [bearerToken, setBearerToken] = useState('')

	type FieldType = {
		token?: string,
		expiredAt?: string,
		Description?: string,
		login: string,
		password: string,
		Title: string,
		DNS: string,
		IP: string,
		Authtype: string
	};

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 14 },
		},
	};

	const getIp = async () => {
		return await fetch('https://api.ipify.org?format=json')
			.then(response => response.json())
			.then(data => data.ip)
			.catch(error => {
				console.log(error);
				return null; // Return null or handle the error as needed
			});
	}



	const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
		const credentials = `${values.login}:${values.password}`;
		const base64Credentials = btoa(credentials);

		let ip = values.IP;
		if (conType === 'AWAITINGER') {
			ip = await getIp(); // Wait for the IP address to be fetched
		}

		const newData = {
			id: defaultList && defaultList.length > 0 ? defaultList.length : 0,
			name: values.Title,
			description: values.Description,
			dnsName: values.DNS,
			ip: conType === 'AWAITINGER' ? ip : values.IP,
			authType: values.Authtype,
			token: values.Authtype === 'Bearer' ? bearerToken : base64Credentials
		}

		dispatch({
			type: ActionTypes.CONNECTOR_ADD_NEW_CONNECTION,
			payload: newData
		})

		closeModal()
	};
	const [typeOfAuth, setAuthType] = useState()
	const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
		messageApi.open({
			type: 'error',
			content: `${errorInfo.errorFields[0].errors}`,
		});
		console.log('Failed:', errorInfo);
	};



	return (
		<Form
			{...formItemLayout}
			layout='horizontal'
			form={form}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			initialValues={{
				'DNS': conType === 'AWAITINGER' ?
					`${baseURL}` :
					'',
				'IP': '',
				'Authtype': 'Base Auth'
			}}
		>

			<Form.Item
				label="Title"
				name="Title"
				rules={[{ required: true, message: 'Please enter the title' }]}>
				<Input />
			</Form.Item>
			<Form.Item
				label="Description"
				name="Description"
				rules={[{ required: false, message: 'Please enter the description' }]}>
				<Input.TextArea rows={3} />
			</Form.Item>

			<Divider />

			<Form.Item
				label="DNS"
				name="DNS"
				rules={[{ required: true, message: 'Please enter DNS' }]}>
				<Input disabled={conType === 'AWAITINGER'} />
			</Form.Item>
			{conType === 'AWAITINGER' ? null :
				<Form.Item
					label="IP"
					name="IP"
					rules={[{ required: conType === 'AWAITINGER' ? false : true, message: 'Please enter IP' }]}>
					<Input disabled={conType === 'AWAITINGER'} />
				</Form.Item>
			}


			<Divider />

			<Form.Item
				label="Auth type"
				name="Authtype"
			>
				<Select
					options={[
						{ value: 'Base Auth', label: 'Base Auth' },
						{ value: 'Bearer', label: 'Bearer' },
					]}
					onChange={setAuthType}
				/>
			</Form.Item>

			{typeOfAuth === 'Bearer' ?
				<ConFormDatePicker getToken={(generatedToken: string) => setBearerToken(generatedToken)} />
				:
				<Flex vertical>
					<Form.Item
						label="login"
						name="login"
						rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="password"
						name="password"
						rules={[{ required: true, message: 'Please enter password' }]}>
						<Input.Password />
					</Form.Item>
				</Flex>
			}

			<Flex style={{ width: '100%' }} justify='flex-end'>
				<Button
					type="primary"
					style={{ marginTop: 20 }}
					onClick={() => form.submit()}
				>
					Submit
				</Button>
			</Flex>

			{contextHolder}

		</Form>
	)
}

export default FormOfStructure