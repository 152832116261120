import { Link } from "react-router-dom";
import { Tooltip } from 'antd'
import type { TableColumnsType } from 'antd';
import { URLPaths } from "../../../../../config/application/URLPaths"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPause, faLock, faSheetPlastic,
	faFileCircleCheck
} from "@fortawesome/free-solid-svg-icons";

const imageUrl = process.env.PUBLIC_URL + '/static/modelRun.svg';

export const modelListCols: TableColumnsType<IModelList> = [
	{
		title: '',
		dataIndex: 'state',
		key: 'state',

		render: (text, record) =>
			<>
				{text === 'RUN' ?
					<Tooltip title={`Модель запущена`}>
						<span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
							<img src={imageUrl} alt="running" style={{ width: 20, paddingLeft: '0px', color: 'green' }} />
						</span>
					</Tooltip>
					:
					text === 'PAUSE' ?
						<Tooltip title={`Модель приостановлена`}>
							<FontAwesomeIcon icon={faPause} />
						</Tooltip>
						:
						null
				}
			</>

		,
		width: '5%',
		ellipsis: true,
		align: 'center'
	},
	{
		title: '',
		dataIndex: 'summaryState',
		key: 'summaryState',
		width: '5%',
		ellipsis: true,
		align: 'center',
		render: (text, record) => <FontAwesomeIcon style={text === 'DRAFT' ? { color: '#B9B9B9' } : { color: '#7FD581' }} icon={text === 'DRAFT' ? faSheetPlastic : faFileCircleCheck} />,
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		render: (text, record) =>
			<Tooltip title={`${text}`}>
				<Link to={`${URLPaths.MODEL_EDIT.link}/${record.versionId}`}>{text}</Link>
			</Tooltip>,
		width: '34%',
		ellipsis: true,
	},

	{
		title: 'Создал',
		dataIndex: 'userName',
		key: 'userName',
		ellipsis: true,
		width: '15%',
		render: (text, record) =>
			<Tooltip title={record.versionId % 3 === 0 ? `System` : `${text}`}>
				{record.versionId % 3 === 0 ? <span><FontAwesomeIcon icon={faLock} /> System</span> : <span>{text}</span>}
			</Tooltip>,
	},

	{
		title: 'Полных обработок',
		dataIndex: 'amountOfTransactions',
		key: 'amountOfTransactions',
		ellipsis: true,
		align: 'center',
		width: '10%',
		render(value, record, index) {
			return <span style={{ display: 'inline-block', minWidth: 50, borderRadius: 8, backgroundColor: '#21AAFF', color: '#fff', padding: '0 5px' }}>{value}</span>
		},
	},
	{
		title: 'Ошибок',
		dataIndex: 'amountOfErrors',
		key: 'amountOfErrors',
		ellipsis: true,
		width: '10%',
		align: 'center',
		render(text, record, index) {
			return <span style={{ display: 'inline-block', borderRadius: 8, backgroundColor: '#FF4D4D', color: '#fff', padding: '0 5px', width: '50px' }}>{text}</span>
		},
	},
	{
		title: 'Время последней операции',
		dataIndex: 'createDate',
		key: 'createDate',
		ellipsis: true,
		width: '21%',
		render: (text) => {
			const date = new Date(text);
			const formattedDate = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}.${(date.getMonth() + 1) < 10 ? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}:${date.getSeconds() < 10 ? '0' : ''}${date.getSeconds()}`;
			return <span>{formattedDate}</span>;
		}
	},
]




