import { Flex, Button, Divider, FloatButton, Typography, Steps } from 'antd'
import { LoadingOutlined, StopOutlined, PlayCircleOutlined, PauseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import AddComponentsModal from './AddComponentsModal/AddComponentsModal';
import { useChangeModelState } from '../../../../../shared/queries';

function ModelTableControl({ currentType, currentState }: { currentType: number, currentState?: 'RUN' | 'PAUSE' | 'STOP' }) {

	const formik = useFormikContext()
	const { id } = useParams()
	const changeState = useChangeModelState({ id })

	return (
		<Flex gap="middle" justify='flex-start' style={{ width: '100%', paddingLeft: 20 }}>
			{changeState.contextHolder}

			<Typography.Title level={5} style={{ width: '25%' }}>Логика</Typography.Title>
			<Flex justify='flex-end' gap='middle' style={{ width: '75%' }}>

				<Steps
					items={[
						{
							title: 'Setting',
							status: currentState === 'RUN' ? 'finish' : 'process',
						},
						{
							title: currentState === 'RUN' ? 'Active' : 'Disabled',
							status: currentState === 'RUN' ? 'process' : 'wait',
							icon: currentState === 'RUN' ? <LoadingOutlined /> : null,
						},
					]}
				/>

				<AddComponentsModal currentType={currentType} />

				<Divider style={{ height: '100%' }} type="vertical" />

				<Flex gap='small'>
					<Button icon={<PlayCircleOutlined />}
						onClick={() => changeState.mutate('RUN')}
						disabled={currentState === 'RUN'} />
					<Button icon={<PauseCircleOutlined />}
						onClick={() => {
							changeState.mutate('PAUSE')
						}
						}
						disabled={currentState === 'PAUSE'} />
					<Button
						onClick={() => changeState.mutate('STOP')}
						icon={<StopOutlined />} disabled={currentState === 'STOP'} />
				</Flex>

			</Flex>
			{/* </ Permission> */}
			<FloatButton onClick={() => formik.handleSubmit()} icon={<SaveOutlined />} />

		</Flex>
	)
}

export default ModelTableControl