import { Flex, Typography } from 'antd'
import styles from './MegaMenu.module.scss'

function MegaMenu({ ChangeMegaMenuStatus }: { ChangeMegaMenuStatus: (status: boolean) => void }) {

	const { wrapper, container, singleItem } = styles
	const { Title } = Typography;

	return (
		<div
			onMouseEnter={() => ChangeMegaMenuStatus(true)}
			onMouseLeave={() => ChangeMegaMenuStatus(false)}
			className={wrapper}
		>

			<div className={container}>

				<Flex justify='space-between'>

					<Flex vertical justify='space-between' style={{ height: 200 }}>
						<Title level={4}>Title</Title>
						<span className={singleItem}>item1</span>
						<span className={singleItem}>item2</span>
						<span className={singleItem}>item3</span>
						<span className={singleItem}>item4</span>
					</Flex>

					<Flex vertical justify='space-between' style={{ height: 200 }}>
						<Title level={4}>Title</Title>
						<span className={singleItem}>item1</span>
						<span className={singleItem}>item2</span>
						<span className={singleItem}>item3</span>
						<span className={singleItem}>item4</span>
					</Flex>

					<Flex vertical justify='space-between' style={{ height: 200 }}>
						<Title level={4}>Title</Title>
						<span className={singleItem}>item1</span>
						<span className={singleItem}>item2</span>
						<span className={singleItem}>item3</span>
						<span className={singleItem}>item4</span>
					</Flex>

					<Flex vertical justify='space-between' style={{ height: 200 }}>
						<Title level={4}>Title</Title>
						<span className={singleItem}>item1</span>
						<span className={singleItem}>item2</span>
						<span className={singleItem}>item3</span>
						<span className={singleItem}>Show all products</span>
					</Flex>

				</Flex>

			</div>

		</div>
	)
}

export default MegaMenu