import React, { FC, useEffect, useState } from 'react'
import { ComponentType } from '../../../../../common/constants';
import CustomAntTable from '../../../../../components/CustomAntTable';
import { useAppSelector } from '../../../../../redux/Store';


import { IModelStructComponents } from '../../../../../shared/entities/Connector/Connector';
import { inputColumns, outputColumns, rulesColumns } from './AddComponentsModal/columnTitles';

interface GridModelsProps {
	type: ComponentType;
	changeType: () => void
}

const AntGridModels: FC<GridModelsProps> = ({ type, changeType }) => {

	const modelStructComponents = useAppSelector((store) => {
		const model = store.ModelReducer.model;

		if (model && 'modelStructComponents' in model) {
			return model.modelStructComponents as IModelStructComponents[];
		}

		// Обработка случая, когда modelStructComponents отсутствует
		return null; // Или другое значение по умолчанию
	});

	const inputConList = useAppSelector(state => state.ConnectorReducer.connectors)
	const RulesList = useAppSelector(state => state.RuleReducer.rules)
	const [dataSource, setDataSource] = useState<IModelStructComponents[]>()
	const [columns, setColumns] = useState<{ title: string, dataIndex: string, key: string }[]>()

	const setData = (type: ComponentType) => {
		switch (type) {
			case ComponentType.Input:
				setDataSource(modelStructComponents?.filter((model: IModelStructComponents) => model.type === 'CONNECTOR_INPUT'));
				setColumns(inputColumns)

				return
			case ComponentType.Output:
				setDataSource(modelStructComponents?.filter((model: IModelStructComponents) => model.type === 'CONNECTOR_OUTPUT'));
				setColumns(outputColumns)
				return
			case ComponentType.Rules:
				setDataSource(modelStructComponents?.filter((model: IModelStructComponents) => model.type === 'RULE'));
				setColumns(rulesColumns)
				return
			default:
				return setDataSource([]);
		}
	}


	useEffect(() => {

		setData(type)

	}, [type, modelStructComponents, inputConList, RulesList])


	return (
		<CustomAntTable
			cols={columns !== undefined ? columns : []}
			dataSource={dataSource}
			changeType={changeType}
		// fullData={fullData}
		/>
	)
}

export default AntGridModels