import { message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';

function useCustomToast() {
	const [messageApi, contextHolder] = message.useMessage();

	const handleToast = ({ type, text, key }: { type: NoticeType, text: string, key: number | string }) => {
		messageApi.open({ type: type, content: text, key });
	};

	return { contextHolder, handleToast };
}

export default useCustomToast;
