import axios from "axios";

export const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3000/" : `https://api.antifraud-test.symtech-cloud.kz/`;

export const api = axios.create({
	baseURL
})

api.interceptors.request.use(
	(config) => {
		// const token = Cookies.get("authToken");
		const token = localStorage.getItem("authToken");

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		console.log('error', error)
		return Promise.reject(error);
	}
);