import { faArrowLeft, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FloatButton } from 'antd'
import { useNavigate } from 'react-router-dom'
import { URLPaths } from '../../config/application/URLPaths'
import { useDispatch } from 'react-redux'
import { ActionTypes } from '../../redux/Model/ModelReducer'

function ReturnButton({ tab, fromModel }: { tab: string, fromModel?: string }) {

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const paths = {
		[URLPaths.MODELS.text]: URLPaths.MODELS.link,
		[URLPaths.CONNECTORS.text]: URLPaths.CONNECTORS.link,
		[URLPaths.RULES.text]: URLPaths.RULES.link,
		[URLPaths.DATASTRUCTURES.text]: URLPaths.DATASTRUCTURES.link,
	};



	return (
		<FloatButton
			shape="square"
			style={{ right: 24, bottom: 90 + 24 }}
			icon={<FontAwesomeIcon icon={faArrowLeft} />}
			onClick={() => {

				const path = paths[tab];

				dispatch({
					type: ActionTypes.GENERAL_CHANGE_MODEL_TAB,
					payload: tab
				})
				navigate(!fromModel ? path : `${URLPaths.MODEL_EDIT.link}/${fromModel}`)
			}}
		/>
	)
}

export default ReturnButton