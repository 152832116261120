
import { Button, Flex, FloatButton, message } from 'antd'
import { useFormikContext } from 'formik';
import { useAppSelector } from '../../redux/Store';
import { useDispatch } from 'react-redux';
import { ActionTypes as inputConActionTypes } from '../../redux/Model/ConnectorReducer';
import { DataType } from './types';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { SaveOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import InputFromJSON from '../../views/Model/connectors/edit/jsonInput/JsonInput';
import SelectableStructure from '../../views/Model/rules/edit/dataStructureTable/selectableStructure';



interface fieldType {
	id: number;
	name: string;
	fieldType: string;
	maxSize: number;
	key?: number;
	allowEmpty: boolean;
	prohibitSpecCharacters: boolean;
	allowArray: boolean;
	maxArray: number;
	children: fieldType[] | null;
}

function ControlElements({ grid, isInputConnector }: { grid: DataType[], isInputConnector: boolean }) {
	const formik = useFormikContext();
	const dispatch = useDispatch();
	const connector = useAppSelector(store => store.ConnectorReducer.connector);
	const currentCon = useAppSelector(store => store.ConnectorReducer.fieldResponse)

	const [messageApi, contextHolder] = message.useMessage();
	// const outputController = new ConnectorOutputController(dispatch);


	const addField = () => {

		const countFields = (fields: fieldType[]): number => {
			let count = 0;
			fields.forEach(field => {
				count++; // Учитываем текущее поле
				if (field.children) {
					count += countFields(field.children); // Рекурсивно учитываем дочерние поля
				}
			});
			return count;
		};


		dispatch({
			type: inputConActionTypes.CONNECTOR_ADD_ROW,
			payload: {

				newField: {
					name: `newField${connector && countFields(connector.fields) + 1}`,
					id: connector ? countFields(connector.fields) + 1 : 1,
					fieldType: "STRING",
					maxSize: 255,
					allowEmpty: true,
					prohibitSpecCharacters: true,
					allowArray: false,
					maxArray: 0,
					newItem: true,
				},
				fromParent: currentCon ? currentCon.id : null
			},
		});


	};


	const saveChanges = async () => {

		if (grid && connector?.urlForInfo && connector.summarySubType === 'REQUESTER') {
			dispatch({
				type: inputConActionTypes.CONNECTOR_CHANGE_POS,
				payload: grid
			})


			formik.setFieldValue("fields", removeIdFromGrid(grid));
			formik.handleSubmit()
			// await new Promise(resolve => setTimeout(resolve, 1000));
			// connectorController.get(String(id)).then(res => {
			// })
		}

		else if (grid && connector && connector.summarySubType !== 'REQUESTER') {
			dispatch({
				type: inputConActionTypes.CONNECTOR_CHANGE_POS,
				payload: grid
			})

			formik.setFieldValue("fields", removeIdFromGrid(grid));
			formik.handleSubmit()
			// await new Promise(resolve => setTimeout(resolve, 1000));
			// connectorController.get(String(id)).then(res => {
			// })
		}



		else {
			messageApi.open({
				type: 'error',
				content: `Отсутвует URL`,
			});
		}

	};

	const removeIdFromGrid = (grid: any[]) => {
		return grid.map((item: any) => {
			const { children, ...rest } = item;
			const updatedItem = { id: -1, ...rest };

			if (children && Array.isArray(children)) {
				updatedItem.children = removeIdFromGrid(children);
			}

			return updatedItem;
		});
	};


	return (

		<Flex gap='middle' style={{ width: '100%', paddingBottom: '20px' }} justify='space-between'>

			{contextHolder}
			<Flex gap='middle' >

				<Button type="primary" icon={<PlusOutlined style={{ color: '#FFF' }} />} onClick={() => addField()} />


				<Button type="primary" icon={<MinusOutlined style={{ color: '#FFF' }} />} onClick={() => {

					dispatch({
						type: inputConActionTypes.CONNECTOR_REMOVE_ROW,
					})

				}} />

				<InputFromJSON isInputConnector={isInputConnector} />

				<SelectableStructure />

			</Flex>

			<FloatButton icon={<SaveOutlined />} onClick={saveChanges} />




		</Flex >

	)
}

export default ControlElements