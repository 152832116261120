import CopingText from "../../../../../components/CopingText/CopingText"

export const inputServerCols = [
	{
		title: 'Название',
		dataIndex: 'name',
		key: 'name',
		width: '15%',
		ellipsis: true,
	},
	// {
	// 	title: 'Описание',
	// 	dataIndex: 'description',
	// 	key: 'description',
	// 	width: '15%'
	// },
	{
		title: 'DNS-имя',
		dataIndex: 'dnsName',
		key: 'dnsName',
		width: '20%',
		ellipsis: true,
	},
	{
		title: 'IP-адрес',
		dataIndex: 'ip',
		key: 'ip',
		width: '15%',
		ellipsis: true,
	},
	{
		title: 'confirm',
		dataIndex: 'confirmAuth',
		key: 'confirmAuth',
		render: (text: boolean) => <span>{text === true ? 'Да' : 'Нет'}</span>,
		width: '10%'
	},
	{
		title: 'token',
		dataIndex: 'token',
		key: 'token',
		width: '20%',
		ellipsis: true,
		render: (text: string) => <CopingText text={text} description='Токен' />
	},
	{
		title: 'Тип аутентификации',
		dataIndex: 'authType',
		key: 'authType',
		ellipsis: true,
		width: '20%'
	},
]

export const outputServerCols = [
	{
		title: 'Наименование',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Описание',
		dataIndex: 'description',
		key: 'description',
	},
	{
		title: 'DNS-имя',
		dataIndex: 'dns',
		key: 'dns',
	},
	{
		title: 'IP-адрес',
		dataIndex: 'ip',
		key: 'ip',
	},
	{
		title: 'Требуется аутентификация',
		dataIndex: 'auth',
		key: 'auth',
	},
	{
		title: 'Учетная запись',
		dataIndex: 'login',
		key: 'login',
	},
	{
		title: 'Тип аутентификации',
		dataIndex: 'authType',
		key: 'authType',
	},
]