import { Flex } from "antd";
import { FC } from "react";

export const Page404: FC = () => {

	return <Flex vertical
		justify={"center"} align={"center"} style={{ height: '100%' }}>
		<span>This page does not exist</span>
	</Flex>

}