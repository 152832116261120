import { Badge, TableColumnsType, Tooltip } from "antd";
import { URLPaths } from "../../../../../config/application/URLPaths";
import { Link } from "react-router-dom";
import { faQ, faSheetPlastic, faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CaretUpOutlined } from '@ant-design/icons';


const superVisedLearn = process.env.PUBLIC_URL + '/static/supervisedLearn.svg'
const ubsuperVisedLearn = process.env.PUBLIC_URL + '/static/unsupervisedLearn.svg'
const ml = process.env.PUBLIC_URL + '/static/ml.svg'
const defaultRule = process.env.PUBLIC_URL + '/static/defaultRule.svg';
const sharedIcon = process.env.PUBLIC_URL + '/static/common.svg'

export const RuleListTitles: TableColumnsType<IRuleList> = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		width: '25%',
		render: (text, record) => <Link to={`${URLPaths.RULES_EDIT.link}/${record.versionId}`}>{text}</Link>
	},
	{
		title: 'Создал',
		dataIndex: 'userName',
		key: 'userName',
		width: '10%',
		render: (text, record) => <span>{text}</span>
	},
	{
		title: 'Цель',
		dataIndex: 'purpose',
		key: 'purpose',
		width: '29%',
		ellipsis: true,
		render: (text, record) => <span>Определение инцидента</span>
	},
	{
		dataIndex: 'summarySubType',
		key: 'summarySubType',
		width: '5%',
		align: 'center',
		render: (text, record) => (text === 'ML_WITH_TRAINING') ?
			<Tooltip title="Обученное учителем">
				<span style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', }}>
					<img src={superVisedLearn} alt="running" style={{ width: 20, paddingLeft: '0px', marginBottom: '-4px' }} />
				</span>
			</Tooltip>
			:
			(text === 'ML_WITHOUT_TRAINING') ?
				<Tooltip title="Обученное без учителя">
					<span style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', }}>
						<img src={ubsuperVisedLearn} alt="running" style={{ width: 20, paddingLeft: '0px', marginBottom: '-4px' }} />
					</span>
				</Tooltip>
				:
				(text === 'NEURAL_NETWORK') ?
					<Tooltip title="Нейронная сеть">
						<span style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', }}>
							<img src={ml} alt="running" style={{ width: 20, paddingLeft: '0px', marginBottom: '-4px' }} />
						</span>
					</Tooltip>
					:
					(text === 'QUALITY') ?
						<Tooltip title={'Качество'}>
							<FontAwesomeIcon icon={faQ} style={{ color: '#7FA7D5' }} />
						</Tooltip> :
						(text === 'QUANTITY') ?
							<Tooltip title={'Количество'}>
								<Badge count={<CaretUpOutlined style={{ color: '#847FD5' }} />} size='small'>
									<FontAwesomeIcon icon={faQ} style={{ color: '#847FD5' }} />
								</Badge>
							</Tooltip>
							:
							<Tooltip title="Обычное">
								<span style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', }}>
									<img src={defaultRule} alt="running" style={{ width: 20, paddingLeft: '0px', marginBottom: '-4px' }} />
								</span>
							</Tooltip>
	},
	// {
	// 	dataIndex: 'summarySubType',
	// 	key: 'summarySubType',
	// 	width: '5%',
	// 	align: 'center',
	// 	render: (text, record) =>
	// 		<Tooltip title={text}>
	// 			{text === 'QUALITY' ?
	// 				<FontAwesomeIcon icon={faQ} /> :
	// 				<Badge count={<CaretUpOutlined />} size='small'>
	// 					<FontAwesomeIcon icon={faQ} />
	// 				</Badge>}
	// 		</Tooltip>
	// },
	{
		dataIndex: 'common',
		key: 'common',
		width: '5%',
		align: 'center',
		render: (text, record) =>
			<Tooltip title={text === true ? 'Общее' : null}>
				{text === true ?
					<span style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
						<img src={sharedIcon} alt="shared" style={{ width: 20, paddingLeft: '0px' }} />
					</span>
					: null
				}
			</Tooltip>
	},
	{
		dataIndex: 'summaryState',
		key: 'summaryState',
		width: '5%',
		align: 'center',
		render: (text, record) => <FontAwesomeIcon style={text === 'DRAFT' ? { color: '#B9B9B9' } : { color: '#7FD581' }} icon={text === 'DRAFT' ? faSheetPlastic : faFileCircleCheck} />
	},
	{
		title: 'Время последней операции',
		dataIndex: 'createDate',
		key: 'createDate',
		ellipsis: true,
		width: '21%',
		render: (text) => {
			const date = new Date(text);
			const formattedDate = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}.${(date.getMonth() + 1) < 10 ? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}:${date.getSeconds() < 10 ? '0' : ''}${date.getSeconds()}`;
			return <span>{formattedDate}</span>;

		}
	},


	// {
	// 	title: 'Type',
	// 	dataIndex: 'type',
	// 	key: 'type',
	// 	width: 0,
	// 	render: (text, record) => <span>{text === 'RULE' && 'ruleType' in record ? (record as Rule).ruleType : text}</span>
	// },

]