import { Button, Flex, Modal, Switch, Table, TableProps } from 'antd'
import { useFormikContext } from 'formik';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../../../redux/Model/ConnectorReducer';
import { ActionTypes as RuleActionTypes } from '../../../../../redux/Model/RuleReducer';
import { ActionTypes as DataStructureTypes } from '../../../../../redux/Model/DataStructureReducer';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { getAllDataStructure } from '../../../../../api/requests';
import { useFetchDataStructure } from '../../../../../shared/queries';
// import { TableParams } from '../../../common/all/Models';

type IDataSource = {
	versionId: number,
	userName: string,
	models?: Array<any>,
	name: string,
}
function SelectableStructure({ generalType }: { generalType?: 'connector' | 'rule' }) {

	const formik = useFormikContext()
	const { id } = useParams()
	const dispatch = useDispatch()
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedDataSourceId, setSelectedDataSourceId] = useState<string>(String(id))
	const [tableParams, setTableParams] = useState<IGeneralTableParams>({
		page: 0,
		size: 10,
		userId: 1
	});

	const fetchDataStructure = useFetchDataStructure({ params: tableParams })

	const showModal = () => {
		dispatch({ type: generalType === 'rule' ? RuleActionTypes.RULE_SET_EXTEND : ActionTypes.CONNECTOR_SET_EXTEND, payload: { id: selectedDataSourceId, type: 'ADD' } })
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		dispatch({ type: generalType === 'rule' ? RuleActionTypes.RULE_SET_EXTEND : ActionTypes.CONNECTOR_SET_EXTEND, payload: { id: selectedDataSourceId, type: 'ADD' } })
		// formik.setFieldValue('extended', selectedDataSourceId).then(() => formik.handleSubmit())
		formik.handleSubmit()
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const cols = [
		{
			title: 'Название',
			dataIndex: 'name',
		},
		{
			title: 'Создал',
			dataIndex: 'userName',
		}
	]

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: IDataSource[]) => {
			setSelectedDataSourceId(String(selectedRows[0].versionId))
		},
		getCheckboxProps: (record: IDataSource) => ({
			disabled: record.name === 'Disabled User', // Column configuration not to be checked
			name: record.name,
		}),
	};

	const onChange = (checked: boolean) => {
		dispatch({ type: generalType === 'rule' ? RuleActionTypes.RULE_SET_EXTEND : ActionTypes.CONNECTOR_SET_EXTEND, payload: { id: selectedDataSourceId, type: checked === true ? 'ADD' : 'REPLACE' } })
	};

	const handleTableChange: TableProps['onChange'] = (pagination) => {
		const newPage = pagination.current ? pagination.current - 1 : 0;
		const newPageSize = Number(pagination.pageSize);

		if (newPage >= 0 && (newPage !== tableParams.page || newPageSize !== tableParams.size)) {
			setTableParams({
				...tableParams,
				page: newPage,
				size: newPageSize,
			})
		}
	}

	return (
		<>
			<Button type="primary" onClick={showModal} >
				<span style={{ color: '#FFF' }}>Выбрать структуру данных</span>
			</Button>
			<Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<Flex gap='middle' align='flex-end' vertical>
					<Flex gap='small' align='center'>

						<Switch
							style={{ backgroundColor: '#fafafa' }}
							defaultChecked
							checkedChildren={<span style={{ color: '#000' }}>Добавить</span>}
							onChange={onChange}
							unCheckedChildren={<span style={{ color: '#000' }}>Заменить</span>}
						/>

					</Flex>
					<Table
						style={{ width: '100%' }}
						dataSource={fetchDataStructure.data?.list}
						columns={cols}
						rowKey={(dataSource) => dataSource.versionId}
						size='small'
						rowSelection={{
							type: 'radio',
							...rowSelection,
						}}
						pagination={{
							position: ['bottomRight'],
							current: tableParams.page + 1,
							pageSize: tableParams.size,
							total: fetchDataStructure.data?.totalCount
						}}
						loading={fetchDataStructure.isPending}
						onChange={handleTableChange}
					/>
				</Flex>
			</Modal>
		</>

	)
}

export default SelectableStructure