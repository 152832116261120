import { AxiosRequestConfig } from "axios";
import { api } from "../../instance";

interface RequestConnectorsParams {
	params?: { page: number, size: number, userId: number };
	config?: AxiosRequestConfig;
}

export const getAllConnectors = async ({ params, config }: RequestConnectorsParams) => {
	const response = await api.post<IGetConnectorsList>('core-service/api/connector/all',
		{},
		{ params, ...config }
	)
	return response.data
}

export const postConnectorWithUpdates = async ({ config }: RequestConnectorsParams): Promise<IGetConnectorsList> => {
	try {
		const res = await api.post<IGetConnectorsList>('core-service/api/connector/create-or-update', { ...config });
		return res.data;
	} catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}

		return Promise.reject({ message: errorMessage });
	}
};
