import { FC, ReactNode, useState } from 'react'
import { Flex, Input, Radio, Space, Spin, Switch, Table, Tooltip, Typography, Button, notification } from 'antd'
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../redux/Store';
import { useClipboard } from 'use-clipboard-copy';
import { CopyOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import CronPicker from './cron/CronPicker';
import { ActionTypes } from '../../../../../redux/Model/ConnectorReducer';
import { EnterOutlined } from '@ant-design/icons';
import { NestedObject } from '../jsonInput/JsonInput';
import LoginTable from './loginTable/LoginTable';
import { inputServerCols, outputServerCols } from './TableTitles';
import { RightOutlined, UpOutlined } from "@ant-design/icons";
import styles from './ConnectorFormInformation.module.scss'
import { baseURL } from '../../../../../api/instance';

type ISingleField = {
	title: string;
	fullTitle?: string
	children: ReactNode
}

const SingleField: FC<ISingleField> = ({ title, children, fullTitle }) => {
	return (
		<Flex gap='middle' justify='flex-start' align='flex-start'>
			<Flex style={{ width: '25%' }} justify='flex-start' align='center' gap='middle'>
				<Typography.Title level={5} style={{ margin: 0 }}>{title}</Typography.Title>
				{fullTitle && <Tooltip title={fullTitle}>
					<FontAwesomeIcon icon={faCircleInfo} />
				</Tooltip>}
			</Flex>
			{children}
		</Flex>
	)
}

function ConnectorFormInformation({ connectorType, defaultInfo }: { connectorType: 'input' | 'output', defaultInfo?: IGetConnectorById }) {

	const dispatch = useDispatch()
	const formik = useFormikContext()

	const { fromModel } = useParams()
	const modelSummaryDataId = useAppSelector(store => store.ModelReducer.model?.summaryDataId)
	const userId = useAppSelector(store => store.ModelReducer.model?.userId)
	const commonValue = useAppSelector(store => store.ConnectorReducer.connector?.common)
	const dataSource = useAppSelector(store => store.ConnectorReducer.connector?.connectionList?.list)
	const clipboard = useClipboard();
	const [api, contextHolder] = notification.useNotification();
	const [url, setUrl] = useState<string>()
	const [selectedRowId, setSelectedRowId] = useState<number>()


	const modelId = modelSummaryDataId && modelSummaryDataId.split('-')[0]


	if (defaultInfo) {

		const CopyLink =
			(urlType: string) => {
				const url = `${baseURL}handler-service/api/v1/handler/${modelId}-${summaryDataId.split('-')[0]}/${urlType}${userId}`
				clipboard.copy(url);
				api.info({
					message: `Успешно скопировано`,
					description: `Скопирована ссылка: ${url}`,
					placement: 'bottomRight',
				});
			}

		const editField = (e: React.ChangeEvent<HTMLInputElement>) => {
			formik.setFieldValue('name', e.target.value)
		}

		const editTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
			formik.setFieldValue('description', e.target.value)
		}

		const getFieldsFromURL = (url: string) => {
			fetch(url)
				.then(response => {
					return response.json();
				})
				.then(parsedValue => {
					let idCounter = 0;

					function convertObjectToArray(obj: Record<string, any>): NestedObject[] {
						return Object.entries(obj).map(([key, value], index) => {
							const type =
								typeof value === 'string'
									? 'STRING'
									: typeof value === 'object'
										? 'OBJECT'
										: typeof value === 'number' && Number.isInteger(value)
											? 'INTEGER'
											: typeof value === 'number' && !Number.isInteger(value)
												? 'DOUBLE'
												: 'STRING';
							const id = idCounter++;

							if (typeof value === 'object' && value !== null) {
								return {
									id,
									name: key,
									fieldType: type,
									maxArray: 255,
									allowEmpty: true,
									prohibitSpecCharacters: true,
									allowArray: false,
									maxSize: 255,
									children: convertObjectToArray(value),
								};
							} else {
								return {
									id,
									name: key,
									fieldType: type,
									maxArray: 255,
									allowEmpty: true,
									prohibitSpecCharacters: true,
									allowArray: false,
									maxSize: 255,
								};
							}
						});
					}

					const fieldsData = convertObjectToArray(parsedValue);

					dispatch({
						type: ActionTypes.CONNECTOR_SET_FIELDS_TABLE,
						payload: fieldsData,
					});

					// console.log('Parsed value:', fieldsData);
					api.info({
						message: `Таблица обновлена`,
						description: `Поля успешно обновлены`,
						placement: 'bottomRight',
					});
				})
				.catch(error => {
					console.error('Error fetching data:', error);
					// Handle errors here
				});
		};


		const { connectorSubType, versionId, type, name, description, summarySubType, urlForInfo, models, userName, summaryDataId } = defaultInfo as IGetConnectorById


		return (
			<Space direction='vertical' style={{ width: '100%' }} size='middle'>

				{contextHolder}

				<SingleField title='Идентификатор'>
					<span>{versionId}</span>
				</SingleField>

				<SingleField title='Вид коннектора'>
					<span>
						{
							type === 'CONNECTOR_INPUT' && summarySubType === 'AWAITINGER' ? 'Ожидающий '
								:
								type === 'CONNECTOR_INPUT' && summarySubType === 'REQUESTER' ? 'С запросом/инициацией на получение '
									:
									'Отправка данных '}
						(
						{
							connectorSubType === 'REQUEST_RESPONSE' ? 'Запрос-ответ' :
								connectorSubType === 'STREAMING' ? 'Потоковый' :
									connectorSubType === 'WITHOUT_WAITING_RESPONSE' ? 'Без ожидания ответа' :
										'Потоковый с запросом-ответом'}
						)</span>
				</SingleField>

				<SingleField title='Используется в моделях'>
					<span>{models.length > 0 ? models.map(model => model.modelName).join(', ') : 'Не используется ни в 1 модели'}</span>
				</SingleField>

				<SingleField title='Наименование'>
					<Input defaultValue={name} style={{ width: '40%', height: 32, borderRadius: 8 }} onChange={editField} />
				</SingleField>

				<SingleField title='Описание'>
					<Input.TextArea
						defaultValue={description}
						rows={3}
						style={{ width: '40%', resize: 'none' }}
						onChange={editTextarea}
					/>
				</SingleField>

				<SingleField title='Создал'>
					<span>{userName}</span>
				</SingleField>

				<SingleField title='Совместное использование' fullTitle='Разрешить совместное использование коннектора 2-мя или более моделями'>
					<Switch
						defaultValue={commonValue}
						value={commonValue}
						onChange={(e) =>
							dispatch({
								type: ActionTypes.CONNECTOR_UPDATE_INFORMATION_VALUE, payload: {
									key: 'common',
									value: e
								}
							})}
					/>
				</SingleField>


				{connectorType === 'input' &&
					<SingleField
						fullTitle={`Разрешить ${summarySubType === 'AWAITINGER' ? 'входящие' : 'исходящие'} соединения с коннектором для систем и учетных записей`}
						title={`${summarySubType === 'AWAITINGER' ? 'Входящие' : 'Исходящие'} соединения`}>

						<Flex
							style={{ width: '75%' }}
							vertical
							gap='middle'>
							<LoginTable currentField={selectedRowId} />
							<Table
								columns={inputServerCols}
								dataSource={dataSource}
								rowKey={record => record.id}
								size="small"
								expandable={{
									expandedRowRender: (record) => <Flex vertical gap='small'>
										<Typography.Title level={5} style={{ margin: 0 }}>{record.name}</Typography.Title>
										<Typography.Text type="secondary">Description: {record.description}</Typography.Text>
										<Typography.Text style={{ margin: 0 }}>DNS: {record.dnsName}</Typography.Text>
										<Typography.Text style={{ margin: 0 }}>IP: {record.ip}</Typography.Text>
										<Typography.Text>
											<Typography.Text style={{ margin: 0 }}>Token:</Typography.Text>
											<Typography.Paragraph copyable>{record.token}</Typography.Paragraph>
										</Typography.Text>
									</Flex>,
									expandIcon: ({ expanded, onExpand, record }) =>
										expanded ? (
											<UpOutlined onClick={e => onExpand(record, e)} />
										) : (
											<RightOutlined onClick={e => onExpand(record, e)} />
										)
								}}
								onRow={(record) => {
									return {
										onClick: () => {
											setSelectedRowId(record.id)
										},
									};
								}
								}
								rowClassName={(record) => (record.id === selectedRowId ? styles.picked : styles.default)}
							/>
						</Flex>
					</SingleField>}

				{connectorType === 'output' && <SingleField fullTitle='Удаленный сервер и учетная запись' title='Удаленный сервер'>
					<Table
						columns={outputServerCols}
						dataSource={dataSource}
						rowKey={record => record.id}
						size="small"
						style={{ width: '65%' }}
					/>
				</SingleField>}


				{summarySubType === 'REQUESTER' && <SingleField title={'URL'}>
					<Flex vertical style={{ width: '30%' }} gap='middle'>
						<Flex justify='space-between'>
							<Input.Search
								style={{ width: 304, borderRadius: 8 }}
								enterButton={<EnterOutlined />}
								onSearch={() => {
									if (url) { getFieldsFromURL(url) }
								}}
								onChange={e => {
									// formik.setFieldValue('urlForInfo', e.target.value)
									dispatch({
										type: ActionTypes.CONNECTOR_UPDATE_INFORMATION_VALUE, payload: {
											key: 'urlForInfo',
											value: e.target.value
										}
									})
									setUrl(e.target.value)
								}}
								defaultValue={urlForInfo ? urlForInfo : url}

								// enterButton={<EnterOutlined />}
								placeholder='https://example.com'
							// onSearch={() => formik.setFieldValue('url', url)}
							/>
						</Flex>
						{urlForInfo && <span>Полный адрес: {urlForInfo}</span>}
					</Flex>
				</SingleField>}



				{summarySubType === 'REQUESTER' && <SingleField title={'Расписание'}>
					<Flex vertical style={{ width: '70%' }} gap='middle'>
						<Flex justify='flex-start' gap='middle' align='center' >
							<CronPicker />
						</Flex>
					</Flex>
				</SingleField>}

				{fromModel && connectorType === 'input' &&

					<SingleField fullTitle='Адрес коннектора для внешних систем' title='Адрес коннектора'>

						<Flex vertical gap="small" style={{ width: '40%' }}>

							<Flex justify='flex-start' align='center' gap='middle'>

								<Flex justify='flex-start' align='center' gap='middle' style={{ width: 250 }}>
									<Button icon={<CopyOutlined />} onClick={() => CopyLink('')} />
									<Tooltip placement="topLeft"
										title={`${baseURL}api/v1/connector-handler/${modelId}-${summaryDataId.split('-')[0]}/${userId}`}
									>
										<span >Основной</span>
									</Tooltip>
								</Flex>

								<Radio.Group value={2} style={{ width: '25%', display: 'flex' }}>
									<Radio value={1} disabled>GET</Radio>
									<Radio value={2}>POST</Radio>
								</Radio.Group>

							</Flex>



							<Flex justify='flex-start' align='center' gap='middle'>
								<Flex justify='flex-start' align='center' gap='middle' style={{ width: 250 }}>


									<Button icon={<CopyOutlined />} onClick={() => CopyLink('status/')} />

									<Tooltip placement="topLeft"
										title={`${baseURL}api/v1/connector-handler/${modelId}-${summaryDataId.split('-')[0]}/status/${userId}`}
									>
										<span >	Для проверки состояния</span>
									</Tooltip>

								</Flex>
								<Radio.Group value={2} style={{ width: '25%', display: 'flex' }}>
									<Radio value={1} disabled>GET</Radio>
									<Radio value={2}>POST</Radio>
								</Radio.Group>
							</Flex>


							<Flex justify='flex-start' align='center' gap='middle'>
								<Flex justify='flex-start' align='center' gap='middle' style={{ width: 250 }}>

									<Button icon={<CopyOutlined />} onClick={() => CopyLink('result/')} />

									<Tooltip placement="topLeft"
										title={`${baseURL}api/v1/connector-handler/${modelId}-${summaryDataId.split('-')[0]}/result/${userId}`}
									>
										<span >Для получения результата</span>
									</Tooltip>
								</Flex>

								<Radio.Group value={2} style={{ width: '25%', display: 'flex' }}>
									<Radio value={1} disabled>GET</Radio>
									<Radio value={2}>POST</Radio>
								</Radio.Group>
							</Flex>

						</Flex>
					</SingleField>
				}

			</Space>
		)
	}

	else return (<Spin />)


}

export default ConnectorFormInformation