import { Tabs, TabsProps } from 'antd'
import React from 'react'
import ParticipantType from './participantType/ParticipantType';
import Organization from './organization/Organization';
import Teams from './Teams/Teams';
import People from './people/People';
import Processes from './processes/Processes';
import IncidentPriority from './incidentPriority/IncidentPriority';

function Handbook() {

	const items: TabsProps['items'] = [
		{ key: '1', label: 'Виды вовлеченных сторон', children: <ParticipantType /> },
		{ key: '2', label: 'Организации и подразделения', children: <Organization /> },
		{ key: '3', label: 'Команды', children: <Teams /> },
		{ key: '4', label: 'Люди', children: <People /> },
		{ key: '5', label: 'Процессы', children: <Processes /> },
		{ key: '6', label: 'Приоритеты инцидентов', children: <IncidentPriority /> },
	];

	const onChange = (key: string) => {
		console.log(key);
	};

	return (
		<div style={{ width: '100%', padding: 20 }}>
			<Tabs
				defaultActiveKey="1"
				items={items}
				onChange={onChange}
			/>
		</div>
	)
}

export default Handbook