import { Mentions } from 'antd'
import React, { useCallback, useState } from 'react'
import { useAppSelector } from '../../../../../../redux/Store';
import type { MentionsProps } from 'rc-mentions';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../../../../redux/Model/RuleReducer';
import { useParams } from 'react-router-dom';

type IConditionTextarea = {
	isQuality: boolean
	data: any
	isCondition: boolean
	result: string
	editResult: (isCondition: boolean, newResult: string) => void
	selectConstructorMode: (mode: 'condition' | 'resultCondition') => void
	toggleEditingCondition: (val: boolean) => void
	editJsonData: (isCondition: boolean, newValue: string) => void
}

interface CustomArr {
	key: string;
	arr: string[];
}

interface CustomObject {
	[key: string]: string[];
}



const ConditionTextarea: React.FC<IConditionTextarea> = (
	{ result,
		editResult,
		isCondition,
		selectConstructorMode,
		toggleEditingCondition,
		editJsonData
	}
) => {

	const { fromModel } = useParams()
	const fields = useAppSelector(state => state.RuleReducer.rule?.fields);
	const dispatch = useDispatch()
	const fieldNameList = fields?.filter(item => item.defaultField === false)?.map(item => ({
		value: item.name.slice(1),
		label: item.name,
		key: item.name,
	})) as Array<{ value: string, label: string, key: string }>;
	const defaultprefixList = fieldNameList?.map(item => item.label[0])
	const prefixList = (fieldNameList?.map(item => item.label[0]) ?? []).concat('.');
	const uniquePrefixes: string[] = [...new Set(defaultprefixList ?? [])];
	const { selectedJsonData } = useAppSelector(store => store.RuleReducer)
	const customArray: CustomArr[] = uniquePrefixes.reduce((acc: CustomArr[], prefix: string) => {
		const arr: string[] = fieldNameList?.filter((item) => item.label.startsWith(prefix)).map((item) => item.value) ?? [];
		acc.push({ key: prefix, arr });
		return acc;
	}, []);

	const mockObject: CustomObject = customArray.reduce((obj: CustomObject, item) => {
		obj[item.key] = item.arr;
		return obj;
	}, {});

	const MOCK_DATA = {
		'.': ['1.0', '2.0', '3.0'],

	};
	mockObject['.'] = ['VAL', 'PREVAL', 'NAME'];

	type PrefixType = keyof typeof MOCK_DATA;

	const [prefix, setPrefix] = useState<PrefixType>('.');

	const onSearch: MentionsProps['onSearch'] = (_, newPrefix) => {
		setPrefix(newPrefix as PrefixType);
	};



	const onClick = () => {
		if (isCondition === true) {
			toggleEditingCondition(true)
			selectConstructorMode('condition')
			dispatch({
				type: ActionTypes.RULE_SET_CONDITION_ACTIVE,
				payload: true
			})
			dispatch({
				type: ActionTypes.RULE_SELECT_VALUE_TO_CONDITION,
				payload: { isValueSelecting: false, valueToCondition: '' }
			})
		}
		else {
			toggleEditingCondition(false)
			selectConstructorMode('resultCondition')
			dispatch({
				type: ActionTypes.RULE_SET_CONDITION_ACTIVE,
				payload: false
			})
			dispatch({
				type: ActionTypes.RULE_SELECT_VALUE_TO_CONDITION,
				payload: { isValueSelecting: false, valueToCondition: '' }
			})
		}
	}

	const handleConditionChange = useCallback((text: string) => {
		editResult(true, text);
	}, [editResult]);

	const handleResultConditionChange = (text: string) => {
		editResult(false, text)

	}

	const onBlur = (text: string) => {
		isCondition === true ?
			editJsonData(true, text) :
			editJsonData(false, text)
	}

	return (
		<Mentions
			onChange={isCondition === true ? handleConditionChange : handleResultConditionChange}
			prefix={prefixList}
			onSearch={onSearch}
			onBlur={() => onBlur(result)}
			options={(mockObject[prefix] || []).map((value) => ({
				key: value,
				value,
				label: `${prefix}${value}`,
			}))}
			split=''
			rows={4}
			value={result}
			disabled={fromModel ? true : false || (selectedJsonData !== null && selectedJsonData.type) === 'NO_EXPRESSION'}
			onClick={onClick}
		/>
	)
}

export default ConditionTextarea