import { UserOutlined } from '@ant-design/icons';
import { Avatar, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { URLPaths } from '../../../config/application/URLPaths';

function SignInHeaderAvatar() {

	const { Text } = Typography;

	return (
		<Link to={URLPaths.AUTH.link}>
			<Flex vertical align='center'>
				<Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
				<Text style={{ fontSize: '12px' }} >Sign in</Text>
			</Flex>
		</Link>
	)
}

export default SignInHeaderAvatar