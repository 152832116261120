import { useState } from 'react'
import { Modal, Space, Flex, Select, Input } from 'antd'
import { useUpdateRule } from '../../../../../shared/queries/rules/useRuleQuery'
interface IAddNewRuleModal {
	modalStatus: boolean
	closeModal: () => void
}

const AddNewRuleModal: React.FC<IAddNewRuleModal> = ({ modalStatus, closeModal }) => {

	const [newConName, setConName] = useState<string>()
	const [selectValue, setSelectValue] = useState('QUANTITY')

	const handleSelect = (value: string) => {
		setSelectValue(value);
	};

	const CreateNewRuleMutation = useUpdateRule({ toastMessage: 'New rule successfully added' })

	const confirmModelAdd = () => {

		const newRule = {
			name: newConName,
			summarySubType: selectValue,
			jsonData: selectValue === 'QUANTITY' ? [{
				condition: "",
				resultCondition: ""
			}] : [],
			fields: [],
			isCreate: true,
		}

		CreateNewRuleMutation.mutate(newRule);
		closeModal()
	};



	return (
		<Modal
			title="Создание правила"
			open={modalStatus}
			onOk={confirmModelAdd}
			onCancel={closeModal}>

			{CreateNewRuleMutation.contextHolder}

			<Space direction="vertical" size="middle">

				<Flex vertical gap='middle'>
					<span>Название</span>
					<Input
						value={newConName}
						onChange={(e) => setConName(e.target.value)}
					/>
				</Flex>

				<Flex vertical gap='middle'>
					<span>Тип данных результата</span>
					<Select
						defaultValue={selectValue}
						onChange={handleSelect}
						options={[{
							label: 'Доступные',
							options: [
								{ value: 'ORDINARY', label: 'Обычное' },
								{ value: 'QUANTITY', label: 'Количество' },
								{ value: 'QUALITY', label: 'Качество' },
								{ value: 'ML_WITH_TRAINING', label: 'ML с обучением' },
								{ value: 'ML_WITHOUT_TRAINING', label: 'ML без обучения' },
								{ value: 'NEURAL_NETWORK', label: 'Нейронная сеть' },
							]
						},
						{
							label: 'Недоступные',
							options: [

								{ value: 'QUANTITY_ACC', label: 'Количество аккумулятивных', disabled: true },
								{ value: 'STRING', label: 'Строка', disabled: true },
								{ value: 'ARRAY', label: 'Массив', disabled: true },
							]
						}

						]}
					/>

				</Flex>
			</Space>
		</Modal>
	)
}

export default AddNewRuleModal