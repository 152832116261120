import { Button, Flex } from 'antd'
import { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ActionTypes } from '../../../../../../redux/Model/RuleReducer'
import { useAppSelector } from '../../../../../../redux/Store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faDivide, faEquals, faGreaterThan, faGreaterThanEqual, faLessThan, faLessThanEqual, faMinus, faNotEqual, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'

const SingleButtonWrapper = ({ children, operation, type }: { children: ReactNode, operation: string, type: 'condition' | 'resultCondition' }) => {

	const { rule, selectedJsonData } = useAppSelector(store => store.RuleReducer)
	const dispatch = useDispatch()
	const [condition, setCondition] = useState('')
	const [result, setResult] = useState('')


	useEffect(() => {
		if (selectedJsonData) {
			setCondition(selectedJsonData?.condition ?? '');
			setResult(selectedJsonData?.resultCondition ?? '');
		}
	}, [selectedJsonData]);


	const addOperation = () => {
		if (rule?.summarySubType === 'QUANTITY' && type === 'condition') {
			dispatch({
				type: ActionTypes.RULE_EDIT_JSON_DATA,
				payload: {
					...selectedJsonData,
					isQuality: false,
					newCondition: condition.concat('', operation),
					newResultCondition: result,
				}
			});
		} else if (rule?.summarySubType === 'QUANTITY' && type === 'resultCondition') {
			dispatch({
				type: ActionTypes.RULE_EDIT_JSON_DATA,
				payload: {
					...selectedJsonData,
					isQuality: false,
					newResultCondition: result.concat('', operation),
					newCondition: condition,
				}
			})
		} else {
			dispatch({
				type: ActionTypes.RULE_EDIT_JSON_DATA,
				payload: {
					...selectedJsonData,
					isQuality: true,
					number: selectedJsonData && selectedJsonData.number,
					newCondition: selectedJsonData?.condition.concat('', operation),
				}
			});
		}

	}


	return (
		<Flex
			justify='center'
			align='center'
			style={{
				height: '100%',
				minWidth: 30,
				borderRadius: 4,
				border: '1px solid #000'
			}}
			onClick={addOperation}
		>
			{children}
		</Flex>
	)

}

function ConstructorSection({ type }: { type: 'condition' | 'resultCondition' }) {
	const [isExpanded, setExpanded] = useState(false)

	return (
		<Flex vertical gap='small' style={{ height: isExpanded ? 70 : 40 }} >
			<Flex gap='small' justify='space-between' style={{ height: 30 }} wrap='wrap'>
				<Flex gap='small' justify='flex-start' style={{ height: 30 }} >
					<SingleButtonWrapper operation=' +' type={type}>
						<FontAwesomeIcon icon={faPlus} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' -' type={type}>
						<FontAwesomeIcon icon={faMinus} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' *' type={type}>
						<FontAwesomeIcon icon={faXmark} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' /' type={type}>
						<FontAwesomeIcon icon={faDivide} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' ==' type={type}>
						<FontAwesomeIcon icon={faEquals} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' !=' type={type}>
						<FontAwesomeIcon icon={faNotEqual} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' >' type={type}>
						<FontAwesomeIcon icon={faGreaterThan} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' >=' type={type}>
						<FontAwesomeIcon icon={faGreaterThanEqual} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' <' type={type}>
						<FontAwesomeIcon icon={faLessThan} />
					</SingleButtonWrapper>
					<SingleButtonWrapper operation=' <=' type={type}>
						<FontAwesomeIcon icon={faLessThanEqual} />
					</SingleButtonWrapper>
				</Flex>
				<Button
					icon={<FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />}
					onClick={() => setExpanded(!isExpanded)}
				/>
			</Flex>

			{isExpanded && <Flex gap='small' style={{ height: 30 }} wrap='wrap'>
				<SingleButtonWrapper operation=' AND' type={type}>
					<span style={{ padding: '0 5px' }}>
						AND
					</span>
				</SingleButtonWrapper>
				<SingleButtonWrapper operation=' OR' type={type}>
					<span style={{ padding: '0 5px' }}>
						OR
					</span>
				</SingleButtonWrapper>
				<SingleButtonWrapper operation=' NOT' type={type}>
					<span style={{ padding: '0 5px' }}>
						NOT
					</span>
				</SingleButtonWrapper>
				<SingleButtonWrapper operation=' XOR' type={type}>
					<span style={{ padding: '0 5px' }}>
						XOR
					</span>
				</SingleButtonWrapper>
			</Flex>}



		</Flex>
	)
}

export default ConstructorSection