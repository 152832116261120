import React from 'react'
import { Flex, Button } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../../redux/Store';
import { useParams } from 'react-router-dom';
import SelectableStructure from './selectableStructure';



// interface MyFormValues {
// 	fields: { name: string }[];
// }
type MyFormValues = {
	addField: () => void
	removeField: (id: number) => void
};



const RulesDataStructureControl: React.FC<MyFormValues> = ({ addField, removeField }) => {

	const selectedFieldId = useAppSelector(state => state.RuleReducer.selectedField.id)
	const { fromModel } = useParams()



	return (

		<Flex justify='space-between' style={{ width: '100%' }}>

			<Flex gap='small'>

				<Button type="primary" icon={<PlusOutlined style={{ color: '#FFF' }} />} onClick={addField} />

				<Button type="primary" icon={<MinusOutlined style={{ color: '#FFF' }} />}
					onClick={() => removeField(selectedFieldId)}
				/>

				{!fromModel && <SelectableStructure generalType={'rule'} />}

			</Flex>

		</Flex>

	)
}

export default RulesDataStructureControl