import { AxiosRequestConfig } from "axios";
import { api } from "../../instance";

interface RequestGeneralParams {
	params?: { id?: string, expirationTime?: number };
	config?: AxiosRequestConfig;
	sdvModelId?: string
	targetId?: string
}

export const deleteById = ({ params, config }: RequestGeneralParams) => {
	return api.delete(`core-service/api/general`, { params, ...config })
		.then((res) => {
			const result = {
				data: res.data
			};
			return result;
		})
		.catch((err) => {
			const error = {
				message: err.response.data.message
			};
			return Promise.reject(error);
		});
}

export const getFieldRelation = async ({ config, sdvModelId }: RequestGeneralParams) => {
	const res = await api.get<IGetFieldRelation[]>(`core-service/api/v1/field-relations/${sdvModelId}`, { ...config })
	return res.data
}

export const postFieldRelation = ({ config }: RequestGeneralParams) => {
	return api.post<IGetFieldRelation[]>(`core-service/api/v1/field-relations`, { ...config })
		.then((res) => {
			const result = {
				data: res.data
			};
			return result;
		})
		.catch((err) => {
			const error = {
				message: err.response.data.message
			};
			return Promise.reject(error);
		});
}

export const getAccessField = async ({ config, sdvModelId, targetId }: RequestGeneralParams) => {
	const res = await api.get<IGetAccessFields[]>(`core-service/api/general/access-field/${sdvModelId}/${targetId}`, { ...config })
	return res.data
}

export const postGenerateToken = ({ config, params }: RequestGeneralParams) => {
	return api.post<string>('auth-service/api/auth/generate-token', {}, { params, ...config })
		.then((res) => {
			const result = {
				data: res.data
			};
			return result;
		})
		.catch((err) => {
			const error = {
				message: err.response.data.message
			};
			return Promise.reject(error);
		});

}