import { useEffect, useState } from 'react'
import { Space, Typography, message, Flex } from 'antd'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/Store';
import { Formik } from 'formik';
import { FieldVariable, RuleRequest, RuleType } from '../../../../shared/entities/Rule/Rule';
import AntFormRule from './mainInfo/AntForm';
import RulesDataStructureTable from './dataStructureTable';
// import { GridRuleOperation } from './GridRuleOperation';
import ConditionFields from './conditionFields/conditionFields';
import { ActionTypes } from '../../../../redux/Model/RuleReducer';
import ReturnButton from '../../../../components/ReturnButton/ReturnButton';
import CurrentResultOfTestData from './testData/CurrentResultOfTestData';
import RulesQualityGrid from './QualityTable';
import { useFetchAccessFields, useFetchFieldRelation, useFetchRule, useUpdateRule } from '../../../../shared/queries';
interface JsonDataType {
	number?: number
	condition: string
	resultCondition?: string
}

function RuleDefaultForm({ edit }: { edit: boolean }) {
	const dispatch = useDispatch();
	const { id, fromModel } = useParams();
	const { Title } = Typography;
	const { rule, selectedJsonData, defaultFieldList } = useAppSelector(store => store.RuleReducer)
	const [jsonDataValue, setJsonDataValue] = useState<JsonDataType>()
	const [messageApi, contextHolder] = message.useMessage();

	const { data } = useFetchRule({ id, fromModel })
	const updateChanges = useUpdateRule({ toastMessage: 'Rule successfully updated' })
	const getAccessField = useFetchAccessFields({ fromModel, id, isRule: true })
	const getFieldRelation = useFetchFieldRelation({ fromModel, isRule: true })

	useEffect(() => {
		if (data) {
			dispatch({
				type: ActionTypes.RULE_GET,
				payload: data,
			});
		}
	}, [data, dispatch]);

	const setJSONData = (data: any) => {

		setJsonDataValue(data);

		if (rule?.summarySubType === 'QUANTITY') {
			console.log('@QUANTITY', selectedJsonData)
			dispatch({
				type: ActionTypes.RULE_EDIT_JSON_DATA,
				payload: {
					// ...selectedJsonData,
					isQuality: false,
					newCondition: data?.condition,
					newResultCondition: data?.resultCondition,
				}
			});
		} else {

			dispatch({
				type: ActionTypes.RULE_EDIT_JSON_DATA,
				payload: {
					...selectedJsonData,
					// textValue: selectedJsonData?.textValue,
					// toIncidents: selectedJsonData?.toIncidents,
					isQuality: true,
					number: selectedJsonData && selectedJsonData.number,
					newCondition: data?.condition
				}
			});
		}
	}

	useEffect(() => {

		if (rule && rule.jsonData) {

			dispatch({
				type: ActionTypes.RULE_SELECT_JSON_DATA,
				payload: rule.jsonData ? rule.jsonData[0] : {
					condition: '',
					resultCondition: ''
				}
			})
		}

	}, [rule && rule.summarySubType === 'QUANTITY' ? rule?.jsonData : null])


	return (

		<Formik
			initialValues={data || {
				name: "",
				description: "",
				saveResult: false,
				ruleType: RuleType.QUANTITY,
				jsonData: [],
				fields: [],
			}}

			onSubmit={(values: any, actions) => {
				values.isCreate = !edit
				values.isTemplate = false
				if (!values.isCreate) {
					values.versionId = id
				}
				// values.extend = (rule?.extend && !rule.extend.isActive) ? rule?.extend : null;

				if (values.fields === null) {
					messageApi.open({
						type: 'error',
						content: "Not variable rule",
					});

				} else {
					const ruleRequest: RuleRequest = {
						modelId: rule && fromModel ? fromModel : null,
						isCreate: values.isCreate,
						type: "RULE",
						summarySubType: rule ? rule.summarySubType : '',
						isTemplate: false,
						name: values.name,
						description: values.description,
						saveResult: true,
						versionId: values.versionId || null,
						ruleType: rule?.ruleType as string,
						queueNumber: 1,
						resultIncremental: false,
						common: rule ? rule?.common : false,
						extend: (rule?.extend && !rule.extend.isActive) ? rule?.extend : null,
						jsonData: rule?.jsonData && rule.jsonData.length > 0 ?
							rule?.jsonData.map(item => {
								if (item.hasOwnProperty('sendingId')) {
									const { sendingId, ...rest } = item as any
									return { ...rest, id: -1 };
								}
								return item;
							})
							: [],
						fields: rule?.fields.map(field => ({
							name: field.name,
							testValueJson: field.testValueJson,
							id: defaultFieldList.includes(field.id) ? field.id : -1,
							ruleVariable: field.ruleVariable
						})) as FieldVariable[]
					};
					updateChanges.mutate(ruleRequest)
				}
				actions.setSubmitting(false)
			}}
		>
			<Space direction="vertical" size="middle" style={{ padding: 20, width: "100%" }}>
				{updateChanges.contextHolder}
				{contextHolder}
				<Title level={2}>{edit ? 'МОДИФИКАЦИЯ ПРАВИЛА' : 'СОЗДАНИЕ ПРАВИЛА'}</Title>

				<ReturnButton tab='Rules' fromModel={fromModel && fromModel} />

				<AntFormRule data={rule || {}} edit={edit} />

				<Flex style={{ width: '100%' }} gap='middle'>
					<span style={{ width: '25%' }}>Входящая структура данных</span>
					<RulesDataStructureTable data={rule?.fields || []} />
				</Flex>



				{rule?.summarySubType === RuleType.QUALITY &&
					<Flex style={{ width: '100%' }} gap='middle'>
						<span style={{ width: '25%' }}>Уровни или индексы</span>
						{/* <GridRuleOperation /> */}
						<RulesQualityGrid rule={rule} />
					</Flex>
				}


				<CurrentResultOfTestData />

				<ConditionFields
					data={selectedJsonData ? selectedJsonData : []}
					isQuality={rule?.summarySubType === RuleType.QUALITY}
					setJSONData={setJSONData}
				/>

			</Space>
		</Formik>

	)
}

export default RuleDefaultForm