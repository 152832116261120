import { Button, Flex, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import { URLPaths } from '../../config/application/URLPaths'
import { incidentColumns } from './IncidentsCols'

function Incidents() {


	const navigate = useNavigate()
	const data = [
		{
			id: "12",
			requestTime: "15.07.2024 12:30:00",
			author: "ШЕ",
			address: "ЦО г. Алматы, OpenWay, Входящие платежи - Эквайринг",
			model: "1956. Выявление аномальных операций",
			title: "Большая сумма перевода",
			cause: "sum > $ 1 000 000",
			detector: "ФИ",
			detecxtionTime: "15.07.2024 12:30:00",
			priority: "Низкий",
			reactionTime: "6 часов",
			Responsible: "АФ",
			state: "Закрыто"
		},
		{
			id: "5522",
			requestTime: "16.07.2024 12:30:00",
			author: "ШЕ",
			address: "ЦО г. Алматы, OpenWay, Входящие платежи - Эквайринг",
			model: "101. Выявление обналичивания",
			title: "Разные страны обналичивания за посл 1 час",
			cause: "lastatmcounttry > 2",
			detector: "ДБиО",
			detecxtionTime: "16.07.2024 12:30:00",
			priority: "Высокий",
			reactionTime: "10 минут",
			Responsible: "АФ",
			state: "Решение"
		},
		{
			id: "5523",
			requestTime: "17.07.2024 12:30:00",
			author: "ШЕ",
			address: "ЦО г. Алматы, OpenWay, Входящие платежи - Эквайринг",
			model: "нет",
			title: "Многократный ввод пароля",
			cause: "SS",
			detector: "SS",
			detecxtionTime: "17.07.2024 12:30:00",
			priority: "Низкий",
			reactionTime: "6 часов",
			Responsible: "ИС",
			state: "Регистрация"
		},
		{
			id: "445",
			requestTime: "18.07.2024 12:30:00",
			author: "ШЕ",
			address: "ЦО г. Алматы, OpenWay, Входящие платежи - Эквайринг",
			model: "570. Сверка SWIFT для выявления изменений в",
			title: "Изменение данных в batch",
			cause: "разные сверочные суммы",
			detector: "ФИ",
			detecxtionTime: "18.07.2024 12:30:00",
			priority: "Средний",
			reactionTime: "2 часа",
			Responsible: "СИТБ",
			state: "Решение"
		}
	]



	return (
		<div style={{ padding: 20, width: '100%' }}>
			<Flex justify='flex-end' style={{ marginBottom: 20 }}>
				<Button onClick={() => navigate(URLPaths.MANUAL_INCIDENTS.link)}>Создание заявки</Button>
			</Flex>
			<Table
				style={{ width: '100%' }}
				columns={incidentColumns}
				dataSource={data}
			/>
		</div>
	)
}

export default Incidents