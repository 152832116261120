import { Button, Flex, Modal, Table, TableColumnsType, TableProps } from 'antd';
import React, { useState } from 'react'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../../../redux/Store';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../../../../redux/Model/ModelReducer';
import { useFetchConnectors, useFetchRules } from '../../../../../../shared/queries';

function AddComponentsModal({ currentType }: { currentType: number }) {

	const dispatch = useDispatch()
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedIDs, setSelectedIds] = useState<number[]>([])
	const [pickedField, setPickedField] = useState<IRuleList[]>()
	const { selectedModelField, model } = useAppSelector(store => store.ModelReducer)
	const userValue = useAppSelector(store => store.UserReducer?.user?.id)
	const [tableParams, setTableParams] = useState<IGeneralTableParams>({
		page: 0,
		size: 10,
		userId: Number(userValue)
	});
	const fetchRules = useFetchRules({ params: tableParams })
	const fetchInputConnectors = useFetchConnectors({ params: { ...tableParams, isInput: true } })
	const fetchOutputConnectors = useFetchConnectors({ params: { ...tableParams, isInput: false } })

	const selectComponent = () => {
		if (currentType === 1) {
			return fetchRules;
		} else if (currentType === 0) {
			return fetchInputConnectors;
		}
		else return fetchOutputConnectors
	}

	const handleTableChange: TableProps['onChange'] = (pagination) => {
		const newPage = pagination.current ? pagination.current - 1 : 0;
		const newPageSize = Number(pagination.pageSize);

		if (newPage >= 0 && (newPage !== tableParams.page || newPageSize !== tableParams.size)) {
			setTableParams({
				...tableParams,
				page: newPage,
				size: newPageSize,
			})
		}
	}

	const showModal = () => {
		setTableParams({
			page: 0,
			size: 10,
			userId: Number(userValue)
		})
		setIsModalOpen(true)
		setSelectedIds(model ? model.modelStructComponents?.map((item: any) => item.modelComponentId) : [])
		selectComponent()
	};

	const handleOk = () => {

		dispatch({
			type: ActionTypes.MODEL_ADD_FIELDS,
			payload: {
				values: pickedField && pickedField.map((item: IRuleList | IConnectorList, index) => {

					const isQuality = (item as IRuleList).summarySubType === 'QUALITY';
					let queueNumber;

					if (isQuality) {
						const qualityFields = pickedField.filter(component => component.summarySubType === 'QUALITY');
						if (qualityFields.length === 1) {
							queueNumber = pickedField.length;
						} else {
							queueNumber = index;
						}
					} else {
						queueNumber = index;
					}

					return {
						name: item.name,
						modelComponentId: item.versionId,
						type: item.type,
						subtype: item.summarySubType,
						resultIncremental: false,
						launchSecondStage: false,
						queueNumber,
						summaryState: 'DRAFT'
					};
				}),
			}
		})

		setIsModalOpen(false);
	};

	const columns: TableColumnsType<IRuleList> = [
		{
			title: 'id',
			dataIndex: 'versionId',
			width: '15%'
		},
		{
			title: 'name',
			dataIndex: 'name',
			width: '60%'
		},
		{
			title: 'type',
			dataIndex: 'type',
			width: '25%'
		},
	];

	return (
		<Flex gap='small'>

			<Button
				disabled={currentType === null && currentType === undefined}
				icon={<PlusOutlined />}
				onClick={showModal}
			/>

			{selectedModelField?.summaryState === "DRAFT" ?
				<Button
					icon={<MinusOutlined />}
					onClick={() => dispatch({ type: ActionTypes.MODEL_REMOVE_FIELDS, payload: { id: selectedModelField?.modelComponentId } })}
				/> :
				null}

			<Modal
				title="Добавить"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={() => setIsModalOpen(false)}
			>

				{selectComponent() && <Table
					onChange={handleTableChange}
					columns={columns}
					style={{ height: 350 }}
					scroll={{ y: 240 }}
					dataSource={selectComponent().data?.list}
					loading={selectComponent().isLoading}
					size='small'
					rowKey={(dataSource: IRuleList) => dataSource.versionId}
					rowSelection={{
						type: 'checkbox',
						getCheckboxProps: (record: IRuleList) => ({
							disabled: selectedIDs.includes(record.versionId),
						}),
						onChange: (selectedRowKeys: React.Key[], selectedRows: IRuleList[]) => {
							setPickedField(selectedRows);
						},
					}}
					pagination={{
						position: ['topRight'],
						current: tableParams.page + 1,
						pageSize: tableParams.size,
						total: selectComponent().data?.totalCount,
					}}
				/>}

			</Modal>

		</Flex>
	)
}

export default AddComponentsModal


