import { Checkbox, CheckboxProps, Collapse, CollapseProps, Flex, Form, FormProps, Input, Space, TreeSelect, Typography } from 'antd'
import React, { useState } from 'react'
import SelectFormItem from './select-formItem/SelectFormItem';



type FieldType = {
	username?: string;
	password?: string;
	remember?: string;
};

const ManualIncidentCreate = () => {

	const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
		console.log('Success:', values);
	};


	const [isAuthor, setIsAuthor] = useState(true)
	const onIsAuthorChange: CheckboxProps['onChange'] = (e) => {
		setIsAuthor(e.target.checked)
	};


	return (

		<Space direction="vertical" size="middle" style={{ display: 'flex', padding: 20 }}>

			<Typography.Title level={2}>Создание заявки</Typography.Title>

			<Form
				name="basic"
				labelAlign="left"
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
				onFinish={onFinish}
				autoComplete="off"
				style={{ minWidth: 800 }}
			>

				<Form.Item<FieldType>
					label="Автор заявки"
				>
					<SelectFormItem
						label='Дополнительная информация'
						inputValue='test'
						otherStyle={{ marginTop: 10 }}
						treeData={[
							{ title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] },
						]}
					/>

				</Form.Item>

				<Form.Item<FieldType>
					label="Детектор"
				>
					<Flex gap={'middle'}>
						<span>Автор</span>
						<Checkbox value={isAuthor} defaultChecked onChange={onIsAuthorChange} />
					</Flex>

					{isAuthor === false &&
						<SelectFormItem
							label='Дополнительная информация'
							inputValue='test'
							treeData={[
								{ title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] },
							]} />
					}
				</Form.Item>

				<Form.Item<FieldType>
					label="Физическое место инцидента"
				>
					<SelectFormItem
						label='Дополнительная информация'
						inputValue='test'
						otherStyle={{ marginTop: 10 }}
						treeData={[
							{ title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] },
						]}
					/>

				</Form.Item>

			</Form>
		</Space>
	)
}

export default ManualIncidentCreate