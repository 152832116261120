import { UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllConnectors, getConnectorById, postConnectorWithUpdates, postDublicateConnectorById } from "../../../api/requests";
import useCustomToast from "../../../components/CustomToast/useCustomToast";
import { v4 as uuidv4 } from 'uuid';

export function useFetchConnectors({ params }:
	{ params: IGeneralTableParams & { isInput?: boolean } }, options?: UseQueryOptions<IGetConnectorsList, Error>): UseQueryResult<IGetConnectorsList, Error> {
	return useQuery<IGetConnectorsList, Error>({
		queryKey: ['connectorList', params],
		queryFn: () => getAllConnectors({ params }),
		enabled: !!params && params.userId !== null && params.userId !== undefined,
		...options
	});
}

export function useUpdateConnector({ toastMessage }: { toastMessage: string }) {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (newCon: any) => postConnectorWithUpdates({ config: newCon }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: toastMessage, key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['connectorList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}

export function useDublicateTheConnector() {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (id: string) => postDublicateConnectorById({ params: { id } }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Connector dublicated successfully', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['connectorList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}

export function useFetchConnectorById({ id, connectorType }: { id: string, connectorType: 'input' | 'output' }, options?: UseQueryOptions<IGetConnectorById, Error>): UseQueryResult<IGetConnectorById, Error> {
	return useQuery<IGetConnectorById>({
		queryKey: ['getConnector'],
		refetchOnWindowFocus: false,
		queryFn: () => getConnectorById({ id: Number(id) }),
		enabled: id !== undefined && !!connectorType,
		...options
	})
}
