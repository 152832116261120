import { Modal, TreeSelect } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ActionTypes } from '../../../../../../redux/Model/ConnectorReducer'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../../../redux/Store'

type ItreeData = {
	value: string | number,
	title: string | number,
	children?: ItreeData[]
}

interface IConnectorLinkModal {
	closeModal: () => void
	modalStatus: boolean
	changeDefaultTitle: (newTitle?: string) => void
	dataSource?: ItreeData[]
}

const ConnectorLinkModal: React.FC<IConnectorLinkModal> = ({ closeModal, modalStatus, dataSource, changeDefaultTitle }) => {

	const dispatch = useDispatch()
	const { fromModel } = useParams()
	const fieldSelection = useAppSelector(state => state.ConnectorReducer.fieldsResponse)
	const currentFieldId = useAppSelector(state => state.ConnectorReducer.selectedField.id)

	const [value, setValue] = useState(0)
	// const [relationalFieldId, setRelationalFieldId] = useState(0)

	const tagsArray = fieldSelection?.flatMap(item =>
		Object.entries(item.tags).map(([id, name]) => ({ id: parseInt(id), name }))
	) ?? [];

	const onChange = (newValue: number) => {

		// setRelationalFieldId(newValue)
		setValue(newValue)
		changeDefaultTitle(tagsArray.find((item) => item.id === newValue)?.name ?? undefined)

		dispatch({
			type: ActionTypes.CONNECTOR_SET_FIELDS_RELATION,
			payload: {
				sdvmodelStructId: fromModel,
				relation: {
					srcName: tagsArray.find((item) => item.id === newValue)?.name ?? undefined,
					srcSummaryFieldId: newValue,
					varSummaryFieldId: currentFieldId
				}
			}
		})
	};

	return (
		<Modal
			title="Pick a connector"
			open={modalStatus}
			onOk={closeModal}
			onCancel={closeModal}
		>
			<TreeSelect
				showSearch
				treeNodeFilterProp='title'
				style={{ width: '100%' }}
				dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
				placeholder="Please select"
				allowClear
				treeDefaultExpandAll
				treeData={dataSource}
				value={value}
				onChange={(newValue: number) =>
					onChange(
						newValue,
					)}
			/>
		</Modal>
	)
}

export default ConnectorLinkModal