import { Button, ColorPicker, Flex, Input, Modal, Table } from 'antd'
import { useState } from 'react'

function IncidentPriority() {

	const [isModalOpen, setIsModalOpen] = useState(false);

	const closeModal = () => setIsModalOpen(false)

	const columns = [
		{
			title: 'Наименование приоритета',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Цвет',
			dataIndex: 'color',
			key: 'color',
		},
		{
			title: 'Циферное обозначение',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: 'Наименование серьезности',
			dataIndex: 'severityName',
			key: 'severityName',
		},
		{
			title: 'Последствия в ден. выражении ($) мин.',
			dataIndex: 'min',
			key: 'min',
		},
		{
			title: 'Последствия в ден. выражении ($) макс.',
			dataIndex: 'max',
			key: 'max',
		},
		{
			title: 'Частота инцидента в месяц, мин',
			dataIndex: 'minFrequency',
			key: 'minFrequency',
		},
		{
			title: 'Частота инцидента в месяц, макс',
			dataIndex: 'maxFrequency',
			key: 'maxFrequency',
		},
		{
			title: 'Время реакции макс в секундах',
			dataIndex: 'maxReactionTime',
			key: 'maxReactionTime',
		},
		{
			title: 'Время закрытия макс в секундах',
			dataIndex: 'maxClosingTime',
			key: 'maxClosingTime',
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
		},
	];

	return (
		<Flex vertical>
			<Flex align='flex-end' justify='end' gap='middle'>
				<Button>Добавить</Button>
				<Button onClick={() => setIsModalOpen(true)}>Открыть существующего</Button>
			</Flex>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
			/>

			<Modal
				title="Редактировать"
				width={800}
				open={isModalOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={
					<Flex justify='space-between'>
						<Button onClick={closeModal} danger>
							Удалить
						</Button>
						<Flex gap='middle' align='end' style={{ textAlign: 'right' }}>
							<Button onClick={closeModal} >
								Отмена
							</Button>
							<Button onClick={closeModal}>
								Сохранить
							</Button>
						</Flex>
					</Flex>
				}>

				<Flex vertical gap='middle'>
					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Наименование приоритета</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Цвет</span>
						<ColorPicker defaultValue="#1677ff" />
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Циферное обозначение</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Наименование серьезности</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Последствия в ден. выражении ($) мин.</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Последствия в ден. выражении ($) макс.</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Частота инцидента в месяц, мин</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Частота инцидента в месяц, макс</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Время закрытия макс в секундах</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex gap='middle' justify='space-between'>
						<span style={{ width: '30%' }}>Описание</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

				</Flex>
			</Modal>

		</Flex>
	)
}

export default IncidentPriority