import { Flex, Table, TableProps, Typography } from 'antd'
import { incidentColumns } from './tableCols'
import { ISingleIncidentJsonData } from '../../../../../shared/entities/Connector/Connector'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ActionTypes } from '../../../../../redux/Model/ModelReducer'
import { useGetIncidentsByModel } from '../../../../../shared/queries'

function IncidentsTable({ currentState }: { currentState: IModelState }) {

	const { id } = useParams()
	const dispatch = useDispatch()
	const [tableParams, setTableParams] = useState<IGeneralTableParams & { modelId: number }>({
		page: 0,
		size: 10,
		userId: 1,
		modelId: Number(id)
	});

	const { data, isPending } = useGetIncidentsByModel({ params: tableParams, currentState: currentState })

	const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
		const newPage = pagination.current ? pagination.current - 1 : 0;
		const newPageSize = Number(pagination.pageSize);

		if (newPage >= 0 && (newPage !== tableParams.page || newPageSize !== tableParams.size)) {
			setTableParams({
				...tableParams,
				page: newPage,
				size: newPageSize,
			});

			dispatch({ type: ActionTypes.MODEL_SET_INCIDENT_PARAMS, payload: { ...tableParams, page: newPage, size: newPageSize } })
		}
	};

	return (
		<Flex style={{ width: '100%', marginTop: 50 }} gap='small' justify='flex-start' vertical>
			<Typography.Title level={5} style={{ width: '25%', marginLeft: 20 }}>Инциденты:</Typography.Title>

			<Table
				// scroll={{ y: 500 }}
				style={{ width: '99%' }}
				columns={incidentColumns}
				dataSource={data && data.incidents}
				onChange={handleTableChange}
				pagination={{ current: tableParams.page + 1, pageSize: tableParams.size, total: data?.totalCount, position: ['bottomLeft'] }}
				rowKey={row => row.id}
				size='small'
				loading={isPending}
				expandable={{
					expandedRowRender: (record) => {
						try {
							const jsonData = JSON.parse(record.jsonData);
							return (
								<Flex vertical style={{ overflow: 'hidden' }}>
									{jsonData.map((singleRecord: ISingleIncidentJsonData, index: number) => (
										<Flex vertical style={{ overflow: 'hidden' }} key={index}>
											{singleRecord.condition !== '' && singleRecord.condition ?

												<span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
													condition: {singleRecord.condition}
												</span>

												: null
											}

											{singleRecord.operation !== '' && singleRecord.operation ?

												<span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
													operation: {singleRecord.operation}
												</span>

												: null
											}

											<span>Data: {

												singleRecord.data ?
													Object.entries(singleRecord.data).map(([key, value]) => `${key}:${value}; `) : null
											}</span>

										</Flex>
									))}
								</Flex>
							);
						} catch (error) {
							console.error('Error parsing JSON:', error);
							return <span>Error parsing JSON</span>; // or handle the error in a different way
						}
					}
				}}
			/>
		</Flex>
	)
}

export default IncidentsTable

