import { useState } from "react";
import { useAppSelector } from "../../../../redux/Store";
import { Button, Flex, Spin } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import ComponentListLayout from "../../common/all/ComponentListLayout/ComponentListLayout";
import AllConnectorList from "./ConnectorListTable/AllConnectorList";
import AddNewConnectorModal from "./addNewConModal/AddNewConnectorModal";
import { useDublicateTheConnector, useFetchConnectors } from "../../../../shared/queries";

const Connectors = () => {

	const userValue = useAppSelector(store => store.UserReducer?.user?.id)

	const [pickedId, setPickedId] = useState<string>()
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [tableParams, setTableParams] = useState<IGeneralTableParams>({
		page: 0,
		size: 10,
		userId: Number(sessionStorage.getItem('userId'))
	});

	const { data, isLoading, isPending } = useFetchConnectors({ params: tableParams });
	const dublicateConnectorMutation = useDublicateTheConnector()

	return (

		<ComponentListLayout current="connectors">

			<Flex vertical gap='middle' align="flex-end" style={{ position: 'relative' }}>
				{dublicateConnectorMutation.contextHolder}

				<Flex justify="space-between" align="center" style={{ position: 'absolute', height: 40, width: 90, padding: '0 4px', top: '-45px', right: 0, zIndex: 5, borderRadius: 8 }}>
					<Button
						style={{ height: 36, width: 36 }}
						onClick={() => setIsModalOpen(true)}
						icon={<PlusOutlined />}
					/>
					<Button
						style={{ height: 36, width: 36 }}
						onClick={() => pickedId !== undefined ? dublicateConnectorMutation.mutateAsync(pickedId) : () => console.log('error')}
						icon={<FontAwesomeIcon icon={faClone} />} />
				</Flex>

				{data && tableParams && <>
					<AllConnectorList
						changeTableParams={(newParams: IGeneralTableParams) => setTableParams(newParams)}
						requestParams={tableParams}
						loading={isPending}
						totalCount={Number(data.totalCount)}
						dataSource={data.list as IConnectorList[]}
						pickIdFromTable={(id: number) => {
							setPickedId(String(id))
						}}
					/>

					<AddNewConnectorModal
						totalCount={data.totalCount}
						modalStatus={isModalOpen}
						hideModal={() => setIsModalOpen(false)}
					/>
				</>}

				{(isLoading || isPending) &&
					<Flex
						style={{ width: '100%', minHeight: '200px' }}
						align='center'
						justify='center'
					>
						<Spin />
					</Flex>
				}

			</Flex>
		</ComponentListLayout>
	)


}


export default Connectors
