import { AxiosRequestConfig } from "axios";
import { api } from "../../../instance";


interface RequestSingleRuleParams {
	params?: { id: string };
	config?: AxiosRequestConfig;
	id?: number
	modelId?: number
	sdvModelId?: string,
	sdvRuleId?: string
	ruleType?: string
}

export const getRuleById = async ({ config, id }: RequestSingleRuleParams) => {
	const res = await api.get<IGetRuleById>(`core-service/api/rule/${id}`,
		{ ...config }
	)
	return res.data
}

export const getRuleFromModel = async ({ config, id, modelId }: RequestSingleRuleParams) => {
	const response = await api.get<IGetRuleById>(`core-service/api/rule/${id}/${modelId}`,
		{ ...config }
	)
	return response.data
};

export const postDublicateRuleById = ({ params, config }: RequestSingleRuleParams) =>
	api.post<IGetRuleList>('core-service/api/rule/duplicate',
		{ params, ...config }
	);

export const postRuleEvaluate = ({ config, ruleType }: RequestSingleRuleParams) => {
	return api.post<IResultOfRuleEvaluate | IResultOfRuleEvaluate[]>(`handler-service/api/v1/rule-evaluate/${ruleType}`, { ...config })
		.then((res) => {
			const result = {
				data: res.data
			};
			return result;
		})
		.catch((err) => {
			const error = {
				message: err.error
			};
			return Promise.reject(error);
		});
};




