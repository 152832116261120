import { Avatar, Dropdown, Flex, MenuProps, Typography } from 'antd';
import { authErrorHandler } from '../../../hooks/AuthHook';
import { useNavigate } from 'react-router-dom';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useGetUser } from '../../../shared/queries';
import { useEffect } from 'react';

function UserHeaderAvatar() {

	const navigate = useNavigate()
	const userName = sessionStorage.getItem('login');
	const { Text } = Typography;

	const { data } = useGetUser({ userName })

	useEffect(() => {
		if (data) { sessionStorage.setItem("userId", String(data.id)) }
	}, [data])

	const logout = () => {
		authErrorHandler.handleAuthChange(false)
		sessionStorage.removeItem('login')
		sessionStorage.removeItem('userId')
		sessionStorage.removeItem('authToken')
		navigate('/')
	}

	const items: MenuProps['items'] = [
		{
			label: 'Settings',
			key: '1',
			icon: <SettingOutlined />,
		},
		{
			label: 'Users list',
			key: '2',
			icon: <UserOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: 'Log out',
			key: '3',
			onClick: logout,
			icon: <FontAwesomeIcon icon={faRightFromBracket} />,
			danger: true,
		}
	];

	return (
		<Dropdown
			menu={{ items }}
			trigger={['click']}
		>
			<Flex justify='flex-start' style={{ width: 120 }}>
				<Flex vertical align='center' >
					<Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>
						{String(userName).charAt(0).toLocaleUpperCase()}
					</Avatar>
					<Text style={{ fontSize: '12px', cursor: 'pointer' }}>{String(userName)}</Text>
				</Flex>
			</Flex>
		</Dropdown>

	)
}

export default UserHeaderAvatar