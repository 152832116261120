import { Table, TableProps, Button, Flex, Popconfirm } from 'antd';
import React, { useState } from 'react'
import { modelListCols } from './modelListTitles';
import { RightOutlined, UpOutlined } from "@ant-design/icons";
import styles from './modelTable.module.scss'
import { useDeleteById } from '../../../../../shared/queries';

interface ITableParams {
	page: number,
	size: number,
	userId: number
}

interface IAllModelList {
	dataSource: IModelList[]
	requestParams: ITableParams
	pickIdFromTable?: (id: number) => void
	changeTableParams: (params: ITableParams) => void
	totalCount: number
	loading: boolean
}

const AllModelList: React.FC<IAllModelList> = ({ dataSource, requestParams, totalCount, changeTableParams, pickIdFromTable, loading }) => {


	const [selectedRowId, setSelectedRowId] = useState<number>()

	const handleTableChange: TableProps['onChange'] = (pagination) => {
		const newPage = pagination.current ? pagination.current - 1 : 0;
		const newPageSize = Number(pagination.pageSize);

		if (newPage >= 0 && (newPage !== requestParams.page || newPageSize !== requestParams.size)) {
			changeTableParams({
				...requestParams,
				page: newPage,
				size: newPageSize,
			})
		}
	}

	const deleteModel = useDeleteById({ type: 'model' })

	const confirmDelete = (id: string) => {
		deleteModel.mutate(id);
	};

	return (
		<Table
			dataSource={dataSource}
			columns={modelListCols}
			size='small'
			rowKey={row => row.summaryDataId}
			loading={loading}
			pagination={{
				position: ['topRight'],
				current: requestParams.page + 1,
				pageSize: requestParams.size,
				total: totalCount,
			}}
			onChange={handleTableChange}
			onRow={(record) => {
				return {
					onClick: () => {
						setSelectedRowId(record.versionId)
						if (pickIdFromTable)
							pickIdFromTable(record.versionId)
					},
				};
			}
			}

			rowClassName={(record) => (record.versionId === selectedRowId ? styles.picked : styles.default)}
			expandable={{

				expandIcon: ({ expanded, onExpand, record }) =>
					expanded ? (
						<UpOutlined onClick={e => onExpand(record, e)} />
					) : (
						<RightOutlined onClick={e => onExpand(record, e)} />
					),
				expandedRowRender: (record) =>
					<Flex justify='space-between'>
						{deleteModel.contextHolder}
						<Flex style={{ margin: 0, paddingLeft: 'calc(10% + 46px)' }} vertical>
							<span>id: {record.versionId}</span>
							<span>Название: {record.name}</span>
							<span>Создал: {record.userName}</span>

							<Flex vertical>
								<span>Процесс-источник: Управление стратегией</span>
								<span>Процесс-назначение: Управление стратегией</span>
							</Flex>

							<span>Используестя в моделях: {record.modelStructComponents.map((model, index) => {
								return <span key={index}>{model.name} {index === record.modelStructComponents.length - 1 ? '.' : ';'}</span>
							})} </span>

						</Flex>

						{record.summaryState === 'DRAFT' && <Popconfirm
							title={`Delete the rule`}
							description={`Are you sure to delete  ${record.name} ?`}
							onConfirm={() => confirmDelete(record.versionId.toString())}
							okText="Yes"
							cancelText="No"
						>
							<Button danger>Delete</Button>
						</Popconfirm>}

					</Flex>
				,
				rowExpandable: (record) => record.name !== 'Not Expandable',
			}}
		/>
	)
}

export default AllModelList