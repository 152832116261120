import { AxiosRequestConfig } from "axios";
import { api } from "../../instance";

export const postUser = async ({ data }: AxiosRequestConfig) => {
	try {
		const response = await api.post<IPostUserResponse>('/auth-service/api/auth/user', data)

		if (response.data && response.data.token) {
			localStorage.setItem('authToken', response.data.token)
		}

		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const postRegisterUser = async ({ data }: AxiosRequestConfig) => {
	try {
		const response = await api.post<IPostUserResponse>('/admin-service/api/users/create', data)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getPrivileges = async ({ params }: AxiosRequestConfig) => {
	const response = await api.get<IPostUserResponse>("admin-service/api/roles", { params: { id: params } })
	return response.data
}


export const getUser = async ({ params }: AxiosRequestConfig) => {
	try {
		const res = await api.get<IGetUserResponse>("admin-service/api/users/login", { params: { loginOrId: params } })
		return res.data
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const postValidateUser = () => {
	const res = api.post<IValidateUser>("auth-service/api/auth/validate", {})
	return res
}

export const postRefreshUserSession = () => {
	try {
		const res = api.post('auth-service/api/auth/refresh', {})
		return res
	}
	catch (e) {
		throw new Error(`error: ${e} `)
	}
}