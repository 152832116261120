import { FloatButton, Space, Typography, message } from 'antd'
import { useEffect, useMemo } from 'react'
import DataStructureInfo from './formData/DataStructureInfo'
import DataStructureTable from './table/DataStructureTable'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../redux/Store'
import { SaveOutlined } from '@ant-design/icons';
import ReturnButton from '../../../../components/ReturnButton/ReturnButton'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getDataStructureById, postDataStructureWithUpdates } from '../../../../api/requests'
import { ActionTypes } from '../../../../redux/Model/DataStructureReducer'

function DataStructureForm({ edit }: { edit: boolean }) {

	const queryClient = useQueryClient()
	const { id, fromModel } = useParams()
	const dispatch = useDispatch()
	const [messageApi, contextHolder] = message.useMessage();
	const { dataStructure, defaultFields } = useAppSelector(store => store.DataStructureReducer)

	const { data } = useQuery({
		queryKey: ['getDataStructure'],
		queryFn: useMemo(() => () => getDataStructureById({ id: Number(id) }), [id]),
		enabled: id !== undefined && id !== null
	})

	const updateChanges = useMutation({
		mutationFn: (newModel: any) => postDataStructureWithUpdates({ config: newModel }),
		onSuccess: () => {
			messageApi.success('Data structure updated successfully');
			queryClient.refetchQueries({ queryKey: ['getDataStructure'] })
		},
		onError: () => {
			messageApi.error('Failed to update the data structure');
		}
	})

	useEffect(() => {
		if (data) {
			dispatch({
				type: ActionTypes.DATA_STRUCTURE_GET,
				payload: data.data,
			});
		}
	}, [data, dispatch]);

	return (
		<Space direction="vertical" size="middle" style={{ display: 'flex', width: '100%', padding: 20 }}>

			<Typography.Title level={2}>
				{!edit ? 'Creating a data Structure' :
					'Update a data Structure'}
			</Typography.Title>

			<Formik initialValues={{}}
				onSubmit={(values: any, actions) => {
					values.isCreate = !edit
					values.isTemplate = false
					values.fields = dataStructure?.fields.map((field) => ({
						...field,
						id: defaultFields && defaultFields.includes(field.id) ? field.id : -1
					}))

					values.name = dataStructure?.name
					values.description = dataStructure?.description
					if (!values.isCreate) {
						values.versionId = id
					}

					if (values.fields.length === 0) {
						messageApi.open({
							type: 'error',
							content: "Add at least one field to data Structure",
						});
					} else {
						updateChanges.mutate(values)
					}
				}}
				validate={values => {
					console.log("values:", values)
				}}
			>
				{({ handleSubmit, submitForm }) => (
					<Form onSubmit={handleSubmit} autoComplete="off" >
						{contextHolder}
						<Space direction='vertical' style={{ width: "100%" }}>

							<DataStructureInfo />
							<DataStructureTable />

							<FloatButton onClick={submitForm} icon={<SaveOutlined />} />
							<ReturnButton tab='DataStructures' fromModel={fromModel && fromModel} />

						</Space>
					</Form>
				)}
			</Formik>

		</Space>
	)
}

export default DataStructureForm