import { AxiosRequestConfig } from "axios";
import { api } from "../../../instance";


interface RequestDataStructureParams {
	params?: { id: string };
	config?: AxiosRequestConfig;
	id?: number
}

export const getDataStructureById = ({ config, id }: RequestDataStructureParams) =>
	api.get<IGetDataStructure>(`core-service/api/data-structure/${id}`,
		{ ...config }
	);

export const postDublicateDataStructureById = async ({ params, config }: RequestDataStructureParams) => {


	try {
		const res = await api.post<IGetConnectorsList>('core-service/api/data-structure/duplicate',
			{},
			{ params, ...config }
		)
		return res.data;
	} catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}

		return Promise.reject({ message: errorMessage });
	}
}