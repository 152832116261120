import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
// import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { store } from './redux/Store';
import { defaultPrivilegeMap, PrivilegesProvider } from './hooks/PrivilegesProvider';
import { AuthProvider } from "./hooks/AuthHook"
import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'


const rootElement = document.getElementById("root");

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		},
	},
})

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		// <React.StrictMode>

		<BrowserRouter>
			<AuthProvider isAuth={false}>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<PrivilegesProvider priv={defaultPrivilegeMap}>
							<App />
						</PrivilegesProvider>
					</Provider>
				</QueryClientProvider>
			</AuthProvider>
		</BrowserRouter>

		// </React.StrictMode>
	);
}
