import { AxiosRequestConfig } from "axios";
import { api } from "../../../instance";

interface RequestHandbookInvolvedPartiesParams {
	params?: { id: string };
	config?: AxiosRequestConfig;
	id?: number
}

export const postHandbookInvolvedParties = async ({ config }: RequestHandbookInvolvedPartiesParams) => {
	try {
		const response = await api.post<InvolvedPartiesType>('/incident/involvedPartiesType', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const putHandbookInvolvedParties = async ({ config }: RequestHandbookInvolvedPartiesParams) => {
	try {
		const response = await api.post<InvolvedPartiesType>('/incident/involvedPartiesType', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookInvolvedPartiesById = async ({ id }: RequestHandbookInvolvedPartiesParams) => {
	try {
		const response = await api.get<InvolvedPartiesType>(`/incident/involvedPartiesType/${id}`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const deleteHandbookInvolvedParties = async ({ id }: RequestHandbookInvolvedPartiesParams) => {
	try {
		const response = await api.post<InvolvedPartiesType>(`/incident/involvedPartiesType/${id}`)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}




