import { Typography, notification } from 'antd';
import React from 'react'
import { useClipboard } from 'use-clipboard-copy';

function CopingText({ text, description }: { text: string, description: string }) {

	const clipboard = useClipboard({
		copiedTimeout: 1000
	});
	const [api, contextHolder] = notification.useNotification();


	const CopyLink = (text: string) => {

		clipboard.copy(text);

		api.info({
			message: `Успешно скопировано`,
			description: `${description}: ${text}`,
			placement: 'bottomRight',
		});
	}

	return (
		<>
			{contextHolder}
			<Typography.Text
				type={clipboard.copied ? 'success' : "secondary"}
				onClick={() => CopyLink(text)}
				style={{ cursor: 'pointer' }}
			>
				{clipboard.copied ? 'Copied' : text}
			</Typography.Text>
		</>

	)
}

export default CopingText