import { UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllModels, getIncidentsByModel, getModelById, postChangeModelState, postDublicateModelById, postModelsWithUpdates } from "../../../api/requests";
import useCustomToast from "../../../components/CustomToast/useCustomToast";
import { v4 as uuidv4 } from 'uuid';

export function useFetchModels({ params }: { params: IGeneralTableParams }, options?: UseQueryOptions<IGetModelsList, Error>): UseQueryResult<IGetModelsList, Error> {
	return useQuery<IGetModelsList, Error>({
		queryKey: ['modelsList', params],
		queryFn: () => getAllModels({ params }),
		refetchInterval: 100000,
		refetchOnWindowFocus: false,
		enabled: !!params && params.userId !== null && params.userId !== undefined,
		...options
	});
}

export function useUpdateModel({ toastMessage }: { toastMessage: string }) {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (newModel: any) => postModelsWithUpdates({ config: newModel }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: toastMessage, key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['modelsList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}

export function useDublicateTheModel() {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (id: string) => postDublicateModelById({ params: { id } }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Model dublicated successfully', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['modelsList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}

export function useFetchModelById({ id, state, options }: { id?: string, state?: string, options?: UseQueryOptions<IGetModelById, Error> }): UseQueryResult<IGetModelById, Error> {
	return useQuery<IGetModelById, Error>({
		queryKey: ['getModel'],
		queryFn: () => getModelById({ id: Number(id) }),
		enabled: id !== undefined && id !== null,
		refetchInterval: state === 'RUN' ? 10000 : false,
		// onSettled:()=>console.log('test'),
		...options
	})
}

export function useChangeModelState({ id }: { id?: string }) {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (newState: IModelState) => postChangeModelState({ id: Number(id), params: { state: newState } }),
			onSuccess: (_, newState) => {

				const Text = newState === 'RUN' ? 'running' :
					newState === 'PAUSE' ? 'paused' : 'stopped'

				toast.handleToast({
					type: 'success',
					text: `Model successfully ${Text}`, key: uuidv4()
				});
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['getModel'] })
				}
			},
		}),
		contextHolder: toast.contextHolder
	}
}


export function useGetIncidentsByModel({ params, currentState }: { currentState: IModelState, params: IGeneralTableParams & { modelId: number } }, options?: UseQueryOptions<IGetIncidents, Error>): UseQueryResult<IGetIncidents, Error> {
	return useQuery<IGetIncidents, Error>({
		queryKey: ['getIncidentsOfModel', params],
		queryFn: () => getIncidentsByModel({ params }),
		refetchInterval: currentState === 'RUN' ? 10000 : false,
		refetchOnWindowFocus: false,
		enabled: !!params && params.modelId !== null && params.modelId !== undefined,
		...options
	});
}

