import { AxiosRequestConfig } from "axios";
import { api } from "../../instance";

interface RequestRulesParams {
	params?: { page: number, size: number, userId: number };
	config?: AxiosRequestConfig;
}

export const getAllRules = async ({ params, config }: RequestRulesParams) => {
	const response = await api.post<IGetRuleList>('core-service/api/rule/all',
		{},
		{ params, ...config }
	)
	return response.data
}

export const postRulesWithUpdates = ({ config }: RequestRulesParams) => {
	return api.post<IGetRuleList>('core-service/api/rule/create-or-update', { ...config })
		.then((res) => {
			const result = {
				data: res.data
			};
			return result;
		})
		.catch((err) => {
			const error = {
				message: err.response.data.message
			};
			return Promise.reject(error);
		});
}

