import { Flex, Affix, Typography, FloatButton, message, notification } from 'antd'
import { useEffect, useState } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../redux/Store';
import { useFormikContext } from "formik";
import { useParams } from 'react-router-dom';
import ConstructorSection from './constructorSection/ConstructorSection';
import ConditionTextarea from './Textarea/ConditionTextarea';
import { ActionTypes } from '../../../../../redux/Model/RuleReducer';
import { useUpdateFieldRelation } from '../../../../../shared/queries';

function ConditionFields({ data, isQuality, setJSONData }: { data: any, isQuality: boolean, setJSONData: (data: any) => void }) {

	const [isConstructorMode, setConstructorMode] = useState<'condition' | 'resultCondition' | null>(null);
	const [editingCondition, setEditingCondition] = useState(false)
	const [condition, setCondition] = useState<string>('');
	const [resultCondition, setResultCondition] = useState<string>('');

	const { isValueSelecting, valueToCondition } = useAppSelector(store => store.RuleReducer)
	const { selectedJsonData, rule } = useAppSelector(store => store.RuleReducer)

	const bottom = 0
	const dispatch = useDispatch()
	const { fromModel } = useParams()
	const formik = useFormikContext()
	const fieldsRelation = useAppSelector(state => state.RuleReducer.fieldRelation)
	const [api, contentHolder] = notification.useNotification();


	const editResult = (isCondtion: boolean, newResult: string) => {
		isCondtion === true ?
			setCondition(newResult) :
			setResultCondition(newResult)
	}

	const selectConstructorMode = (mode: 'condition' | 'resultCondition') => {
		setConstructorMode(mode)
	}

	const toggleEditingCondition = (val: boolean) => {
		setEditingCondition(val)
	}

	const checkForString = (text: string, operators: string[], logicalOperators: string[]): string => {
		const regex = new RegExp(`(${operators.join('|')})\\s*([^${logicalOperators.join('')}\\s]+)(?=\\s*(?:${logicalOperators.join('|')}|$))`, 'g');
		const processedStrings = new Set<string>();

		return text.replace(regex, (match, operator, foundString) => {
			if (!processedStrings.has(foundString)) {
				processedStrings.add(foundString);
				if (!/^\d+$/.test(foundString) && !/^'.*'$/.test(foundString)) {
					return `${operator} '${foundString}'`;
				} else if (/^'.*'$/.test(foundString)) {
					return match;
				}
			}
			return match;
		});
	};

	const editJsonData = (isCondition: boolean, newValue: string) => {
		if (isCondition) {
			const newCondition = checkForString(newValue, ["==", "!="], ["AND", "OR", "XOR", "NOT"]);
			const payload = rule?.summarySubType === 'QUANTITY'
				? { isQuality: false, newCondition, newResultCondition: resultCondition }
				: { ...selectedJsonData, isQuality: true, number: selectedJsonData?.number, newCondition };
			dispatch({ type: ActionTypes.RULE_EDIT_JSON_DATA, payload });
		} else {
			const newResultCondition = checkForString(newValue, ["==", "!="], ["AND", "OR", "XOR", "NOT"]);
			const payload = { isQuality: false, newCondition: condition, newResultCondition };
			dispatch({ type: ActionTypes.RULE_EDIT_JSON_DATA, payload });
		}
	};

	const updateChanges = useUpdateFieldRelation({ fromModel, toastMessage: 'Fields have been successfully related' })

	const SaveChanges = () => {

		const idsWithNonNullTestValueJson = rule?.fields
			.filter(item => item.ruleVariable === null)
			.filter(item => item.defaultField === false)
			// Фильтруем поля с null-значением testValueJson
			.map(item => item.id); // Извлекаем id из отфильтрованных полей

		const idWithoutFieldRelation = idsWithNonNullTestValueJson && idsWithNonNullTestValueJson.filter(id =>
			!fieldsRelation?.fieldRelationRequestSubDataDTOList.some(item => item.varSummaryFieldId === id)
		);

		if (fromModel && idWithoutFieldRelation && idWithoutFieldRelation.length > 0) {
			api.open({
				message: <span style={{ color: 'red' }}>Распределите поля</span>,
				description: `Поля ${idWithoutFieldRelation.join(', ')} не были распределены`,
				duration: 3,
			});
		}

		if (fromModel && fieldsRelation && idWithoutFieldRelation?.length === 0) {
			const filteredRelation = fieldsRelation.fieldRelationRequestSubDataDTOList.map(({ srcSummaryFieldId, varSummaryFieldId, varName, srcName }) => ({ srcSummaryFieldId, varSummaryFieldId, varName, srcName }))
			updateChanges.mutate({ sdvmodelStructId: Number(fieldsRelation.sdvmodelStructId), fieldRelationRequestSubDataDTOList: filteredRelation })
		}

		if (isQuality === true) {
			setJSONData({ ...data, condition })
			formik.handleSubmit()
		}
		else {
			setJSONData({ ...data, condition, resultCondition })
			formik.handleSubmit()
		}
	}

	useEffect(() => {
		if (isQuality === true && data) {
			setCondition(data.condition)
		}
		else if (isQuality === false && data) {
			setCondition(data.condition)
			setResultCondition(data.resultCondition)
		}
	}, [isQuality, data]);

	useEffect(() => {
		if (isValueSelecting === true && editingCondition === true) {
			setCondition(condition + valueToCondition)
		}
		else if (isValueSelecting === true && editingCondition === false) {
			setResultCondition(resultCondition + valueToCondition)
		}
	}, [isValueSelecting])

	return (
		<Affix offsetBottom={bottom}>

			<Flex gap='middle' style={{ backgroundColor: '#fff', zIndex: 5, height: 200 }}>
				{updateChanges.contextHolder}
				{contentHolder}
				<Flex vertical gap='small' style={{ width: '100%' }}>
					<Typography.Text>ЕСЛИ:</Typography.Text>
					{isConstructorMode === 'condition' && <ConstructorSection type='condition' />}
					<ConditionTextarea
						isQuality={isQuality}
						data={data}
						result={condition}
						editResult={editResult}
						isCondition={true}
						selectConstructorMode={selectConstructorMode}
						toggleEditingCondition={toggleEditingCondition}
						editJsonData={editJsonData}
					/>
				</Flex>

				{isQuality === false &&
					<Flex vertical gap='small' style={{ width: '100%' }}>
						<Typography.Text>РАВНО:</Typography.Text>
						{isConstructorMode === 'resultCondition' && <ConstructorSection type='resultCondition' />}
						<ConditionTextarea
							isQuality={isQuality}
							data={data}
							result={resultCondition}
							editResult={editResult}
							isCondition={false}
							selectConstructorMode={selectConstructorMode}
							toggleEditingCondition={toggleEditingCondition}
							editJsonData={editJsonData}
						/>
					</Flex>
				}

				<FloatButton onClick={SaveChanges} icon={<SaveOutlined />} />

			</Flex>

		</Affix>
	)
}

export default ConditionFields