import { useState } from "react";
import { useAppSelector } from "../../../../redux/Store";
import { Flex, Button, Spin } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import ComponentListLayout from "./ComponentListLayout/ComponentListLayout";
import AllModelList from "./ModelsCustomTable/AllModelList";
import { useDublicateTheModel, useFetchModels, useUpdateModel } from "../../../../shared/queries";

export const Models = () => {
	const userValue = useAppSelector(store => store.UserReducer?.user?.id)

	const [pickedId, setPickedId] = useState<string>('')

	const [tableParams, setTableParams] = useState<IGeneralTableParams>({
		page: 0,
		size: 10,
		userId: Number(sessionStorage.getItem('userId'))
	});

	const changeTableParams = (params: IGeneralTableParams) => {
		setTableParams(params)
	}

	const pickIdFromTable = (id: number) => {
		setPickedId(String(id))
	}

	const { data, isLoading, isPending } = useFetchModels({ params: tableParams });
	const createModelMutation = useUpdateModel({ toastMessage: 'Model added successfully' })
	const dublicateModelMutation = useDublicateTheModel()

	const confirmModelAdd = (newModel: any) => {
		createModelMutation.mutate(newModel)
	}

	const newModel = {
		name: `Model #${data && data.totalCount ? data.totalCount + 1 : 0}`,
		isCreate: true,
		inputs: [],
		outputs: [],
		rules: []
	}

	return (

		<ComponentListLayout current="Models">

			<Flex gap='middle' vertical align='flex-start' style={{ position: 'relative' }}>

				<Flex justify="space-between" align="center" style={{ position: 'absolute', height: 40, width: 90, padding: '0 4px', top: '-45px', right: 0, zIndex: 5, borderRadius: 8 }}>
					<Button
						style={{ height: 36, width: 36 }}
						onClick={() => confirmModelAdd(newModel)}
						icon={<PlusOutlined />}
						disabled={createModelMutation.isPending}
					/>
					<Button
						style={{ height: 36, width: 36 }}
						onClick={() => dublicateModelMutation.mutateAsync(pickedId)}
						icon={<FontAwesomeIcon icon={faClone} />}
					/>
				</Flex>

				{data && tableParams &&
					<AllModelList
						loading={isLoading}
						pickIdFromTable={pickIdFromTable}
						requestParams={tableParams}
						changeTableParams={changeTableParams}
						dataSource={data.list}
						totalCount={data.totalCount}
					/>
				}

				{(isLoading || isPending) &&
					<Flex
						style={{ width: '100%', minHeight: '200px' }}
						align='center'
						justify='center'
					>
						<Spin />
					</Flex>
				}

			</Flex>

			{createModelMutation.contextHolder}
			{dublicateModelMutation.contextHolder}

		</ComponentListLayout>
	);
}
export default Models;
