import { Button, Flex, Input, Modal, Table } from 'antd'
import { useState } from 'react'

function Teams() {

	const [isModalOpen, setIsModalOpen] = useState(false);

	const closeModal = () => setIsModalOpen(false)

	const columns = [
		{
			title: 'Команда',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Принадлежность к виду вовлечннной стороны',
			dataIndex: 'age',
			key: 'age',
		},
	];

	return (
		<Flex vertical>
			<Flex align='flex-end' justify='end' gap='middle'>
				<Button>Добавить</Button>
				<Button onClick={() => setIsModalOpen(true)}>Открыть существующего</Button>
			</Flex>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
			/>

			<Modal
				title="Редактировать"
				width={800}
				open={isModalOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={
					<Flex justify='space-between'>
						<Button onClick={closeModal} danger>
							Удалить
						</Button>
						<Flex gap='middle' align='end' style={{ textAlign: 'right' }}>
							<Button onClick={closeModal} >
								Отмена
							</Button>
							<Button onClick={closeModal}>
								Сохранить
							</Button>
						</Flex>
					</Flex>
				}>
				<Flex vertical gap='middle'>
					<Flex vertical>
						<span>Команда</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

					<Flex vertical>
						<span>Принадлежность к виду вовлечннной стороны</span>
						<Input
							defaultValue="test"
						/>
					</Flex>

				</Flex>
			</Modal>

		</Flex>
	)
}

export default Teams