import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex, Modal, Tooltip } from 'antd'
import { useState } from 'react';
import FormOfStructure from './Form';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../../../../redux/Model/ConnectorReducer';
import { useAppSelector } from '../../../../../../redux/Store';


function LoginTable({ currentField }: { currentField: number | undefined }) {
	const dispatch = useDispatch()
	const [isModalOpen, setIsModalOpen] = useState(false);
	const conType = useAppSelector(state => state.ConnectorReducer.connector?.summarySubType)
	const totalCount = useAppSelector(state => state.ConnectorReducer.connector?.connectionList?.list.length)
	const disabled = conType === 'REQUESTER' && totalCount !== undefined && totalCount >= 1

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const removeField = () => {
		if (currentField !== undefined) {
			dispatch({
				type: ActionTypes.CONNECTOR_REMOVE_CONNECTION,
				payload: currentField
			});
		}
	}

	return (
		<Flex
			vertical
			gap='small'
			style={{ width: '65%' }}
		>

			<Flex gap='small'>
				<Tooltip title={disabled ? 'В запрашивающем коннекторе доступна только одна запись' : ''}>
					<Button
						style={{ width: 40 }}
						icon={<FontAwesomeIcon icon={faPlus} />}
						disabled={disabled}
						onClick={showModal}
					/>
				</Tooltip>
				<Button
					style={{ width: 40 }}
					icon={<FontAwesomeIcon icon={faMinus} />}
					onClick={removeField}
				/>
			</Flex>

			<Modal
				title="Добавить новое соединение"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleOk}
				footer={null}
			>
				<FormOfStructure closeModal={handleOk} />
			</Modal>

		</Flex>
	)
}

export default LoginTable