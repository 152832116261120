import { Button, Flex, Form, Input } from "antd";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import type { FormProps } from 'antd';
import { useFetchPrivileges, usePostUser, useRegisterUser } from "../../../shared/queries";
import { authErrorHandler } from "../../../hooks/AuthHook";
import { URLPaths } from "../../../config/application/URLPaths";

interface FieldType {
	login: string;
	password: string;
	email: string;
	phone: string
};

interface RequestRegisterData extends FieldType {
	copyShippingToBilling: boolean
	roleIds: Array<number>
}

function RegistrationForm() {

	const navigate = useNavigate()
	const regUser = useRegisterUser()



	const onTest: FormProps<RequestRegisterData>['onFinish'] = async (values) => {
		regUser.mutateAsync({ ...values, roleIds: [3], copyShippingToBilling: true })
		navigate(URLPaths.AUTH.link);
	}

	const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Flex style={{ height: 'calc(100vh - 70px)', width: '100%' }} justify="center" align="center">
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600, backgroundColor: '#fff', padding: 20, borderRadius: 12 }}
				initialValues={{ remember: true }}
				onFinish={onTest}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item<FieldType>
					label="Username"
					name="login"
					rules={[{ required: true, message: 'Please input your username!' }]}
				>
					<Input />
				</Form.Item>


				<Form.Item<FieldType>
					label="Password"
					name="password"
					rules={[{ required: true, message: 'Please input your password!' }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item<FieldType>
					label="Email"
					name="email"
					rules={[{ required: true, message: 'Почта не введена или введена не верно', type: 'email' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Phone"
					name="phone"
					rules={[{ required: true, message: 'Please input your phone number!' }]}
				>
					<Input />
				</Form.Item>

				<span >
					Есть аккаунт ?&nbsp;
					<Link to={`${URLPaths.AUTH.link}`}>Войти</Link>
				</span>



				<Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{ marginTop: '15px' }}>
					<Button
						type="primary"
						htmlType="submit"
						loading={regUser.isPending}
					>
						Submit
					</Button>
				</Form.Item>
			</Form>
			{regUser.contextHolder}
		</Flex>
	);
}

export default RegistrationForm