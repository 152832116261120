import { Button, Flex, Spin } from 'antd'
import { useEffect } from 'react'
import { IResultOfTestData, RuleType } from '../../../../../shared/entities/Rule/Rule'
import { useAppSelector } from '../../../../../redux/Store'
import { useDispatch } from 'react-redux'
import { ActionTypes } from '../../../../../redux/Model/RuleReducer'
import { useCalculateRuleEvaluate } from '../../../../../shared/queries'

function CurrentResultOfTestData() {
	const dispatch = useDispatch()
	const { rule, resultOfTestData } = useAppSelector(store => store.RuleReducer)

	useEffect(() => {
		if (rule)
			dispatch({
				type: ActionTypes.RULE_GET_RESULT_OF_TESTDATA,
				payload: { result: 'undefined' }
			})
	}, [])

	const calculate = useCalculateRuleEvaluate()

	const getResult = () => {

		const testdata: { [key: string]: string | number } = rule?.fields
			? rule.fields.reduce((acc, field) => {
				if (field.testValueJson !== null) {
					acc[field.name] = field.testValueJson;
				}
				return acc;
			}, {} as { [key: string]: string | number })
			: {};

		if (rule && rule.summarySubType === 'QUALITY') {

			const conditions: { number: number; condition: string }[] = rule?.jsonData
				? rule.jsonData.map((item) => ({
					number: item.number as number,
					condition: item.condition
				}))
				: [];

			calculate.mutate({ data: { testdata, conditions }, ruleType: 'quality' });
		}
		else {
			const quantityCondition = rule && rule.jsonData && rule?.jsonData[0].condition
			//@ts-ignore
			const quantityResCondition = rule && rule.jsonData && rule?.jsonData[0].resultCondition

			calculate.mutate({ data: { testdata, quantityCondition, quantityResCondition }, ruleType: 'quantity' });
		}
	}

	if (rule)
		return (

			<Flex style={{ width: '100%' }} gap='middle'>

				{calculate.contextHolder}

				<span style={{ width: '25%' }}>Текущее тестовое значение</span>

				<Flex gap='middle' justify='flex-start' align='center'>

					<Button onClick={getResult}>Calculate</Button>

					{rule?.summarySubType === RuleType.QUALITY && rule?.jsonData && Array.isArray(resultOfTestData) ? (
						<span style={{ width: '70%' }}>
							{resultOfTestData.filter(item => item.result === "true")[0]?.number + '-' +
								(rule.jsonData.find(item => item.number === resultOfTestData.filter(item => item.result === "true")[0]?.number))?.textValue}
						</span>
					) : (
						<span>
							Result: {resultOfTestData && 'result' in resultOfTestData
								? String((resultOfTestData as IResultOfTestData).result)
								: ''
							}
						</span>
					)}

				</Flex>
			</Flex>
		)

	else return <Spin />

}

export default CurrentResultOfTestData