import { useMutation, useQueryClient } from "@tanstack/react-query";
import useCustomToast from "../../../../components/CustomToast/useCustomToast";
import { v4 as uuidv4 } from 'uuid';
import { postHandbookInvolvedParties, putHandbookInvolvedParties } from "../../../../api/requests";

export function usePostHandbookInvolvedPartiesType() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (config: any) => postHandbookInvolvedParties({ config }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Welcome!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['dataStructureList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useUpdateHandbookInvolvedPartiesType() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (data: any) => putHandbookInvolvedParties({ config: data }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Welcome!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['dataStructureList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}