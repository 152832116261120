
import React, { useState } from "react";
import { Button, Flex, Result, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { URLPaths } from "../../config/application/URLPaths";

export const Home: React.FC<{ isAuth: boolean }> = ({ isAuth }) => {
	const [isLoading] = useState(false);
	const navigate = useNavigate();

	const err = process.env.PUBLIC_URL + '/static/403.svg';

	return (

		<Flex vertical style={{ width: '100%', height: '100%' }} justify="center" align="center">
			{isLoading ? (
				<Flex style={{ width: '100%', height: '100vh' }} justify="center" align="center">
					<Spin />
				</Flex>
			) : (
				<>
					{isAuth === false ? <Result
						style={{ width: 300, height: 300, padding: 0, marginBottom: '100px', objectFit: 'cover' }}
						status="error"
						title="403"
						icon={<img alt="403 error" src={err} />}
						subTitle="Sorry, you are not authorized to access this page."
						extra={<Button
							type="primary"
							onClick={() => navigate(URLPaths.AUTH.link)}
						>
							Sign in
						</Button>}
					/>
						:
						<Result
							status="404"
							title="This page will be here soon"
							subTitle="Sorry, the page you visited does not exist."
						/>
					}
				</>

			)}

		</Flex>

	)
}
