import { UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllRules, getRuleById, getRuleFromModel, postDublicateRuleById, postRuleEvaluate, postRulesWithUpdates } from "../../../api/requests";
import useCustomToast from "../../../components/CustomToast/useCustomToast";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../redux/Model/RuleReducer";
import { v4 as uuidv4 } from 'uuid';


export function useFetchRules({ params }: { params: IGeneralTableParams }, options?: UseQueryOptions<IGetRuleList, Error>): UseQueryResult<IGetRuleList, Error> {
	return useQuery({
		queryKey: ['rulesList', params],
		queryFn: () => getAllRules({ params }),
		enabled: !!params && params.userId !== null && params.userId !== undefined,
		...options
	});
}

export function useFetchRule(
	{ id, fromModel }: { id?: string; fromModel?: string },
	options?: UseQueryOptions<IGetRuleById, Error>
): UseQueryResult<IGetRuleById, Error> {
	return useQuery({
		queryKey: ['getRule', id, fromModel],
		queryFn: () => {
			if (!!fromModel) {
				return getRuleFromModel({ id: Number(id), modelId: Number(fromModel) });
			} else {
				return getRuleById({ id: Number(id) });
			}
		},
		...options
	});
}

export function useUpdateRule({ toastMessage }: { toastMessage: string }) {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (newRule: any) => postRulesWithUpdates({ config: newRule }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: toastMessage, key: uuidv4() });

			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['rulesList'] });
					await queryClient.invalidateQueries({ queryKey: ['getRule'] });
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}

export function useDublicateTheRule() {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (id: string) => postDublicateRuleById({ params: { id } }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Rule dublicated successfully', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['rulesList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}

export function useCalculateRuleEvaluate() {

	const dispatch = useDispatch()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: ({ data, ruleType }: { data: any, ruleType: string }) => postRuleEvaluate({ config: data, ruleType }),
			onSuccess: (data) => {
				dispatch({ type: ActionTypes.RULE_GET_RESULT_OF_TESTDATA, payload: data.data })
				toast.handleToast({ type: 'success', text: 'Success', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error: ${error.message}`, key: uuidv4() });
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}
