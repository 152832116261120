

import { Select } from 'antd'
import { useDispatch } from 'react-redux'
import { ActionTypes as InputConActionTypes } from "../../../redux/Model/ConnectorReducer";


const SelectItem = ({ defaultValue, id, type, children }: { defaultValue: string, id: string | number, type?: string, children: boolean }) => {

	const dispatch = useDispatch()
	const onClick = (e: any) => {
		e.stopPropagation()
		dispatch({
			type: InputConActionTypes.CONNECTOR_PICK_ROW,
			payload: {
				field: id
			}
		})
	}

	const handleChange = (value: string) => {
		dispatch({
			type: InputConActionTypes.CONNECTOR_UPDATE_ROW,
			payload: {
				key: "fieldType",
				data: value,
			},
		});
	};

	return (
		<Select
			value={defaultValue}
			style={{ width: "90%" }}
			onClick={(e) => onClick(e)}
			onChange={handleChange}
			options={[
				{ value: "STRING", label: "STRING" },
				{ value: "INTEGER", label: "INTEGER" },
				{ value: "DOUBLE", label: "DOUBLE" },
				{ value: "OBJECT", label: "OBJECT" },
			]}
		/>
	)
}

export default SelectItem