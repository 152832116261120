import { Button, Flex, FloatButton, Table, TableProps, notification } from 'antd'
import { MoreOutlined, SaveOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../../redux/Store'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActionTypes } from '../../../../../redux/Model/ConnectorReducer';
import { useFormikContext } from 'formik';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConnectorLinkModal from './ConLinkModal/ConnectorLinkModal';
import { useFetchAccessFields, useFetchFieldRelation, useUpdateFieldRelation } from '../../../../../shared/queries';

interface DataType {
	id: number;
	name: string;
	description: string
	fieldType: string;
	maxSize: number;
	allowEmpty: boolean;
	prohibitSpecCharacters: boolean;
	allowArray: boolean;
	maxArray: number;
	children: DataType[] | null;

	newItem?: boolean
}

const mapChildren = (fields: any[]): any[] => {
	return fields.map((field) => ({
		value: field.id,
		disabled: field.children !== null,
		title: field.name,
		children: field.children ? mapChildren(field.children) : null,
	}));
}
const mapFields = (fields: any[]): any[] => {
	return fields.map((field) => ({
		value: field.id,
		disabled: field.children !== null,
		title: `${field.type} ${field.id}`,
		children: field.fields ? mapChildren(field.fields) : null,
	}));
};

function ConnectorLinkTable({ isInputConnector, dataSource }: { isInputConnector: boolean, dataSource: Array<DataType> }) {

	const dispatch = useDispatch()
	const { fromModel, id } = useParams()
	const formik = useFormikContext()
	const fieldsRelation = useAppSelector(state => state.ConnectorReducer.fieldRelation)
	const fieldsRelationId = useAppSelector(state => state.ConnectorReducer.fieldRelation?.fieldRelationRequestSubDataDTOList)

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [defaultTitle, setDefaultTitle] = useState<string>()
	const [api, contentHolder] = notification.useNotification();
	const [unrelatedId, setUnrelatedId] = useState<number[]>()

	const changeDefaultTitle = (newTitle?: string) => {
		setDefaultTitle(newTitle)
	}

	const getAccessField = useFetchAccessFields({ fromModel, id, isRule: false, isInputConnector })
	const getFieldRelation = useFetchFieldRelation({ fromModel, isRule: false })
	const updateChanges = useUpdateFieldRelation({ fromModel, toastMessage: 'Fields have been successfully related' })


	const colsWithFieldRelation: TableProps<DataType>['columns'] = [
		{
			title: 'Имя',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Тип данных',
			dataIndex: 'fieldType',
			key: 'fieldType',
			align: 'left',
		},
		{
			title: 'Макс. размер',
			dataIndex: 'maxSize',
			key: 'maxSize',
			align: 'center',
		},
		{
			title: 'Пустые',
			dataIndex: 'allowEmpty',
			key: 'allowEmpty',
			align: 'center',
			render: (text: boolean, record: any) => {
				return (record.fieldType !== 'OBJECT' ? <FontAwesomeIcon icon={text === true ? faCheck : faXmark} /> : null)
			}
		},
		{
			title: 'Спецсимволы',
			dataIndex: 'prohibitSpecCharacters',
			key: 'prohibitSpecCharacters',
			align: 'center',
			render: (text: boolean, record: any) => {
				return (record.fieldType !== 'OBJECT' ? <FontAwesomeIcon icon={text === true ? faCheck : faXmark} /> : null)
			}
		},
		{
			title: 'Множество',
			dataIndex: 'allowArray',
			key: 'allowArray',
			align: 'center',
			render: (text: boolean, record: any) => {
				return (record.fieldType !== 'OBJECT' ? <FontAwesomeIcon icon={text === true ? faCheck : faXmark} /> : null)
			}
		},
		{
			title: 'Макс. кол-во',
			dataIndex: 'maxArray',
			key: 'maxArray',
			align: 'center',
			render: (text: string, record: any) => {
				return (record.fieldType !== 'OBJECT' ? <span>{text}</span> : null)
			}
		},
		{
			title: 'Связь с данными',
			key: 'edit',
			align: 'left',
			render: (text: string, record: any) => {
				return (record.fieldType !== 'OBJECT' && isInputConnector === false ?

					<div>
						{fieldsRelationId?.find(field => field.varSummaryFieldId === record.id) ?
							<Button
								style={{ borderColor: 'green' }}
								onClick={showModal}>
								{fieldsRelationId?.find(field => field.varSummaryFieldId === record.id)?.srcName ?
									fieldsRelationId?.find(field => field.varSummaryFieldId === record.id)?.srcName :
									defaultTitle
								}
							</Button>
							:
							<Button
								shape="circle"
								icon={<MoreOutlined />}
								style={unrelatedId && unrelatedId.find(item => item === record.id) ? { backgroundColor: "#FFF", borderColor: 'red' } : { backgroundColor: "#FFF" }}
								onClick={showModal}
							/>
						}
					</div>
					: null)
			}
		},
	]

	const usedCols = !isInputConnector ? colsWithFieldRelation : colsWithFieldRelation.slice(0, colsWithFieldRelation.length - 1)

	const showModal = () => { setIsModalOpen(true); }

	const extractIds = (data: DataType[]) => {
		let ids: Array<number> = [];

		const recursiveExtract = (items: DataType[]) => {
			items.forEach(item => {
				if (item.fieldType !== 'OBJECT') {
					ids.push(item.id);
				}

				if (item.children && Array.isArray(item.children)) {
					recursiveExtract(item.children);
				}
			});
		};

		recursiveExtract(data);
		return ids;
	};

	const saveFieldRelation = () => {

		const idsWithNonNullTestValueJson = extractIds(dataSource)

		const idWithoutFieldRelation = idsWithNonNullTestValueJson && idsWithNonNullTestValueJson.filter(id =>
			!fieldsRelation?.fieldRelationRequestSubDataDTOList.some(item => item.varSummaryFieldId === id)
		);

		setUnrelatedId(idWithoutFieldRelation)

		if (fieldsRelation && fromModel && idWithoutFieldRelation.length === 0) {

			const filteredRelation = fieldsRelation.fieldRelationRequestSubDataDTOList.map(({ srcSummaryFieldId, varSummaryFieldId, varName, srcName }) => ({ srcSummaryFieldId, varSummaryFieldId, varName, srcName }))

			updateChanges.mutate({ sdvmodelStructId: Number(fieldsRelation.sdvmodelStructId), fieldRelationRequestSubDataDTOList: filteredRelation })
		}

		if (fromModel && idWithoutFieldRelation && idWithoutFieldRelation.length > 0) {
			api.open({
				message: <span style={{ color: 'red' }}>Распределите поля</span>,
				description: `Поля ${idWithoutFieldRelation.join(', ')} не были распределены`,
				duration: 3,
			});
		}
	}

	return (
		<Flex vertical justify='flex-end' align='flex-end' style={{ width: '100%', position: 'relative' }}>

			{updateChanges.contextHolder}
			{contentHolder}

			<ConnectorLinkModal
				closeModal={() => setIsModalOpen(false)}
				modalStatus={isModalOpen}
				dataSource={getAccessField.data && mapFields(getAccessField.data)}
				changeDefaultTitle={changeDefaultTitle}
			/>

			<Table
				columns={usedCols}
				dataSource={dataSource}
				size='small'
				rowKey={(row) => row.id}
				style={{ width: '100%' }}
				onRow={(record: any) => {
					return {
						onClick: () => {
							dispatch({ type: ActionTypes.CONNECTOR_SELECT_FIELD, payload: record })
						},
					};
				}}
				expandable={{ defaultExpandAllRows: true }}
			/>

			<FloatButton
				icon={<SaveOutlined />}
				onClick={() => {
					if (isInputConnector === false) {
						saveFieldRelation();
					}
					formik.handleSubmit();
				}}
			/>

		</Flex>
	)
}

export default ConnectorLinkTable