import { UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllDataStructure, postDataStructureWithUpdates, postDublicateDataStructureById } from "../../../api/requests";
import useCustomToast from "../../../components/CustomToast/useCustomToast";
import { v4 as uuidv4 } from 'uuid';

export function useFetchDataStructure({ params }: { params: IGeneralTableParams }, options?: UseQueryOptions<IGetDataStructureList, Error>): UseQueryResult<IGetDataStructureList, Error> {
	return useQuery({
		queryKey: ['dataStructureList', params],
		queryFn: () => getAllDataStructure({ params }),
		enabled: !!params && params.userId !== null && params.userId !== undefined,
		...options
	})

}

export function useAddNewDataStructure() {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (newDataStructure: any) => postDataStructureWithUpdates({ config: newDataStructure }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Data Structure added successfully', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['dataStructureList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}

export function useDublicateDataStructure() {

	const queryClient = useQueryClient()
	const toast = useCustomToast()

	return {
		...useMutation({
			mutationFn: (id: string) => postDublicateDataStructureById({ params: { id } }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Rule dublicated successfully', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['dataStructureList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}