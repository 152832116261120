import { Button, Flex, Input, Modal, Table, Form, FormProps } from 'antd'
import { useState } from 'react'
import { usePostHandbookInvolvedPartiesType, useUpdateHandbookInvolvedPartiesType } from '../../../shared/queries/handbook/involvedPartiesType/useInvolvedPartiesType';

function ParticipantType() {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCreate, setCreate] = useState(false)
	const [form] = Form.useForm();

	const closeModal = () => {
		setIsModalOpen(false);
		form.resetFields();
	};

	const create = usePostHandbookInvolvedPartiesType();
	const update = useUpdateHandbookInvolvedPartiesType();

	const handleSave: FormProps<InvolvedPartiesType>['onFinish'] = async (values) => {
		console.log(values)
		try {
			if (isCreate) {
				await create.mutateAsync(values);
			} else {
				await update.mutateAsync(values);
			}
			closeModal();
		} catch (error) {
			console.error('Ошибка сохранения:', error);
		}
	};

	const columns = [
		{
			title: 'Вид вовлеченной стороны',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Короткое наименование вида',
			dataIndex: 'age',
			key: 'age',
		},
		{
			title: 'Принадлежность к другому виду вовлеченнной стороны',
			dataIndex: 'address',
			key: 'address',
		},
	];

	return (
		<Flex vertical>
			<Flex align='flex-end' justify='end' gap='middle'>
				<Button onClick={() => {
					setCreate(true)
					setIsModalOpen(true)
				}}>Добавить</Button>
				<Button onClick={() => {
					setCreate(false)
					setIsModalOpen(true)
				}}>Открыть существующий</Button>
			</Flex>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
			/>

			<Modal
				title="Редактировать"
				width={800}
				open={isModalOpen}
				onOk={closeModal}

				onCancel={closeModal}
				footer={
					<Flex justify='space-between'>
						{isCreate || <Button onClick={closeModal} danger>
							Удалить
						</Button>}
						<Flex gap='middle' align='end' style={{ textAlign: 'right' }}>
							<Button onClick={closeModal} >
								Отмена
							</Button>
							<Button onClick={() => form.submit()}>
								Сохранить
							</Button>
						</Flex>
					</Flex>
				}>

				<Form
					form={form}
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: '100%' }}
					labelWrap={true}
					labelAlign='left'
					onFinish={handleSave}
					initialValues={{ remember: true }}
					autoComplete="off"
				>
					<Form.Item<InvolvedPartiesType>
						label="Вид вовлеченной стороны"
						name="type"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item<InvolvedPartiesType>
						label="Короткое наименование вида"
						name="shortType"
					>
						<Input />
					</Form.Item>

					<Form.Item<InvolvedPartiesType>
						label="Принадлежность к другому виду вовлеченнной стороны"
						name="children"
					>
						<Input />
					</Form.Item>
				</Form>
			</Modal>

		</Flex>
	)
}

export default ParticipantType