import React from 'react'
import { Flex } from 'antd'
import AntTableControl from './control'
import AntTable from './table/AntTable'
import { DataModelTableType } from './types'
import { IModelStructComponents } from '../../shared/entities/Connector/Connector'

type ICustomAntTable = {
	cols: { title: string, dataIndex: string, key: string }[] | null
	dataSource: IModelStructComponents[] | undefined
	changeType: () => void
	// fullData: any
}

const CustomAntTable: React.FC<ICustomAntTable> = ({ cols, dataSource, changeType }) => {


	return (
		<Flex
			vertical
			gap='middle'
			style={{
				padding: '8px',
				border: '1px solid #d9d9d9',
				borderRadius: 8,
				width: '33%',
				backgroundColor: '#FAFAFA'
			}}
			onClick={changeType}
		>
			{/* <AntTableControl fullData={fullData} checkedValues={dataSource as IModelStructComponents[]} /> */}
			<AntTable cols={cols} dataSource={dataSource as IModelStructComponents[]} />

		</Flex>
	)
}

export default CustomAntTable