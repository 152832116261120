import { useEffect } from "react";
import { useAuth } from "./hooks/AuthHook";
import { MainContainer } from "./views/MainContainer";
import { buildPrivilegesMap, defaultPrivilegeMap, usePrivileges } from "./hooks/PrivilegesProvider";
import { Spin } from "antd";
import { useQuery } from '@tanstack/react-query'
import { postValidateUser } from "./api/requests";

/**
 * App.
 *
 * App's main starting poing
 *
 * Renders header and app's main workspace
 * @see Header
 * @see MainContainer
 *
 */
function App() {

	const { isAuth, handleAuthChange } = useAuth()
	const { setPrivileges } = usePrivileges()

	const { isError, error } = useQuery({
		queryKey: ['validateUser', isAuth],
		queryFn: () => {
			return postValidateUser()
				.then((res) => {
					if (res.data.privileges.length) {
						sessionStorage.setItem("login", String(res.data.login));
						setPrivileges(buildPrivilegesMap(res.data.privileges));
						handleAuthChange(true);
					} else {
						setPrivileges(defaultPrivilegeMap);
						handleAuthChange(false);
					}
					return res.data; // Return the data so useQuery can use it
				})
				.catch((err) => {
					handleAuthChange(false);
					throw err; // Re-throw the error so useQuery can catch it
				});
		},
		enabled: isAuth === null || isAuth === undefined,
	});


	useEffect(() => {
		if (isError) {
			console.log('E', error)
		}
	}, [isError])

	return (
		<div className="App" >
			{isAuth === null || isAuth === undefined ? (
				<Spin />
			) : (
				<MainContainer isAuth={isAuth} />
			)}
			{/* {modalData && <CustomModal {...modalData} />} */}
			{/* {stateModel && <ModalList state={stateModel}/>} */}
		</div>
	);

}

export default App;
