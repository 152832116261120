import { TableColumnsType } from "antd"
import { URLPaths } from "../../../../../config/application/URLPaths"
import { Link } from "react-router-dom"

export const DataStructureListCols: TableColumnsType<IDataStructureList> = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		width: '30%',
		ellipsis: true,
		render: (text, record) => <Link to={`${URLPaths.DATASTRUCTURE_EDIT.link}/${record.versionId}`}>{text}</Link>

	},
	{
		title: 'Создал',
		dataIndex: 'userName',
		key: 'userName',
		ellipsis: true,
		width: '10%',
	},
	{
		title: 'Используется в моделях',
		dataIndex: 'models',
		key: 'models',
		ellipsis: true,
		width: '25%',
		render: (models: { modelName: string }[]) =>
			<span>
				{
					models.map((model, index) => {
						return <span key={index}>{model.modelName}; </span>
					})
				}</span>
	},
	{
		title: 'Коннекторы',
		dataIndex: 'connectors',
		key: 'connectors',
		ellipsis: true,
		width: '20%',
		render:
			(text) =>
				<span>Коннектор</span>
	},
	{
		title: 'В составе группы',
		dataIndex: 'inStructure',
		key: 'inStructure',
		ellipsis: true,
		width: '15%',
		render:
			(text) =>
				<span>нет</span>
	}
]