import { Button, Flex, message, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../../../redux/Store'
import { useDispatch } from 'react-redux'
import { ActionTypes } from '../../../../../../redux/Model/ConnectorReducer'
import { useParams } from 'react-router-dom';

function CronPicker() {
	const dispatch = useDispatch()
	const defaultCron = useAppSelector(store => store.ConnectorReducer.connector?.cron);
	const fromModlel = useParams()
	const [value, setValue] = useState(defaultCron ? defaultCron : '*/7 */2 */3 * *');

	const [messageApi, contextHolder] = message.useMessage();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		dispatch({ type: ActionTypes.CONNECTOR_UPDATE_INFORMATION_VALUE, payload: { key: 'cron', value: `${value}` } })
		messageApi.open({
			type: 'success',
			content: 'Расписание обновлено',
		})
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		console.log('@', value)
		setIsModalOpen(false);
	};

	const handleChange = (newValue: string) => {
		setValue(newValue);
	};

	return (
		<Flex gap='middle' vertical>
			{contextHolder}

			{fromModlel && <Button
				onClick={showModal}
				icon={defaultCron && defaultCron !== undefined ? <EditOutlined /> : <PlusOutlined />}
				style={{ width: 40 }}
			/>}

			<Modal title="Задайте расписание" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<Cron
					value={value}
					setValue={handleChange}
				/>
			</Modal>

			{defaultCron && defaultCron !== undefined ?
				<Cron
					value={defaultCron !== undefined ? String(defaultCron) : '* * * * *'}
					setValue={handleChange}
					readOnly
				/> : null}

		</Flex>
	);
}

export default CronPicker;
