import { Button, Form, FormProps, Input, Modal, Typography } from 'antd'
import React from 'react'

type FieldType = {
	firstLineResponsible?: Array<string>;
	secondLineResponsible?: Array<string>;
	thirdLineResponsible?: Array<string>;
	investigationTeam?: Array<string>;
	headOfInvestigationTeam?: string;
	monitoringResponsible?: Array<string>;
	physicalPlace?: Array<string>;
	system?: string
	process?: Array<string>
	additionalAdress?: Array<string>,
	description?: string,
	victims?: Array<string>,
	participants?: Array<string>,
	minLoss: number,
	maxLoss: number,
	priority: number,
};

type IQualityTableModal = {
	isModalOpen: boolean,
	closeModal: () => void
};

const QualityTableModal: React.FC<IQualityTableModal> = ({ isModalOpen, closeModal }) => {

	const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
		console.log('Success:', values);
	};

	return (
		<Modal
			title="Basic Modal"
			footer={false}
			open={isModalOpen}
			onOk={closeModal}
			onCancel={closeModal}
			width={'80%'}
		>
			<Form
				name="basic"
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
				labelAlign='left'
				labelWrap={true}
				style={{ width: '100%' }}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				autoComplete="off"
			>
				<Form.Item<FieldType>
					label="Ответственные на первой линии"
					name="firstLineResponsible"
					rules={[{ required: true, message: 'Ответственные на первой линии' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Ответственные на второй линии"
					name="secondLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Ответственные на третьей линии"
					name="thirdLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Спец. группа расследования"
					name="thirdLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Руководитель или лидер спец группы рассл"
					name="thirdLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Ответственные за мониторинг или контроль"
					name="thirdLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input />
				</Form.Item>

				<Typography.Title level={4}>Место, где произошел инцидент или есть подозрения на него </Typography.Title>

				<Form.Item<FieldType>
					label="Физическое"
					name="physicalPlace"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input />
				</Form.Item>


				<Form.Item<FieldType>
					label="Система"
					name="system"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Процесс"
					name="process"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Общее описание сути инцидента"
					name="description"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input.TextArea />
				</Form.Item>

				<Form.Item label={null}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default QualityTableModal