import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './NavHeaderLinks.module.scss'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

function NavHeaderLinks({ currentMegaMenuStatus, ChangeMegaMenuStatus }: { currentMegaMenuStatus: boolean, ChangeMegaMenuStatus: (status: boolean) => void }) {

	const { container, activeArrow, defaultArrow } = styles

	return (
		<ul className={container}>
			<li
				onMouseEnter={() => ChangeMegaMenuStatus(true)}
				onMouseLeave={() => ChangeMegaMenuStatus(false)}
			>
				<FontAwesomeIcon icon={faChevronUp} className={currentMegaMenuStatus ? activeArrow : defaultArrow} />
				Продукты и услуги
			</li>
			<li>Демо</li>
			<li>Тарифы</li>
			<li>Поддержка</li>
			<li>Компания</li>
		</ul>
	)
}

export default NavHeaderLinks