import { useEffect } from 'react'
import { Flex, Space, Typography } from 'antd'
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CustomTable from '../../../../components/customTable/CustomTable';
import ConnectorFormInformation from './mainInfo/ConnectorFormInformation';
import { useAppSelector } from '../../../../redux/Store';
import ConnectorAdditionalForm from './additionalInfo/ConnectorAdditionalForm';
import ReturnButton from '../../../../components/ReturnButton/ReturnButton';
import ConnectorLinkTable from './conLinkTable/ConnectorLinkTable';
import { ActionTypes } from '../../../../redux/Model/ConnectorReducer';
import { useFetchConnectorById, useUpdateConnector } from '../../../../shared/queries';

interface DataType {
	id: number;
	name: string;
	fieldType: string;
	maxSize: number;
	allowEmpty: boolean;
	prohibitSpecCharacters: boolean;
	allowArray: boolean;
	maxArray: number;
	children: DataType[] | null;
}

function ConnectorFormEdit({ edit, connectorType }: { edit: boolean, connectorType: 'input' | 'output' }) {

	const { id, fromModel } = useParams();
	const dispatch = useDispatch();
	const Connector = useAppSelector(store => store.ConnectorReducer.connector)
	const defaultFields: Array<number> = useAppSelector(store => store.ConnectorReducer.fieldList);
	const connectorFields = useAppSelector(store => store.ConnectorReducer.connector?.fields)

	const { data } = useFetchConnectorById({ id: String(id), connectorType })
	const editConnectorMutation = useUpdateConnector({ toastMessage: 'Connector Succesfully updated' })

	useEffect(() => {
		if (data) {
			dispatch({
				type: ActionTypes.CONNECTOR_GET,
				payload: data,
			});
		}
	}, [data, dispatch]);

	const removeIdFromGrid = (grid: DataType[]) => {
		return grid.map((item: any) => {
			const { key, depth, fromParent, index, isLast, newItem, parent, parentId, id, children, srcRelationId, ...rest } = item;
			const updatedId = defaultFields.includes(Number(id)) ? id : -1
			const updatedItem = { ...rest, id: updatedId };

			if (children && Array.isArray(children)) {
				updatedItem.children = removeIdFromGrid(children);
			}

			return updatedItem;
		})
	}

	return (
		<Space direction='vertical' style={{ padding: 20, width: "100%" }}>

			<ReturnButton tab='connectors' fromModel={fromModel && fromModel} />

			<Typography.Title level={2}>
				{!edit ? 'Creating a connector to receive and check data from other systems' :
					'Update a connector to receive and check data from other systems'}
			</Typography.Title>
			<Formik
				initialValues={{}}
				onSubmit={(values: any, actions) => {
					values.connectorPurpose = Object.entries(values.connectorPurpose || {})
						.filter(([key, value]) => value === true)
						.map(([key]) => key);

					values.isCreate = !edit
					values.isTemplate = false
					values.type = connectorType === 'input' ? 'CONNECTOR_INPUT' : 'CONNECTOR_OUTPUT'
					values.extend = (Connector?.extended && !Connector.extended.isActive) ? Connector?.extended : null;
					values.fields = removeIdFromGrid(Connector?.fields as DataType[])
					values.urlForInfo = Connector?.urlForInfo
					values.cron = Connector?.cron !== null ? `*/60 ${Connector?.cron}` : null
					values.common = Connector?.common
					values.authorizations = Connector?.connectionList?.list.map(field => ({ ...field, id: -1 }));
					if (!values.isCreate) {
						values.versionId = id
					}
					editConnectorMutation.mutate(values);
				}}
			>
				{({ isSubmitting, errors, touched, handleSubmit }) => (
					<Form onSubmit={handleSubmit} autoComplete="off" >
						{editConnectorMutation.contextHolder}
						<Space direction='vertical' style={{ width: "100%" }}>

							<ConnectorFormInformation
								defaultInfo={data as IGetConnectorById}
								connectorType={connectorType}
							/>

							<div style={{ width: '100%', borderTop: '1px solid rgba(0, 0, 0, .1)', margin: '20px 0' }}></div>

							<Flex gap='middle' vertical>
								<Typography.Title level={5} style={{ width: '100%' }}>Структура получаемых данных</Typography.Title>
								<div style={{ width: '100%' }}>
									{fromModel ?
										connectorFields &&
										<ConnectorLinkTable
											dataSource={connectorFields}
											isInputConnector={connectorType === 'input' ? true : false} />
										:
										<CustomTable isInputConnector={connectorType === 'input' ? true : false} />}
								</div>
							</Flex>

							<ConnectorAdditionalForm connectorType={connectorType} defaultInfo={data as IGetConnectorById} />
						</Space>
					</Form>
				)}
			</Formik>
		</Space>
	)
}

export default ConnectorFormEdit