import { FC, useState } from "react";
import { Flex, Button, Spin } from 'antd'
import { useAppSelector } from "../../../../redux/Store";
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import ComponentListLayout from "../../common/all/ComponentListLayout/ComponentListLayout";
import AddNewRuleModal from "./addNewRuleModal/AddNewRuleModal";
import AllRulesList from "./ruleListTable/AllRulesList";
import { useDublicateTheRule, useFetchRules } from "../../../../shared/queries";

export const Rules: FC = () => {

	const userValue = useAppSelector(store => store.UserReducer?.user?.id)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [pickedId, setPickedId] = useState<string>()
	const [tableParams, setTableParams] = useState<IGeneralTableParams>({
		page: 0,
		size: 10,
		userId: Number(sessionStorage.getItem('userId'))
	});

	const { isPending, data, isLoading } = useFetchRules({ params: tableParams })
	const dublicateRuleMutation = useDublicateTheRule()

	const pickIdFromTable = (id: number) => {
		setPickedId(String(id))
	}

	return (
		<ComponentListLayout current="Rules">

			{dublicateRuleMutation.contextHolder}

			<Flex vertical gap='middle' align="flex-end" style={{ position: 'relative' }}>

				<Flex justify="space-between" align="center" style={{ position: 'absolute', height: 40, width: 90, padding: '0 4px', top: '-45px', right: 0, zIndex: 5, borderRadius: 8 }}>
					<Button
						style={{ height: 36, width: 36 }}
						onClick={() => setIsModalOpen(true)}
						icon={<PlusOutlined />}
					/>
					<Button
						style={{ height: 36, width: 36 }}
						onClick={() => pickedId !== undefined ? dublicateRuleMutation.mutateAsync(pickedId) : null}
						icon={<FontAwesomeIcon icon={faClone} />}
					/>
				</Flex>

				<AddNewRuleModal
					modalStatus={isModalOpen}
					closeModal={() => setIsModalOpen(false)}
				/>

				{data && tableParams && <AllRulesList
					pickIdFromTable={pickIdFromTable}
					dataSource={data?.list}
					totalCount={data.totalCount}
					changeTableParams={(newParams: IGeneralTableParams) => setTableParams(newParams)}
					requestParams={tableParams}
				/>}

				{(isLoading || isPending) &&
					<Flex
						style={{ width: '100%', minHeight: '200px' }}
						align='center'
						justify='center'
					>
						<Spin />
					</Flex>
				}

			</Flex>

		</ComponentListLayout>
	);



}
export default Rules;
