import { AxiosRequestConfig } from "axios";
import { api } from "../../instance";

interface RequestModelParams {
	params?: { page: number, size: number, userId: number };
	config?: AxiosRequestConfig;
}

export const getAllModels = async ({ params, config }: RequestModelParams) => {
	const response = await api.post<IGetModelsList>('core-service/api/model/all',
		{},
		{ params, ...config }
	)
	return response.data
}

export const postModelsWithUpdates = ({ config }: RequestModelParams) => {
	return api.post<IGetModelsList>('core-service/api/model/create-or-update', { ...config })
		.then((res) => {
			const result = {
				data: res.data
			};
			return result;
		})
		.catch((err) => {
			const error = {
				message: err.response.data.message
			};
			return Promise.reject(error);
		});
}