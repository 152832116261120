import { Button, Flex, Popconfirm, Table, TableProps, message } from 'antd';
import React, { useState } from 'react'
import { RuleListTitles } from './RuleListTitles';
import styles from './RuleList.module.scss'
import { RightOutlined, UpOutlined } from "@ant-design/icons";
import { deleteById } from '../../../../../api/requests';
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface ITableParams {
	page: number,
	size: number,
	userId: number
}

interface IAllRulesList {
	dataSource: Array<IRuleList>
	pickIdFromTable: (id: number) => void
	totalCount: number
	requestParams: ITableParams
	changeTableParams: (params: ITableParams) => void
}

const AllRulesList: React.FC<IAllRulesList> = ({ dataSource, pickIdFromTable, requestParams, totalCount, changeTableParams }) => {

	const queryClient = useQueryClient()
	const [messageApi, contextHolder] = message.useMessage();

	const [selectedRowId, setSelectedRowId] = useState<number>()

	const handleTableChange: TableProps['onChange'] = (pagination) => {
		const newPage = pagination.current ? pagination.current - 1 : 0;
		const newPageSize = Number(pagination.pageSize);

		if (newPage >= 0 && (newPage !== requestParams.page || newPageSize !== requestParams.size)) {
			changeTableParams({
				...requestParams,
				page: newPage,
				size: newPageSize,
			})
		}
	}

	const deleteModel = useMutation({
		mutationFn: (id: string) => deleteById({ params: { id } }),
		onSuccess: () => {
			messageApi.success('Rule deleted successfully');
			queryClient.refetchQueries({ queryKey: ['rulesList'] })
		},
		onError: () => {
			messageApi.error('Failed to delete the Rule');
		}
	})

	const confirmDelete = (id: string) => {
		deleteModel.mutate(id);
	};

	return (
		<Table
			style={{ width: '100%' }}
			columns={RuleListTitles}
			size="small"
			dataSource={dataSource}
			rowKey={row => row.versionId as number}
			onChange={handleTableChange}
			// loading={loading}
			pagination={{
				position: ['topRight'],
				current: requestParams.page + 1,
				pageSize: requestParams.size,
				total: totalCount
			}}
			onRow={(record) => {
				return {
					onClick: () => {
						setSelectedRowId(record.versionId);
						if (pickIdFromTable)
							pickIdFromTable(record.versionId as number)
					},
				};
			}
			}
			rowClassName={(record) => (record.versionId === selectedRowId ? styles.picked : styles.default)}
			expandable={{
				expandIcon: ({ expanded, onExpand, record }) =>
					expanded ? (
						<UpOutlined onClick={e => onExpand(record, e)} />
					) : (
						<RightOutlined onClick={e => onExpand(record, e)} />
					)
				,
				expandedRowRender: (record) =>
					<Flex style={{ margin: 0, paddingLeft: 46 }} justify='space-between'>
						{contextHolder}
						<Flex vertical>
							<span>id: {record.versionId}</span>
							<span>Название: {record.name}</span>
							<span>Создал: {record.userName}</span>
							<span>Цель: Определение инцидента</span>
						</Flex>
						{record.summaryState === 'DRAFT' &&

							<Popconfirm
								title={`Delete the rule`}
								description={`Are you sure to delete  ${record.name} ?`}
								onConfirm={() => confirmDelete(record.versionId.toString())}
								okText="Yes"
								cancelText="No"
							>
								<Button danger>Delete</Button>
							</Popconfirm>
						}
					</Flex>,
				rowExpandable: (record) => record.name !== 'Not Expandable',
			}}
		/>
	)
}

export default AllRulesList