
import type { TableProps } from 'antd';


export const incidentColumns: TableProps<IIncidentTable>['columns'] = [
	{
		title: '#',
		dataIndex: 'id',
		key: 'id'
	},
	{
		title: 'Дата и время заявки',
		dataIndex: 'requestTime',
		key: 'requestTime',
	},
	{
		title: 'Автор заявки',
		dataIndex: 'author',
		key: 'author',
	},
	{
		title: 'Место проишенствия инцидента',
		dataIndex: 'address',
		key: 'address',
	},
	{
		title: 'Модель',
		dataIndex: 'model',
		key: 'model',
	},
	{
		title: 'Наименование сценария',
		dataIndex: 'title',
		key: 'title',
	},
	{
		title: 'Основной признак',
		dataIndex: 'cause',
		key: 'cause',
	},
	{
		title: 'Детектор',
		dataIndex: 'detector',
		key: 'detector',
	},
	{
		title: 'Время обнаружения',
		dataIndex: 'detecxtionTime',
		key: 'detecxtionTime',
	},
	{
		title: 'Приоритет',
		dataIndex: 'priority',
		key: 'priority',
	},
	{
		title: 'Время реакции (макс)',
		dataIndex: 'reactionTime',
		key: 'reactionTime',
	},
	{
		title: 'Ответственные',
		dataIndex: 'Responsible',
		key: 'Responsible',
	},
	{
		title: 'Состояние',
		dataIndex: 'state',
		key: 'state',
	},
];
