import { Collapse, CollapseProps, Input, TreeDataNode, TreeSelect } from 'antd'
import React from 'react'



type ISelectFormItem = {
	label: string,
	treeData: Array<any>,
	inputValue: string,
	otherStyle?: React.CSSProperties
}

const SelectFormItem: React.FC<ISelectFormItem> = (
	{
		label,
		treeData,
		inputValue,
		otherStyle
	}
) => {

	const items: CollapseProps['items'] = [
		{
			key: '1',
			label,
			children: <Input value={inputValue} />,
		}
	];

	return (
		<div style={otherStyle}>
			<TreeSelect
				treeData={treeData}
			/>
			<Collapse ghost items={items} />
		</div>
	)
}

export default SelectFormItem