import { useEffect, useState } from 'react'
import TableHeader from './TableHeader'
import { Flex } from 'antd'
import SortableTreeTable from './SortableTree'
import ControlElements from './ControlElements'
import { useAppSelector } from '../../redux/Store'
import { DataType } from './types'
import { useDispatch } from 'react-redux'
import { ActionTypes } from '../../redux/Model/ConnectorReducer'


function CustomTable({ isInputConnector }: { isInputConnector: boolean }) {
	const dispatch = useDispatch()

	const inputConnector = useAppSelector(store => store.ConnectorReducer.connector);
	const outputConnector = useAppSelector(store => store.ConnectorReducer.connector);

	const [grid, setGrid] = useState<DataType[]>([]);

	useEffect(() => {
		// console.log(isInputConnector === true ? 'true' : 'false')
		if (isInputConnector && inputConnector !== null && inputConnector.fields !== undefined) {
			console.log('input', inputConnector)
			const updatedFields = inputConnector.fields.map(field => ({
				...field,
				key: field.id
			}));
			console.log('@@@@@', updatedFields)
			setGrid(updatedFields);
		}
		else
			if (!isInputConnector && outputConnector !== null && outputConnector.fields !== undefined) {
				console.log('output', outputConnector)
				const updatedFields = outputConnector.fields.map(field => ({
					...field,
					key: field.id
				}));
				setGrid(updatedFields);
			}
	}, [inputConnector, outputConnector, isInputConnector]);

	const updateTableRowsPosition = (value: DataType[]) => {
		setGrid(value)
		dispatch({
			type: ActionTypes.CONNECTOR_CHANGE_POS,
			payload: value
		})
	}


	return (
		<Flex vertical style={{ width: '100%', minWidth: '800px' }}>

			<ControlElements grid={grid} isInputConnector={isInputConnector} />

			<TableHeader />
			<SortableTreeTable
				data={grid}
				updatePos={(newPos: DataType[]) => updateTableRowsPosition(newPos)}
				isInputConnector={isInputConnector}
			/>
		</Flex>
	)
}

export default CustomTable