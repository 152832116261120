import { Flex, Typography, Input, Spin, Select, Collapse } from 'antd'
import { useAppSelector } from '../../../../../redux/Store'
import { useDispatch } from 'react-redux'
import { ActionTypes } from '../../../../../redux/Model/ModelReducer'
import type { SelectProps } from 'antd';
import type { CollapseProps } from 'antd';

const SingleField = ({ title, children }: { title: string, children: React.ReactNode }) => {
	return (
		<Flex style={{ width: '100%', paddingLeft: 20 }} gap='middle' justify='flex-start'>
			<Typography.Title level={5} style={{ width: '25%' }}>{title}</Typography.Title>
			{children}
		</Flex>
	)
}



function ModelInfo() {

	const model = useAppSelector(store => store.ModelReducer.model);
	const dispatch = useDispatch()

	const options: SelectProps['options'] = [
		{
			label: 'Управление стратегией',
			value: 'STRATEGY_MANAGEMENT'
		},
		{
			label: 'Управление и разработка продуктов и услуг',
			value: 'PRODUCT_AND_SERVICE_DEVELOPMENT'
		},
		{
			label: 'Управление маркетингом и продажами',
			value: 'MARKETING_AND_SALES_MANAGEMENT'
		},
		{
			label: 'Управление поставками',
			value: 'SUPPLY_MANAGEMENT'
		},
		{
			label: 'Производство',
			value: 'PRODUCTION'
		},
		{
			label: 'Предоставление продуктов и услуг',
			value: 'PRODUCT_AND_SERVICE_DELIVERY'
		},
		{
			label: 'Управление клиентским сервисом',
			value: 'CUSTOMER_SERVICE_MANAGEMENT'
		},
		{
			label: 'Управление финансами',
			value: 'FINANCIAL_MANAGEMENT'
		},
		// {
		// 	label: 'Управление персоналом',
		// 	value: 8
		// },
	];


	const onChangeField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
		const { value } = e.target;

		dispatch({
			type: ActionTypes.MODEL_EDIT_MODEL_INFO,
			payload: { key: type, value }
		})

	}
	const handleChange = (value: string[], type: string) => {

		dispatch({
			type: ActionTypes.MODEL_EDIT_MODEL_INFO,
			payload: { key: type, value }
		})

	};

	const items: CollapseProps['items'] = [
		{
			key: '1',
			label: <Typography.Title level={5} style={{ color: '#BDBDBD' }}>Дополнительная информация</Typography.Title>,
			children: <Flex vertical gap='middle'>
				<SingleField title='Краткое описание'>
					<Input.TextArea
						value={model?.description}
						variant="filled"
						rows={4}
						onChange={(e) => onChangeField(e, 'description')}
						style={{ width: '40%', resize: 'none' }}
						disabled={model !== null && model.state === 'RUN'}
					/>
				</SingleField>

				<SingleField title='Процесс - источник'>
					<Select
						mode="multiple"
						maxTagCount={'responsive'}
						allowClear
						style={{ width: '40%', height: 30 }}
						placeholder="Please select"
						onChange={(value) => handleChange(value, 'processAssignment')}
						options={options}
						disabled={model !== null && model.state === 'RUN'}
						value={model?.processAssignment}
					/>
				</SingleField>


				<SingleField title='Процесс - назначение'>
					<Select
						mode="multiple"
						allowClear
						maxTagCount={'responsive'}
						style={{ width: '40%', height: 30 }}
						placeholder="Please select"
						onChange={(value) => handleChange(value, 'sourceProcess')}
						options={options}
						disabled={model !== null && model.state === 'RUN'}
						value={model?.sourceProcess}
					/>
				</SingleField>

				<SingleField title='Макс. время на все этапы'>
					<Input style={{ width: 130, height: 30, borderRadius: 8 }} addonAfter="ms" />
				</SingleField>
			</Flex>,
		},
	]



	if (model) {
		return (
			<Flex vertical gap='middle' style={{ marginBottom: 50 }}>

				<Flex vertical gap='middle' >
					<SingleField title='Идентификатор'>
						<span>{model.versionId}</span>
					</SingleField>

					<SingleField title='Создал'>
						<span>{model.userName}</span>
					</SingleField>

					<SingleField title='Наименование'>
						<Input
							value={model.name}
							onChange={(e) => onChangeField(e, 'name')}
							variant="filled"
							style={{ width: '40%', height: 30, borderRadius: 8 }}
							disabled={model.state === 'RUN'}
						/>
					</SingleField>
				</Flex>

				<Collapse ghost items={items} style={{ paddingLeft: 0 }} />

			</Flex>
		)


	}

	return (
		<Spin />
	)
}

export default ModelInfo