import { Button, Flex, Form, Input } from "antd";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import type { FormProps } from 'antd';
import { useFetchPrivileges, usePostUser } from "../../../shared/queries";
import { authErrorHandler } from "../../../hooks/AuthHook";
import { URLPaths } from "../../../config/application/URLPaths";

type FieldType = {
	login: string;
	password: string;
};

export const AuthorizationForm: FC = () => {

	const navigate = useNavigate()
	const authUser = usePostUser()
	const postUserResponse = authUser?.data
	const fetchPrivileges = useFetchPrivileges({ postUserResponse })

	const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
		authUser.mutateAsync(values)
		await fetchPrivileges.refetch()
	}

	const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Flex style={{ height: 'calc(100vh - 70px)', width: '100%' }} justify="center" align="center">
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600, backgroundColor: '#fff', padding: 20, borderRadius: 12 }}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item<FieldType>
					label="Username"
					name="login"
					rules={[{ required: true, message: 'Please input your username!' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item<FieldType>
					label="Password"
					name="password"
					rules={[{ required: true, message: 'Please input your password!' }]}
				>
					<Input.Password />
				</Form.Item>

				<span style={{ marginBottom: 10 }}>
					Нет аккаунта ?&nbsp;
					<Link to={`${URLPaths.REGFORM.link}`}>Создать</Link>
				</span>

				<Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{ marginTop: '15px' }}>
					<Button
						type="primary"
						htmlType="submit"
						loading={authUser.isPending}
					>
						Submit
					</Button>
				</Form.Item>
			</Form>
			{authUser.contextHolder}
		</Flex>
	);
}
