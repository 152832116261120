import { Button, Flex, Spin } from 'antd'
import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../redux/Store';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import ComponentListLayout from '../../common/all/ComponentListLayout/ComponentListLayout';
import DataStructureListTable from './DataStructureListTable/DataStructureListTable';
import AddNewDataStructureModal from './addNewDataStructureModal/AddNewDataStructureModal';
import { useDublicateDataStructure, useFetchDataStructure } from '../../../../shared/queries';

function DataStructureList() {

	const userValue = useAppSelector(store => store.UserReducer?.user?.id)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [pickedId, setPickedId] = useState<string>('')
	const [tableParams, setTableParams] = useState<IGeneralTableParams>({
		page: 0,
		size: 10,
		userId: Number(sessionStorage.getItem('userId'))
	});

	const { isPending, isLoading, data } = useFetchDataStructure({ params: tableParams })
	const dublicateDataStructureMutation = useDublicateDataStructure()

	const pickIdFromTable = (id: number) => {
		setPickedId(String(id))
	}

	return (
		<ComponentListLayout current="DataStructures">
			<Flex vertical gap='middle' align='flex-end' style={{ position: 'relative' }}>

				{dublicateDataStructureMutation.contextHolder}

				<Flex justify="space-between" align="center" style={{ position: 'absolute', height: 40, width: 90, padding: '0 4px', top: '-45px', right: 0, zIndex: 5, borderRadius: 8 }}>
					<Button
						style={{ height: 36, width: 36 }}
						onClick={() => setIsModalOpen(true)}
						icon={<PlusOutlined />} />
					<Button
						style={{ height: 36, width: 36 }}
						onClick={() => pickedId !== undefined ? dublicateDataStructureMutation.mutateAsync(pickedId) : () => console.log('error')}
						icon={<FontAwesomeIcon icon={faClone} />} />
				</Flex>

				{data && tableParams && <>
					<DataStructureListTable
						totalCount={Number(data.totalCount)}
						dataSource={data.list}
						requestParams={tableParams}
						changeTableParams={(newParams: IGeneralTableParams) => setTableParams(newParams)}
						loading={isLoading}
						pickIdFromTable={pickIdFromTable}
					/>

					<AddNewDataStructureModal
						modalStatus={isModalOpen}
						closeModal={() => setIsModalOpen(false)}
						dataStructureLength={data.totalCount}
					/>
				</>
				}

				{(isLoading || isPending) &&
					<Flex
						style={{ width: '100%', minHeight: '200px' }}
						align='center'
						justify='center'
					>
						<Spin />
					</Flex>
				}
			</Flex>
		</ComponentListLayout>
	)

}

export default DataStructureList