import { UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteById, getAccessField, getFieldRelation, postFieldRelation } from "../../../api/requests";
import React from "react";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../../redux/Model/RuleReducer";
import useCustomToast from "../../../components/CustomToast/useCustomToast";
import { ActionTypes as ConTypes } from "../../../redux/Model/ConnectorReducer";
import { v4 as uuidv4 } from 'uuid';

export function useFetchAccessFields({ fromModel, id, isRule, isInputConnector }: { id?: string; fromModel?: string, isRule: boolean, isInputConnector?: boolean }, options?: UseQueryOptions<IGetAccessFields[], Error>): UseQueryResult<IGetAccessFields[], Error> {

	const dispatch = useDispatch()

	const query = useQuery({
		queryKey: ['AcceessField'],
		queryFn: () => getAccessField({ sdvModelId: fromModel, targetId: id }),
		enabled: !!id && !!fromModel && isInputConnector !== true,
		...options
	});

	React.useEffect(() => {
		if (query.data) {
			dispatch({
				type: !!isRule ? ActionTypes.RULE_GET_FIELD_RESPONSE : ConTypes.CONNECTOR_GET_FIELD_RESPONSE,
				payload: query.data
			})
		}
	}, [query.data])

	return query
}

export function useFetchFieldRelation({ fromModel, isRule }: { fromModel?: string, isRule: boolean }, options?: UseQueryOptions<IGetFieldRelation[], Error>): UseQueryResult<IGetFieldRelation[], Error> {

	const dispatch = useDispatch()

	const query = useQuery({
		queryKey: ['FieldRelation'],
		queryFn: () => getFieldRelation({ sdvModelId: fromModel }),
		enabled: !!fromModel,
		...options
	});

	React.useEffect(() => {
		if (query.data) {
			dispatch({
				type: !!isRule ? ActionTypes.RULE_GET_FIELDS_RELATION : ConTypes.CONNECTOR_GET_FIELDS_RELATION,
				payload: {
					sdvmodelStructId: fromModel,
					data: query.data
				}
			})
		}
	}, [query.data])

	return query
}

export function useUpdateFieldRelation({ toastMessage, fromModel }: { toastMessage: string, fromModel?: string }) {

	const toast = useCustomToast()
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (newRelation: any) => postFieldRelation({ config: newRelation }),
			onSuccess: (data) => {
				toast.handleToast({ type: 'success', text: toastMessage, key: uuidv4() });
				queryClient.refetchQueries({ queryKey: ['FieldRelation'] })
			},
		}),
		contextHolder: toast.contextHolder
	}
}

export function useDeleteById({ type }: { type: 'model' | 'rule' | 'connector' }) {

	const toast = useCustomToast()
	const queryClient = useQueryClient()
	const toastMessage = `${type} successfully deleted`

	return {
		...useMutation({
			mutationFn: (id: string) => deleteById({ params: { id } }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: toastMessage, key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries(
						type === 'rule' ? { queryKey: ['rulesList'] } :
							type === 'connector' ? { queryKey: ['connectorList'] } :
								type === 'model' ? { queryKey: ['modelsList'] } :
									{ queryKey: ['dataStructureList'] }
					)
				}
			}
		}),
		contextHolder: toast.contextHolder
	}
}