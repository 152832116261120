import { AxiosRequestConfig } from "axios";
import { api } from "../../../instance";


interface RequestSingleModelParams {
	params?: {
		id?: string,
		state?: IModelState
		size?: number
		page?: number
		modelId?: number
	};
	config?: AxiosRequestConfig;
	id?: number
}

export const getModelById = async ({ config, id }: RequestSingleModelParams) => {
	const response = await api.get<IGetModelById>(`core-service/api/model/${id}`,
		{ ...config }
	)
	return response.data
}

export const postDublicateModelById = async ({ params, config }: RequestSingleModelParams) => {
	return await api.post<IGetModelsList>('core-service/api/model/duplicate',
		{},
		{ params, ...config }
	)
		.then((res) => {
			const result = {
				data: res.data
			};
			return result;
		})
		.catch((err) => {
			const error = {
				message: err.response.data.message
			};
			return Promise.reject(error);
		})
}

export const postChangeModelState = async ({ config, id, params }: RequestSingleModelParams) => {
	return await api.post(`core-service/api/v1/transaction-counter/${id}/set-state-all`,
		{},
		{ params, ...config }
	)
		.then((res) => {
			const result = {
				data: res.data
			};
			return result;
		})
		.catch((err) => {
			const error = {
				message: err.response.data.message
			};
			return Promise.reject(error);
		})
}

export const getIncidentsByModel = async ({ config, params }: RequestSingleModelParams) => {
	const response = await api.get<IGetIncidents>(`handler-service/api/v1/incidents/all`,
		{ params, ...config }
	)

	return response.data

}

