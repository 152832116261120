import { Flex, Input, Modal, Space, TreeSelect } from 'antd'
import React, { useState } from 'react'
import { useUpdateConnector } from '../../../../../shared/queries/connector/useConnectorQuery'

type IAddNewConnectorModal = {
	modalStatus: boolean
	hideModal: () => void
	totalCount: number
}

const AddNewConnectorModal: React.FC<IAddNewConnectorModal> = ({ hideModal, modalStatus, totalCount }) => {

	const [conName, setConName] = useState(`new Connector #${totalCount}`)
	const [conGeneralType, setConGeneralType] = useState('')
	const [conType, setConType] = useState('')
	const [conSubType, setConSubType] = useState('')

	const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setConName(e.target.value);
	}

	const createConnectorMutation = useUpdateConnector({ toastMessage: 'Connector successfully added' })

	const confirmModelAdd = () => {

		const newInputCon = {
			name: conName,
			isCreate: true,
			summarySubType: conSubType,
			connectorSubType: conType,
			type: conGeneralType,
			fields:
				[]
		}

		createConnectorMutation.mutate(newInputCon);
		hideModal()
	};


	const conTypeList = [
		'REQUEST_RESPONSE',
		'STREAMING',
		'STREAMING_WITH_REQUEST_RESPONSE',
		'WITHOUT_WAITING_RESPONSE'
	]

	const selectOptions = [
		{
			value: 'parent 1',
			title: 'Получение',
			disabled: true,
			children: [
				{
					value: 'parent 1-0',
					title: 'Ожидающий',
					disabled: true,
					children: [
						{
							value: 'item 1-1-1',
							title: 'Запрос-ответ',
						},
						{
							value: 'item 1-1-2',
							title: 'Потоковый',
						},
						{
							value: 'item 1-1-3',
							title: 'Потоковый с запросом-ответом',
						},
					],
				},
				{
					value: 'parent 1-1',
					title: 'С запросом/инициацией на получение',
					children: [
						{
							value: 'item 1-2-1',
							title: 'Запрос-ответ',
						},
						{
							value: 'item 1-2-2',
							title: 'Потоковый',
						},
					],
				},
			],
		},
		{
			value: 'parent.sec',
			title: 'Отправка',
			disabled: true,
			children: [
				{
					value: 'parent 2',
					title: 'Ожидающий',
					disabled: true,
					children: [
						{
							value: 'item 2-1-1',
							title: 'Без ожидания ответа',
						},
						{
							value: 'item 2-1-3',
							title: 'Потоковая',
						},
					],
				},
				{
					value: 'parent 3',
					title: 'Отправка',
					disabled: true,
					children: [
						{
							value: 'item 2-1-2',
							title: 'Запрос-ответ',
						},
						{
							value: 'item 2-1-4',
							title: 'Потоковый с запросом-ответом',
						},
					]
				}
			]
		}
	];

	const handleChange = (value: string) => {

		const fieldList = [
			{
				name: 'item 1-1-1',
				generalType: 'CONNECTOR_INPUT',
				subType: 'AWAITINGER',
				conType: conTypeList[0],
			},
			{
				name: 'item 1-1-2',
				generalType: 'CONNECTOR_INPUT',
				subType: 'AWAITINGER',
				conType: conTypeList[1],
			},
			{
				name: 'item 1-1-3',
				generalType: 'CONNECTOR_INPUT',
				subType: 'AWAITINGER',
				conType: conTypeList[2],
			},
			{
				name: 'item 1-2-1',
				generalType: 'CONNECTOR_INPUT',
				subType: 'REQUESTER',
				conType: conTypeList[0],
			},
			{
				name: 'item 1-2-2',
				generalType: 'CONNECTOR_INPUT',
				subType: 'REQUESTER',
				conType: conTypeList[1],
			},
			{
				name: 'item 1-2-2',
				generalType: 'CONNECTOR_INPUT',
				subType: 'REQUESTER',
				conType: conTypeList[1],
			},
			{
				name: 'item 2-1-1',
				generalType: 'CONNECTOR_OUTPUT',
				subType: 'AWAITINGER',
				conType: conTypeList[3],
			},
			{
				name: 'item 2-1-2',
				generalType: 'CONNECTOR_OUTPUT',
				subType: 'REQUESTER',
				conType: conTypeList[0],
			},
			{
				name: 'item 2-1-3',
				generalType: 'CONNECTOR_OUTPUT',
				subType: 'AWAITINGER',
				conType: conTypeList[1],
			},
			{
				name: 'item 2-1-4',
				generalType: 'CONNECTOR_OUTPUT',
				subType: 'REQUESTER',
				conType: conTypeList[2],
			},
		]

		const selectedField = fieldList.find(field => field.name === value);

		setConGeneralType(String(selectedField?.generalType))
		setConType(String(selectedField?.conType))
		setConSubType(String(selectedField?.subType))

	};


	return (
		<Modal
			title="Добавление коннектора"
			open={modalStatus}
			onOk={confirmModelAdd}
			onCancel={hideModal}
			okButtonProps={{ disabled: conType === '' }}
		>
			{createConnectorMutation.contextHolder}

			<Space direction="vertical" size="middle" style={{ display: 'flex', width: 400 }}>

				<Flex gap="small" justify="space-between" align="center">
					<span>Название</span>
					<Input
						style={{ width: '70%', height: 32, borderRadius: 8 }}
						value={conName}
						onChange={changeName}
					/>
				</Flex>

				<Flex gap="small" justify="space-between" align="center">
					<span>Тип коннектора</span>
					<TreeSelect
						style={{ width: '70%' }}
						treeData={selectOptions}
						onChange={handleChange}
						treeDefaultExpandAll
					/>
				</Flex>


			</Space>

		</Modal>
	)
}

export default AddNewConnectorModal