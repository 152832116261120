import { Flex, Dropdown, MenuProps } from 'antd'
import React from 'react'
import Logo from "../../../assets/img/logo.png";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

function AdditionalLinks() {

	const items: MenuProps['items'] = [
		{
			key: 'ABOUT',
			label: 'About',
		},
		{
			key: 'HELP',
			label: 'Help',
		},
		{
			key: 'REPORTING',
			label: 'Bug reporting',
		},
	];

	const langItems: MenuProps['items'] = [
		{
			key: 'RU',
			label: 'RU',
		},
		{
			key: 'EN',
			label: 'EN',
		},
		{
			key: 'KZ',
			label: 'KZ',
		},
	];

	return (

		<Flex style={{ height: 54 }} gap='middle' >

			<Flex gap='middle' justify='center' align='center'>

				<Dropdown menu={{ items }}>
					<span style={{ fontSize: 16, cursor: 'pointer', height: 20, lineHeight: '18px' }}>Помощь</span>
				</Dropdown>

				<Dropdown menu={{ items: langItems, selectable: true, defaultSelectedKeys: ['RU'] }}>
					<Flex justify='center' align='center'>
						<FontAwesomeIcon icon={faGlobe} style={{ fontSize: 16, cursor: 'pointer' }} />
					</Flex>
				</Dropdown>

			</Flex>

			<Link to={'/'}>
				<img alt='logo' src={Logo} style={{ height: 54 }} />
			</Link>
		</Flex>
	)
}

export default AdditionalLinks