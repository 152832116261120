import { Input, Modal } from 'antd'
import React, { useState } from 'react'
import { useAddNewDataStructure } from '../../../../../shared/queries/dataStructure/useDataStructureQuery'

interface IAddNewDataStructureModal {
	modalStatus: boolean
	closeModal: () => void
	dataStructureLength: number
}

const AddNewDataStructureModal: React.FC<IAddNewDataStructureModal> = ({ modalStatus, closeModal, dataStructureLength }) => {

	const [dataStructureTitle, setDataStructureTitle] = useState(`Структура данных #${dataStructureLength}`)
	const addNewDataStructure = useAddNewDataStructure()

	const confirmAdd = () => {

		const newField = {
			name: dataStructureTitle,
			isCreate: true,
			fields: [],
			type: "DATA_STRUCTURE"
		}

		addNewDataStructure.mutate(newField);
		closeModal()
	};

	return (
		<Modal
			title="Создание структуры данных"
			open={modalStatus}
			onOk={confirmAdd}
		>
			{addNewDataStructure.contextHolder}
			<label htmlFor="">Название</label>
			<Input
				value={dataStructureTitle}
				onChange={(e) => setDataStructureTitle(e.target.value)}
			/>
		</Modal>
	)
}

export default AddNewDataStructureModal