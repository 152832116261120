import { Flex } from "antd"
import NavHeaderLinks from "./navLinks/NavHeaderLinks"
import UserHeaderAvatar from "./userLink/userHeaderAvatar"
import SignInHeaderAvatar from "./userLink/signInHeaderBtn"
import AdditionalLinks from "./additionalLinks/AdditionalLinks"
import { useState } from "react"
import MegaMenu from "../MegaMenu/MegaMenu"


function GeneralHeader({ isAuth }: { isAuth: boolean }) {

	const [isMegaMenuActive, setMegaMenuActive] = useState(false)

	const ChangeMegaMenuStatus = (status: boolean) => {
		setMegaMenuActive(status)
	}

	return (
		<Flex justify="space-between" align="center" style={{ height: '100%' }}>

			<Flex align="center" gap='middle' style={{ height: '100%' }}>
				{isAuth ? <UserHeaderAvatar /> : <SignInHeaderAvatar />}
				<NavHeaderLinks
					currentMegaMenuStatus={isMegaMenuActive}
					ChangeMegaMenuStatus={ChangeMegaMenuStatus}
				/>
			</Flex>

			<AdditionalLinks />

			{isMegaMenuActive && <MegaMenu ChangeMegaMenuStatus={ChangeMegaMenuStatus} />}

		</Flex>
	)
}

export default GeneralHeader