import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useAppSelector } from "../../../../redux/Store";
import { ComponentType } from "../../../../common/constants";
import AntGridModels from "./ModelComponents/AntGridModels";
import ModelInfo from "./mainInfo/modelInfo";
import { ConfigProvider, Flex, Steps, Typography } from 'antd'
import ModelTableControl from "./ModelComponents/ModelTableControl";
import ModelTesting from "./ModelTesting";
import { ActionTypes } from "../../../../redux/Model/ModelReducer";
import ReturnButton from "../../../../components/ReturnButton/ReturnButton";
import IncidentsTable from "./incidents/IncidentsTable";
import { useFetchModelById, useUpdateModel } from "../../../../shared/queries";

export const FormModels: FC<{ edit: boolean }> = (edit) => {

	const dispatch = useDispatch();
	const { id } = useParams();
	const model = useAppSelector(store => store.ModelReducer.model)
	const [currentType, setCurrentType] = useState(0)
	const [currentState, setCurrentState] = useState<IModelState>('STOP')
	const ConnectorFormCardConfig = { position: "relative", width: "100%" }
	const { data } = useFetchModelById({ id, state: currentState })
	const editModelMutation = useUpdateModel({ toastMessage: 'Model successfully updated' })


	useEffect(() => {
		if (data) {
			dispatch({
				type: ActionTypes.MODEL_GET,
				payload: data,
			})
			setCurrentState(data.state)
		}
	}, [data])


	const onChange = (value: number) => {
		setCurrentType(value)
	};

	return (
		<div {...ConnectorFormCardConfig} style={{ padding: 20 }}>

			<ReturnButton tab='Models' />

			<Typography.Title level={2}>
				{!edit.edit ? 'Создание модели' :
					'Обновление модели'}</Typography.Title>
			<Formik initialValues={{}}
				onSubmit={(values: any, actions) => {
					values.isCreate = !edit.edit
					values.isTemplate = false
					if (!values.isCreate) {
						values.versionId = Number(id)
					}
					values.name = model?.name
					values.type = model?.type
					values.processAssignment = model?.processAssignment
					values.sourceProcess = model?.sourceProcess
					values.description = model?.description
					values.inputs = model?.modelStructComponents.filter(element => element.type === 'CONNECTOR_INPUT')
						.map(({ daysRemaining, resultIncremental, launchSecondStage, modelComponentId }) => ({ daysRemaining: 0, resultIncremental: true, launchSecondStage, componentId: modelComponentId }));
					values.outputs = model?.modelStructComponents.filter(element => element.type === 'CONNECTOR_OUTPUT')
						.map(({ daysRemaining, resultIncremental, modelComponentId }) => ({ daysRemaining: 0, resultIncremental: true, componentId: modelComponentId }));
					values.rules = model?.modelStructComponents.filter(element => element.type === 'RULE')
						.map(({ daysRemaining, resultIncremental, modelComponentId, queueNumber }) => ({ daysRemaining: 0, resultIncremental: true, componentId: modelComponentId, queueNumber }))
					editModelMutation.mutate(values);
				}}
				validate={values => {
					console.log("values:", values)
				}}
			>
				{({ handleSubmit }) => (
					<ConfigProvider
						theme={{
							components: {
								Collapse: {
									contentPadding: 0,
									headerPadding: 0,
									borderRadius: 8,
								},
							},
						}}
					>
						<Flex vertical>
							<Form onSubmit={handleSubmit} autoComplete="off" >
								{editModelMutation.contextHolder}

								<ModelInfo />

								<ModelTableControl
									currentState={data && data.state}
									currentType={currentType} />

								<Steps
									type="navigation"
									size="small"
									current={currentType}
									onChange={onChange}
									progressDot={false}
									style={{ marginTop: 25, marginBottom: 5 }}
									items={[
										{
											title: <Flex align="center" style={{ height: 50, paddingTop: 8 }}>Получение данных</Flex>,
											status: 'process',
											icon: <span></span>
										},
										{
											title: <Flex align="center" style={{ height: 50, textAlign: 'center', paddingTop: 8 }}>Правила вычисления,<br /> выявления и реагирования</Flex>,
											status: 'process',
											icon: <span></span>
										},
										{
											title: <Flex align="center" style={{ height: 50, paddingTop: 8 }}>Отправка данных</Flex>,
											status: 'process',
											icon: <span></span>
										},
									]}
								/>
								<Flex justify="space-between" style={{ marginTop: 5, marginBottom: 25 }}>
									<AntGridModels type={ComponentType.Input} changeType={() => setCurrentType(0)} />
									<AntGridModels type={ComponentType.Rules} changeType={() => setCurrentType(1)} />
									<AntGridModels type={ComponentType.Output} changeType={() => setCurrentType(2)} />
								</Flex>

								<ModelTesting />

								<IncidentsTable currentState={data?.state ? data.state : 'STOP'} />

							</Form>
						</Flex>
					</ConfigProvider>
				)}
			</Formik>
		</div>
	);
}

